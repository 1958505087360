import { getCookie } from "./cookies";

export const checkUserLoggedIn = (navigate) => {
  if (!sessionStorage.getItem("access-token")) {
    navigate("/");
  }
};

export const dataURItoFile = (dataURI, filename) => {
  const arr = dataURI.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];

  // Enforce image/jpeg MIME type
  if (mime !== "image/jpeg") {
    mime = "image/jpeg";
  }

  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};
