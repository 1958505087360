import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormHelperText,
} from "@mui/material";

interface Props {
  LabelName: string;
  menuItems: any;
  name: any;
  value: any;
  handleChange: any;
  error: any;
  helperText: any;
  setOthers?: any | undefined;
  disabled?: any;
}

const Dropdown: React.FC<Props> = ({
  LabelName,
  menuItems,
  name,
  value,
  handleChange,
  error,
  helperText,
  setOthers,
  disabled,
}) => {
  // console.log("value",value)

  return (
    <FormControl fullWidth>
      <Select
        inputProps={{ "aria-label": "Without label" }}
        value={value}
        name={name}
        displayEmpty
        renderValue={
          value !== ""
            ? undefined
            : () => {
                return (
                  <p style={{ margin: 0, fontWeight: 400, color: "#adadb1" }}>
                    {LabelName}
                  </p>
                );
              }
        }
        disabled={disabled}
        placeholder={LabelName}
        error={error}
        onChange={(e) => {
          // console.log(e.target.value.value);
          if (e.target.value && setOthers) {
            // console.log(e.target.value);
            setOthers(e.target.value);
          }

          handleChange(e);
        }}
        sx={{
          borderRadius: "10px",
          // width: "-webkit-fill-available",
          background: "#F2F2F6",
          outline: "none",
          fontSize: "14px",
          fontWeight: 500,
          padding: "0 0px",
          color: "#000000",
          boxShadow: "none",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          // "&:hover .MuiOutlinedInput-notchedOutline":
          //   {
          //     border: "none",
          //   },
          // "&.Mui-focused .MuiOutlinedInput-notchedOutline":
          //   {
          //     border: "none",
          //   },
        }}
        MenuProps={{
          MenuListProps: {
            sx: {
              "& li.Mui-selected": {
                backgroundColor: "#4754A0",
                color: "white",
              },
            },
          },
        }}
      >
        {/* <MenuItem
          disabled
          value=""
          sx={{ height: "0px", overflow: "hidden", visibility: "hidden" }}
        >
          {LabelName}
        </MenuItem> */}
        {menuItems.map((a: any, index: any) => {
          return (
            <MenuItem
              key={index}
              value={a}
              selected={a === value}
              // sx={{ background: "#ECFDF3" }}
            >
              {a.value}
            </MenuItem>
          );
        })}
      </Select>
      {/* <div style={{fontSize:'12px', color:'red'}}> {helperText}</div> */}
      <FormHelperText style={{ color: "#ef5350" }}>{helperText}</FormHelperText>
    </FormControl>
  );
};
export default Dropdown;
