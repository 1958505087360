import axios, { AxiosResponse } from "axios";

import { EKYCURL } from "./constants";
import {
  IMobile,
  IMobileOtpValidation,
  IReSendMobileOTP,
  ISendEmail,
  IEmailOTPValidation,
  IReSendEmailOTP,
  IPanDetails,
  IYourProfileDetails,
  IWebImage,
  ITermsandConditions,
  IBankDetails,
  IDeleteDocument,
  IGetReversePennyDropStatus,
  IRevesePennyDropBankDetails,
  IAdminLogin,
  IGetDocumentDigilocker,
  IFetchDataAccountAggregtor,
  IEmailGoogleAuth,
  IEmailSignup,
  ISaveIPaddress,
} from "../shared/dtos";

const axiosInterceptor = axios.create({
  baseURL: EKYCURL,
});

axiosInterceptor.interceptors.request.use(
  async (req) => {
    const token = sessionStorage.getItem("access-token");

    req.headers.Authorization = `Bearer ${token}`;
    return req;
  },
  (error) => Promise.reject(error)
);

axiosInterceptor.interceptors.response.use(
  (res) => res,
  (err) => {
    const status = err.response ? err.response.status : null;
    if (status === 401) {
      handleLogout();
    }
    return Promise.reject(err);
  }
);
const handleLogout = () => {
  window.location.href = "/";
  sessionStorage.clear();
};

//---------------------------------Onboarding APIS-------------------------------------------------------------------

//------Common

export const locationCheckAPICall = (ip_address: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/v1/api/ekyc/common/ip_location_check/${ip_address}`, {
        params: { ip_address: ip_address },
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const SaveIpAddressAPICall = (requestBody: ISaveIPaddress) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/common/save_ip_location`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const startOverAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/common/start_over/`)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const CommonDropDownAPICall = (dd_type: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/v1/api/ekyc/common/drop_down/${dd_type}`, { params: { dd_type } })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const stageDetailsAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/common/stage_details/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const AdminLoginToClientAccountAPICall = (requestBody: IAdminLogin) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/common/admin_login`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

//------Mobile
export const sendMobileNoAPICall = (requestBody: IMobile) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/verify/mobile/getotp/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const reSendMobileOTPAPICall = (requestBody: IReSendMobileOTP) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/verify/mobile/resend_otp/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const validateMobileOTPAPICall = (requestBody: IMobileOtpValidation) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/verify/mobile/verify_otp/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

//------Email
export const EmailSignupAPICall = (requestBody: IEmailSignup) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/verify/email/getotp/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const EmailGoogleAuthAPICall = (requestBody: IEmailGoogleAuth) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/verify/email/google_auth/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const sendEmialAPICall = (requestBody: ISendEmail) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/validation/emailOTP/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const validateEmailOTPAPICall = (requestBody: IEmailOTPValidation) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/verify/email/verify_otp/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const reSendEmailOTPAPICall = (requestBody: IReSendEmailOTP) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/verify/email/resend_otp/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

//------Pan
export const panDetailsAPICall = (requestBody: IPanDetails) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/stage/pan/update/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
//------Aadhaar
export const digiLockerInitAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/stage/digilocker/create_consent/`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const digiLockerStatusAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/stage/digilocker/status/`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const CompleteAadhaarAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/stage/kra_or_aadhaar/complete/`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const KRAUpdateAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`ekyc/fetchdetails/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

//------Profile
export const yourProfileDetailsAPICall = (requestBody: IYourProfileDetails) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/stage/customer_details/update/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const segmentDetailsAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/dropDown/segmentDetails/`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

//-------Trading Preferrence

export const segmentDetailsPostAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/stage/segments/update/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

//------IPV
export const webImageBase64APICall = (requestBody: IWebImage) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/stage/ipv/update/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
//-------Nominee
export const nomineeDetailsAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/stage/stage_nominee/update/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

//-------T & C
export const termsAndConditionsAPICall = (requestBody: ITermsandConditions) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/stage/t_and_c/update/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

//------Documents Upload
export const uploadDocumentsAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/stage/stage_document/upload/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const deleteDocumentAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/stage/stage_document/delete/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const documentupdateAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/stage/document/update/`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

//------Bank
export const bankDetailsAPICall = (requestBody: IBankDetails) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/stage/stage_bank/update/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const NewReversePennyDropRequestAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(
        `/v1/api/ekyc/stage/stage_bank/reverse_penny_request/`,
        requestBody,
        {}
      )
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetReversePennyDropStatusAPICall = (
  requestBody: IGetReversePennyDropStatus
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(
        `/v1/api/ekyc/stage/stage_bank/reverse_penny_status/`,
        requestBody,
        {}
      )
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const esignAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/ekyc/esign/`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const getDocumentTypeDropDownAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/dropDown/doctypedropdown/`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const KraesignAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/ekyc/kraesign/`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const requestEsignAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/esign/request_esign`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const CreatePdfEsignAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/stage/esign/fill_pdf/`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const CreateEsignRequestAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/stage/esign/create_consent/`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const getDocumentEsignAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/esign/get_document/${requestBody}`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const fetchGetDropdownValuesAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/dropDown/profile/`)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const fetchGetNomineeDropdownValuesAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/dropDown/nominee/`)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const nomineeAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/ekyc/nomineedetails/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const SubmitReversePennyDropAPICall = (
  requestBody: IRevesePennyDropBankDetails
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(
        `/v1/api/ekyc/stage/stage_bank/reverse_penny_drop_update/`,
        requestBody,
        {}
      )
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const PanDocumentRequestAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/ekyc/documnet/request/`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const IncomeProofRequestAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/ekyc/account_aggregator/create_consent/`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const AccountAggregatorFetchDataAPICall = (
  requestBody: IFetchDataAccountAggregtor
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/ekyc/account_aggregator/fetch_data/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const PanDigilockerDocumentGetAPICall = (
  requestBody: IGetDocumentDigilocker
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/ekyc/digilocker/document/get/`, "", {
        params: {
          id: requestBody.id,
          doc_type: requestBody.doc_type,
        },
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

//--------------------------Redirect-------------------------------------------

export const digiLockerAuthenticationAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(
        `/v1/api/ekyc/stage/digilocker/update_digilocker_data/`,
        requestBody,
        {}
      )
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const esignDocumentAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/stage/esign/get_signed_document/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

//--------------------------Payment-------------------------------------------
export const PaymentAuthAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/payment/auth/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const PaymentCallbackAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/payment/callback/`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const PaymentTransactionAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/payment/transaction/`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
