import React from "react";
import PageNotFoundStyles from "../pages/Styles/pageNotFound.module.scss";
import ErrorImage from "../Components/assets/Images/pagenotfoung.png";
import { Button } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";

export default function PageNotFound() {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/");
  };
  return (
    <div className={PageNotFoundStyles.wrapper}>
      <img src={ErrorImage} className={PageNotFoundStyles.errorImage} />
      <div className={PageNotFoundStyles.pagenotfound}>
        <h1 className={PageNotFoundStyles.titleText}>404 - Page Not Found</h1>
        <p className={PageNotFoundStyles.paratext}>
          Sorry, the page you are looking for does not exist.
        </p>
      </div>
      <Button variant="contained" onClick={handleBack}>
        Go Back
      </Button>
    </div>
  );
}
