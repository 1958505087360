import React, { useEffect, useState } from "react";

import { Avatar, Grid, Stack, TextField, Typography } from "@mui/material";

import KRAStyles from "../pages/Styles/KRAScreen.module.scss";

interface Props {
  aadhaarData: any;

}

export default function KRAScreen({ aadhaarData }: Props) {
  const [fetechedDetails, setfetechedDetails] = useState(true);

  //  console.log(aadhaarData);

  return (
    <>
      {fetechedDetails ? (
        <Stack height={"max-content"}>
          <p className={KRAStyles.header}>Fetched KRA Details</p>
          <div className={KRAStyles.container}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 5 }}
            >
              <Grid item xs={12} sm={4}>
                <p className={KRAStyles.textfieldLabel}>Name As per PAN</p>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  placeholder="Name As per PAN"
                  variant="outlined"
                  value={aadhaarData?.name}
                  inputProps={{
                    sx: {
                      background: "#F2F2F6",
                      borderRadius: "8px",
                      "&::placeholder": {
                        paddingLeft: "10px",
                      },

                      paddingLeft: "10px",
                    },
                  }}
                  sx={{
                    fieldset: { borderColor: "transparent" },
                    width: { xs: "100%", sm: "100%" },
                    input: {
                      color: "#344054",
                      fontSize: "14px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <p className={KRAStyles.textfieldLabel}>Father Name</p>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  placeholder="Father Name"
                  variant="outlined"
                  value={aadhaarData?.father_name}
                  inputProps={{
                    sx: {
                      background: "#F2F2F6",
                      borderRadius: "8px",
                      "&::placeholder": {
                        paddingLeft: "10px",
                      },

                      paddingLeft: "10px",
                    },
                  }}
                  sx={{
                    fieldset: { borderColor: "transparent" },
                    width: { xs: "100%", sm: "100%" },
                    input: {
                      color: "#344054",
                      fontSize: "14px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <p className={KRAStyles.textfieldLabel}>Date of Birth</p>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  placeholder="Date of Birth"
                  variant="outlined"
                  value={aadhaarData?.dob}
                  inputProps={{
                    sx: {
                      background: "#F2F2F6",
                      borderRadius: "8px",
                      "&::placeholder": {
                        paddingLeft: "10px",
                      },

                      paddingLeft: "10px",
                    },
                  }}
                  sx={{
                    fieldset: { borderColor: "transparent" },
                    width: { xs: "100%", sm: "100%" },
                    input: {
                      color: "#344054",
                      fontSize: "14px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <p className={KRAStyles.textfieldLabel}>Gender</p>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  placeholder="Gender"
                  variant="outlined"
                  value={aadhaarData?.gender}
                  inputProps={{
                    sx: {
                      background: "#F2F2F6",
                      borderRadius: "8px",
                      "&::placeholder": {
                        paddingLeft: "10px",
                      },

                      paddingLeft: "10px",
                    },
                  }}
                  sx={{
                    fieldset: { borderColor: "transparent" },
                    width: { xs: "100%", sm: "100%" },
                    input: {
                      color: "#344054",
                      fontSize: "14px",
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                justifyContent={"center"}
                margin={"auto"}
              >
                <p className={KRAStyles.textfieldLabel}>Email</p>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  placeholder="Email"
                  variant="outlined"
                  value={aadhaarData?.email?.toLowerCase()}
                  inputProps={{
                    sx: {
                      background: "#F2F2F6",
                      borderRadius: "8px",
                      "&::placeholder": {
                        paddingLeft: "10px",
                      },

                      paddingLeft: "10px",
                    },
                  }}
                  sx={{
                    fieldset: { borderColor: "transparent" },
                    width: { xs: "100%", sm: "100%" },
                    input: {
                      color: "#344054",
                      fontSize: "14px",
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                justifyContent={"center"}
                margin={"auto"}
              >
                <p className={KRAStyles.textfieldLabel}>Pincode</p>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  placeholder="Pincode"
                  variant="outlined"
                  value={aadhaarData.pincode}
                  inputProps={{
                    sx: {
                      background: "#F2F2F6",
                      borderRadius: "8px",
                      "&::placeholder": {
                        paddingLeft: "10px",
                      },

                      paddingLeft: "10px",
                    },
                  }}
                  sx={{
                    fieldset: { borderColor: "transparent" },
                    width: { xs: "100%", sm: "100%" },
                    input: {
                      color: "#344054",
                      fontSize: "14px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <p className={KRAStyles.textfieldLabel}>Address</p>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  placeholder="Address"
                  variant="outlined"
                  value={aadhaarData?.address}
                  inputProps={{
                    sx: {
                      background: "#F2F2F6",
                      borderRadius: "8px",
                      "&::placeholder": {
                        paddingLeft: "10px",
                      },

                      paddingLeft: "10px",
                    },
                  }}
                  sx={{
                    fieldset: { borderColor: "transparent" },
                    width: { xs: "100%", sm: "100%" },
                    input: {
                      color: "#344054",
                      fontSize: "14px",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </Stack>
      ) : (
        <Stack>
          <Typography>No Details Fetched</Typography>
        </Stack>
      )}
    </>
  );
}
