import { Alert, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import Slide, { SlideProps } from "@mui/material/Slide";
type TransitionProps = Omit<SlideProps, "direction">;

function TransitionUp(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}

interface props {
  message: any;
}

export default function BankSnackBar({ message }: props) {
  const [transition, setTransition] = React.useState<
    React.ComponentType<TransitionProps> | undefined
  >(undefined);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (message.Bank.length>1) {
      setOpen(true);
      setTransition(() => TransitionUp);
      
    }
    if (message.Bank.length===0) {
        setOpen(false);
        setTransition(() => TransitionUp);
        
      }
  }, [message]);

  //console.log(message)
  return (
    <div >
      <Snackbar
        open={open}
       
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        TransitionComponent={transition}
        sx={{ marginTop: "120px" }}
      >
        <div style={{ background: "#54BF00", borderRadius: "10px " }}>
          <div style={{ marginLeft: "30px" }}>
            <Alert
              icon={false}
              severity="success"
              sx={{
                width: "max-content",
                height: "max-content",
                fontSize: "16px",
                borderRadius: "0",
                display: "flex",
                alignItems: "center",
                background: "#EAFFE9",
              }}
            >
              {/* <p style={{margin:"0px", fontSize:"15px", textAlign:"center"}}>Bank Details</p> */}
             <p style={{margin:"0px", fontSize:"14px"}}>{message.Bank}</p> 
             <p style={{margin:"0px", fontSize:"12px", color:"black"}}>{message.Address}</p> 
            </Alert>
          </div>
        </div>
      </Snackbar>
    </div>
  );
}
