import { SET_PATHNAME } from "../actions/pathNameActions";

const initialState: any = {
  pathname: "",
};

const dataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_PATHNAME:
      state = { ...state };
      state.pathname = action.payload;

      return state;

    default:
      return state;
  }
};

export default dataReducer;
