import React, { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  Typography,
  Button,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { browserName } from "react-device-detect";
import CaptchaTest from "../Components/Captcha/CaptchaTest";
import {
  sendMobileNoAPICall,
  locationCheckAPICall,
  AdminLoginToClientAccountAPICall,
  SaveIpAddressAPICall,
} from "../shared/APICalls";
import { IAdminLogin, IMobile, ISaveIPaddress } from "../shared/dtos";
import routesData from "../shared/routes.json";
import SignupStyles from "../pages/Styles/SignUp.module.scss";
import SignUpImage from "../Components/assets/Images/CompLeftImage.svg";
import SnackBar from "../Components/SnackBars/SnackBar";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import axios from "axios";

export default function SignupPage() {
  const navigate = useNavigate();

  const [isSafari, setisSafari] = useState(false);
  const [isFireFox, setisFireFox] = useState(false);
  const [loading, setloading] = useState(false);
  const [showContent, setshowContent] = useState(true);
  const [message, setMessage] = useState("");
  const [IPAddress, setIPAddress] = useState("");
  const [userDeniedLocation, setuserDeniedLocation] = useState(false);
  const [locationData, setLocationData] = useState({
    status: true,
    latitude: "",
    longitude: "",
  });
  const navigationData = routesData;

  const urlParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlParams.entries());
  const source = Object.keys(params)[0];
  const id = (params.c && params.c) || (params.p && params.p);

  //  console.log(Object.keys(params)[0]);

  const client_id = params.client_id;
  const user_id = params.user_id;

  if (source) {
    sessionStorage.setItem("source", source);
    sessionStorage.setItem("id", id);
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchIPAddress = async () => {
      try {
        const response = await axios.get("https://checkip.amazonaws.com/");
        setIPAddress(response.data);
      } catch (err: any) {}
    };
    fetchIPAddress();
  }, []);

  useEffect(() => {
    if (IPAddress !== "") {
      locationCheckAPICall(IPAddress?.trim())
        .then((response: any) => {
          // console.log("responselocation", response.data.data);
          if (response.data.data) {
            setLocationData(response.data.data);
            sessionStorage.setItem("latitude", response.data.data.latitude);
            sessionStorage.setItem("longitude", response.data.data.longitude);
          }
        })
        .catch((error: any) => {
          // console.log("error", error);
          setshowContent(false);
        });
    }
  }, [IPAddress]);

  useEffect(() => {
    if (client_id && client_id) {
      let obj: IAdminLogin = {
        client_id: client_id,
        admin_id: user_id,
      };

      AdminLoginToClientAccountAPICall(obj)
        .then((response: any) => {
          const responseData = response.data;
          sessionStorage.setItem(
            "access-token",
            responseData.data.access_token
          );
          if (
            responseData.data.next_stage &&
            navigationData.hasOwnProperty(responseData.data.next_stage)
          ) {
            navigate(navigationData[responseData.data.next_stage]);
          }
        })
        .catch((error: any) => {
          setMessage(error.response.data.message);
          setTimeout(() => {
            setMessage("");
          }, 5000);
        });
    }
  }, [client_id, user_id]);

  function sendMobileNumber(data: any) {
    setloading(true);
    let obj: IMobile = {
      mobile: data,
      // ip_address: IPAddress?.trim(),
    };
    sendMobileNoAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        sessionStorage.setItem("mobile", data);
        setloading(false);
        if (
          responseData.data.next_stage &&
          navigationData.hasOwnProperty(responseData.data.next_stage)
        ) {
          navigate(navigationData[responseData.data.next_stage]);
        }
      })
      .catch((error: any) => {
        setloading(false);

        setMessage(
          error.message.includes("Network")
            ? "Error occured, Please try again"
            : error.response.data.message
        );
        setTimeout(() => {
          setMessage("");
        }, 5000);
      });
  }

  function validatePhoneNumber(value: any) {
    let error: any;
    if (!value) {
      error = "Mobile Number Required";
    } else if (!/^[0-9]{10}$/.test(value)) {
      error = "Must be a valid 10 digit mobile number";
    }
    return error;
  }

  useEffect(() => {
    setTimeout(() => {}, 1000);
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        // console.log("Enter key was pressed. Run your function.");
        const onFormSubmit = (values) => {
          sendMobileNumber(values.phoneNumber);
        };

        event.preventDefault();
        // callMyFunction();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <>
      <div className={SignupStyles.wrapper}>
        <div className={SignupStyles.LeftContainer}>
          <img src={SignUpImage} alt="Image" className={SignupStyles.image} />
        </div>
        <div className={SignupStyles.RightContainer}>
          <div className={SignupStyles.headerSection}>
            <p className={SignupStyles.signup}>Sign Up</p>
            <p className={SignupStyles.subText}>
              Or continue with your existing application
            </p>
          </div>

          <div>
            {showContent ? (
              <Formik
                initialValues={{
                  phoneNumber: "",
                }}
                onSubmit={(values) => {
                  sendMobileNumber(values.phoneNumber);
                }}
              >
                {({ errors, touched, handleChange, values, handleSubmit }) => (
                  <Form
                    onKeyDown={(event) => {
                      event.code === "Enter" && handleSubmit();
                    }}
                  >
                    {!userDeniedLocation ? (
                      <>
                        <div className="">
                          <p className={SignupStyles.textfieldLabel}>
                            Mobile Number
                          </p>
                          <Field
                            name="phoneNumber"
                            validate={validatePhoneNumber}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label=""
                                type="numeric"
                                sx={{
                                  fieldset: { borderColor: "transparent" },
                                }}
                                placeholder="10 Digit mobile number"
                                className={SignupStyles.textfield}
                                variant="outlined"
                                value={values.phoneNumber}
                                helperText={
                                  errors.phoneNumber &&
                                  touched.phoneNumber && (
                                    <FormHelperText
                                      style={{ color: "#d32f2f" }}
                                    >
                                      {errors.phoneNumber}
                                    </FormHelperText>
                                  )
                                }
                                onChange={handleChange}
                                inputProps={{
                                  sx: {
                                    background: "#F2F2F6",
                                    borderRadius: "8px",
                                    "&::placeholder": {
                                      paddingLeft: "10px",
                                    },

                                    paddingLeft: "10px",
                                  },
                                  maxLength: 10,
                                  inputMode: "numeric",
                                  pattern: "[0-9]*",
                                }}
                                onKeyPress={(event) => {
                                  const keyCode = event.keyCode || event.which;
                                  const keyValue = String.fromCharCode(keyCode);
                                  const isValidKey = /^\d+$/.test(keyValue);

                                  if (!isValidKey) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            )}
                          />
                          <p className={SignupStyles.smallText}>
                            You will receive an OTP on your number
                          </p>

                          <SnackBar message={message} />

                          <div className={SignupStyles.buttonSection}>
                            {/* {values.phoneNumber.length === 10 ? ( */}
                            <LoadingButton
                              variant="contained"
                              type="submit"
                              loading={loading}
                              id="signupContinueButton"
                              className={SignupStyles.button}
                              onClick={() => {
                                handleSubmit();
                              }}
                              loadingIndicator={
                                <CircularProgress
                                  sx={{ color: "#4754A0" }}
                                  size={20}
                                />
                              }
                            >
                              Continue
                            </LoadingButton>
                            {/* ) : (
                              <Button
                                variant="contained"
                                disabled
                                className={SignupStyles.button}
                              >
                                Continue
                              </Button>
                            )} */}
                            <p className={SignupStyles.LoginText}>
                              Already have an account?
                              <a
                                href="https://xts.compositedge.com/#!/app"
                                style={{
                                  color: "#4754A0",
                                  marginLeft: "5px",
                                  cursor: "pointer",
                                }}
                              >
                                Login
                              </a>{" "}
                              <ArrowOutwardIcon
                                style={{ fontSize: "18px", color: "#4754A0" }}
                              />
                            </p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <Typography>
                            Location Access is blocked, please grant the
                            Loaction access in browser settings to proceed
                            further{" "}
                          </Typography>
                        </div>
                        {isFireFox && (
                          <div>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                window.location.reload();
                              }}
                              id="signupReloadButton"
                            >
                              Reload
                            </Button>
                          </div>
                        )}
                        {isSafari ? (
                          <div>
                            <Typography>
                              Please Press Reload Button after granting camera
                              access
                            </Typography>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                window.location.reload();
                              }}
                              id="signupSafariReloadButton"
                            >
                              Reload
                            </Button>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </Form>
                )}
              </Formik>
            ) : (
              <div>Access Allowed Only in India </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
