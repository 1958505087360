import React, { useState, useEffect } from "react";
import Styles from "./BackToTop.module.scss";
import { IconButton } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={Styles.BacktoTop}>
      {isVisible && (
        <IconButton
          sx={{
            background: "#4754A0",
            color: "white",
            "&:hover": {
              backgroundColor: "#4754A0",

              color: "white",
            },
          }}
          onClick={handleBackToTop}
        >
          <KeyboardArrowUpIcon />
        </IconButton>
      )}
    </div>
  );
};

export default BackToTopButton;
