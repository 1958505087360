


import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
} from "@mui/material";

import * as pdfjs from "pdfjs-dist/webpack";
import * as pdfjsLib from "pdfjs-dist";
// import { Document, Page,pdfjs } from 'react-pdf';
import { Viewer } from "@react-pdf-viewer/core";

interface Props {
  url: any;
}

const PdfModal: React.FC<Props> = ({ url }) => {
  const [numPages, setNumPages] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);


  const viewerRef = useRef<HTMLCanvasElement>(null);

  const onPageChange = useCallback(
    (page: number) => {
      if (page !== currentPage) {
        setCurrentPage(page);
      }
    },
    [currentPage]
  );

  useEffect(() => {
    const loadPdf = async () => {
      const pdf = await pdfjs.getDocument(url).promise;
      // console.log("pdf",pdf);
      setNumPages(pdf.numPages);
      const page = await pdf.getPage(currentPage);
      const canvas: any = viewerRef.current;
      const context = canvas.getContext("2d");
      const viewport = page.getViewport({ scale: 1.0 });
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      await page.render({
        canvasContext: context,
        viewport,
      });
    };

    loadPdf();
  }, [url, currentPage]);


  return (
    <>
      <Stack>
        <Stack
          style={{ height: "480px", overflow: "auto", width: "100%" }}
          alignItems={"center"}
        >
          <canvas
            ref={viewerRef}
            style={{ width: "100%", objectFit: "contain" }}
          />
       
          {/* <Document file={newUrl}  >
              <Page pageNumber={pageNumber} />
            </Document> */}

          <Stack alignItems={"center"} style={{ background: "white" }}>
            <div>
              Page {currentPage} of {numPages}
            </div>
            <div>
              <Button
                disabled={currentPage === 1}
                onClick={() => onPageChange(currentPage - 1)}
                sx={{ color: "rgba(52, 64, 84, 1)" }}
              >
                Previous
              </Button>
              <Button
                disabled={currentPage === numPages}
                onClick={() => onPageChange(currentPage + 1)}
                sx={{ color: "rgba(3, 152, 85, 1)" }}
              >
                Next
              </Button>
            </div>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default PdfModal;
