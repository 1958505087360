import React from "react";
import Styles from "../../pages/Styles/NomineeStyles.module.scss";
import { IconButton } from "@mui/material";

import trash from "../../Components/assets/Images/trash.svg";
import edit from "../../Components/assets/Images/edit.svg";
interface Props {
  index: number;
  onEditClick: any;
  onDeleteClick: any;
  percentageofShare: number;
  currentIndex:any;
}
export default function NomineeBar({
  index,
  onEditClick,
  onDeleteClick,
  percentageofShare,
  currentIndex
}: Props) {

  // console.log("currentIndex",currentIndex ,"index", index)
  return (
    <div className={Styles.headerTitle}>
      <p className={Styles.nomineeText}>
        Nominee {index + 1} {percentageofShare ? `(${percentageofShare})%` : ""}
      </p>
      <div>
        <IconButton
          disabled={index === 0}
          style={{
            visibility: index === 0 ? "hidden" : "visible",
            marginRight: "20px",
          }}
          onClick={() => {
            onDeleteClick();
          }}
          id="nomineesDeleteNomineeButton"
        >
          <img src={trash} style={{ width: "20px" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            onEditClick(index);
          }}
        >
          <img src={edit} style={{ width: "20px" }} />
        </IconButton>
      </div>
    </div>
  );
}
