import React, { useEffect, useState } from "react";
import Data from "../Data.json";
import { Avatar, Grid, Stack, TextField, Typography } from "@mui/material";
import { digiLockerStatusAPICall } from "../../shared/APICalls";
import JsonStyles from "./JsonData.module.scss";

interface Props {
  aadhaarData: any;
}

export default function JsonDataToAadhaarFormat({ aadhaarData }: Props) {
  // const base64Image = Data.CertificateData.KycRes.UidData.Pht;

  // console.log("aadhaarData",aadhaarData)
  const base64Image =
    aadhaarData?.aadhaar_json && aadhaarData.aadhaar_json?.photo;

  const [fetechedDetails, setfetechedDetails] = useState(true);

  return (
    <>
      {fetechedDetails ? (
        <Stack height={"max-content"}>
          <p className={JsonStyles.header}>Fetched Aadhaar Details</p>

          <div className={JsonStyles.container}>
            <Grid margin={"10px 0"}>
              <img
                //src={`data:image/jpeg;base64,${base64Image}`}
                src={aadhaarData.url}
                alt="Image"
                style={{
                  width: "90px",
                  height: "90px",
                  // border: "5px solid #F0EBEB",
                  borderRadius: "50%",
                }}
              />
            </Grid>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 5 }}
            >
              <Grid item xs={12} sm={4}>
                <p className={JsonStyles.textfieldLabel}>Aadhaar Number</p>
                <TextField
                  fullWidth
                  // focused
                  id="outlined-basic"
                  placeholder="Aadhaar No"
                  variant="outlined"
                  value={aadhaarData.aadhaar_no}
                  inputProps={{
                    sx: {
                      background: "#F2F2F6",
                      borderRadius: "8px",
                      "&::placeholder": {
                        paddingLeft: "10px",
                      },

                      paddingLeft: "10px",
                    },
                  }}
                  sx={{
                    fieldset: { borderColor: "transparent" },
                    width: { xs: "100%", sm: "100%" },
                    input: {
                      color: "#344054",
                      fontSize: "14px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <p className={JsonStyles.textfieldLabel}>Name</p>
                <TextField
                  fullWidth
                  // focused
                  id="outlined-basic"
                  placeholder="Name"
                  variant="outlined"
                  value={aadhaarData.name}
                  inputProps={{
                    sx: {
                      background: "#F2F2F6",
                      borderRadius: "8px",
                      "&::placeholder": {
                        paddingLeft: "10px",
                      },

                      paddingLeft: "10px",
                    },
                  }}
                  sx={{
                    fieldset: { borderColor: "transparent" },
                    width: { xs: "100%", sm: "100%" },
                    input: {
                      color: "#344054",
                      fontSize: "14px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <p className={JsonStyles.textfieldLabel}>Date of Birth</p>
                <TextField
                  fullWidth
                  // focused
                  id="outlined-basic"
                  placeholder="Date of Birth"
                  variant="outlined"
                  value={aadhaarData.dob}
                  inputProps={{
                    sx: {
                      background: "#F2F2F6",
                      borderRadius: "8px",
                      "&::placeholder": {
                        paddingLeft: "10px",
                      },

                      paddingLeft: "10px",
                    },
                  }}
                  sx={{
                    fieldset: { borderColor: "transparent" },
                    width: { xs: "100%", sm: "100%" },
                    input: {
                      color: "#344054",
                      fontSize: "14px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <p className={JsonStyles.textfieldLabel}>Gender</p>
                <TextField
                  fullWidth
                  // focused
                  id="outlined-basic"
                  placeholder="Gender"
                  variant="outlined"
                  value={aadhaarData.gender}
                  inputProps={{
                    sx: {
                      background: "#F2F2F6",
                      borderRadius: "8px",
                      "&::placeholder": {
                        paddingLeft: "10px",
                      },

                      paddingLeft: "10px",
                    },
                  }}
                  sx={{
                    fieldset: { borderColor: "transparent" },
                    width: { xs: "100%", sm: "100%" },
                    input: {
                      color: "#344054",
                      fontSize: "14px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={8}>
                <p className={JsonStyles.textfieldLabel}>Address</p>
                <TextField
                  fullWidth
                  // focused
                  id="outlined-basic"
                  placeholder="Address"
                  variant="outlined"
                  value={aadhaarData.address}
                  inputProps={{
                    sx: {
                      background: "#F2F2F6",
                      borderRadius: "8px",
                      "&::placeholder": {
                        paddingLeft: "10px",
                      },

                      paddingLeft: "10px",
                    },
                  }}
                  sx={{
                    fieldset: { borderColor: "transparent" },
                    width: { xs: "100%", sm: "100%" },
                    input: {
                      color: "#344054",
                      fontSize: "14px",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </Stack>
      ) : (
        <Stack>
          <Typography>No Details Fetched</Typography>
        </Stack>
      )}
    </>
  );
}
