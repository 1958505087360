import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UIURL } from "../shared/constants";

function Updates() {
  const navigate = useNavigate();
  useEffect(() => {
    window.location.href = `${UIURL}updates/`;
  }, []);
  return <div>updates</div>;
}

export default Updates;
