import React, { useState, useEffect } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  Typography,
  Button,
  FormHelperText,
  IconButton,
  Divider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { css } from "@emotion/react";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import { checkUserLoggedIn } from "../shared/commonfunctions";
import { string } from "yargs";
import { IPanDetails } from "../shared/dtos";
import { panDetailsAPICall, stageDetailsAPICall } from "../shared/APICalls";
import routesData from "../shared/routes.json";
import PanStyles from "../pages/Styles/PanVerification.module.scss";
import StageLayout from "../Layouts/StageLayout";
import calenderImage from "../Components/assets/Images/calendar.svg";
import eyeIcon1 from "../Components/assets/Images/eye-slash.svg";
import SnackBar from "../Components/SnackBars/SnackBar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorSnackBar from "../Components/SnackBars/ErrorSnackBar";
import PanImage from "../Components/assets/Images/panpagelatestimage.svg";
import { confirmAlert } from "react-confirm-alert";
import SucessSnackBar from "../Components/SnackBars/SucesssnackBar";

const schema = Yup.object().shape({
  // name: Yup.string()
  //   .matches(/^[a-zA-Z ]*$/, "Enter Valid Name")
  //   .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed")
  //   .required("Please enter your name"),
  pannumber: Yup.string()

    .required("PanNumber is Required")
    .max(10)
    .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, "Enter Valid PanNumber")
    .transform((value: any) => value && value.toUpperCase()),

  // dateofBirth: Yup.string().required("Date of Birth is a required field"),
  // .max(9, "Wrong Date of Birth"),
});

function DateIcon(props) {
  return <img src={calenderImage} style={{ width: "25px" }} />;
}

export default function PanVerificationPage() {
  const navigate = useNavigate();
  const [pan, setPan] = useState("");
  // const [dob, setDob] = useState<any>(null);
  const [dobError, setDobError] = useState("");
  const [loading, setloading] = useState(false);
  const [message, setMessage] = useState<any>("");
  const [panPrefill, setPanPrefill] = useState("");
  const [namePrefill, setNamePrefill] = useState("");
  // const [dobPrefill, setPanDobfill] = useState<any>("");
  const [completedStages, setCompletedStages] = useState<any>();
  const [prefilloading, setprefilloading] = useState(true);
  const [rejectMessage, setRejectMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [open, setOpen] = React.useState(true);

  const navigationData = routesData;

  useEffect(() => {
    window.scrollTo(0, 0);
    checkUserLoggedIn(navigate);

    let obj: any = {
      stage: "pan",
    };

    stageDetailsAPICall(obj)
      .then((response: any) => {
        setprefilloading(false);
        const responseData = response.data;
        console.log("res", response.data.data.data);
        setCompletedStages(responseData.data.completed_stages);

        setRejectMessage(response.data.data.ErrorMessage);

        setPanPrefill(response.data.data.data.pan_no);

        // if (response.data.data.data.dob) {
        //   setPanDobfill(dayjs(response.data.data.data.dob));
        // }
      })
      .catch((error: any) => {
        // console.log("error", error);
        setprefilloading(false);
      });
  }, []);

  //console.log("dobPrefill", dobPrefill);

  function panDetails(pan: any, name: any) {
    confirmAlert({
      title: "",
      message:
        "By Submitting the above I hereby allow Composite Investments Pvt Ltd to Capture my PAN details. I hereby authorized Composite Investments Pvt Ltd to fetch my details and open my account through Income Tax Dept.",
      buttons: [
        {
          label: "No",
          onClick: () => "",
        },
        {
          label: "Yes",
          onClick: () => {
            setloading(true);
            let obj: any = {
              pan_no: pan.toUpperCase(),
              // dob: date,
            };

            panDetailsAPICall(obj)
              .then((response: any) => {
                const responseData = response.data;

                if (!responseData) {
                  setMessage("Failed to get Data");
                  setTimeout(() => {
                    setMessage("");
                  }, 5000);
                }

                setSuccessMessage(
                  "PAN details have been fetched from Income Tax Department"
                );

                setTimeout(() => {
                  setSuccessMessage("");
                }, 5000);

                setTimeout(() => {
                  setloading(false);
                  if (
                    responseData.data.next_stage &&
                    navigationData.hasOwnProperty(responseData.data.next_stage)
                  ) {
                    navigate(navigationData[responseData.data.next_stage]);
                  }
                }, 3000);
              })
              .catch((error: any) => {
                setloading(false);

                setMessage(
                  error.message.includes("Network")
                    ? "Error Occured, Please try again"
                    : error.response.data.message
                );

                setTimeout(() => {
                  setMessage("");
                }, 5000);
              });
          },
        },
      ],
    });
  }

  const handleClick = (panNumber: any, name: any) => {
    // const dobLimit = new Date();
    // dobLimit.setFullYear(dobLimit.getFullYear() - 18);
    // if (!dob || dob > dobLimit) {
    //   // setDobError(true);

    //   setDobError("Your Age is Below 18");
    //   setTimeout(() => {
    //     setDobError("");
    //   }, 3000);
    //   // alert("Your Age is Below 18");
    // }
    // else {
    panDetails(panNumber, name);
    // }
  };

  const eighteenYearsAgo = dayjs().subtract(18, "year");
  const maxDate = dayjs("1940-01-01");

  const renderYear = (year) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {year - maxDate.year()}
      </div>
    );
  };

  const handlePanChange = (event: any) => {
    const input = event.target.value;
    const regex = /^[a-zA-Z0-9]*$/;
    if (regex.test(input) && input.length <= 10) {
      setPan(input.toUpperCase());
    }
  };

  return (
    <>
      <ErrorSnackBar message={rejectMessage} />
      <SnackBar message={message} />
      <SucessSnackBar message={successMessage} />
      <div className={PanStyles.desktopProgress}>
        <StageLayout
          completedStages={completedStages}
          stageName={"Pan Number"}
          navigatePath={"/panverification"}
        />
      </div>
      <div className={PanStyles.mainWrapper}>
        <div className={PanStyles.mobileProgress}>
          <StageLayout
            completedStages={completedStages}
            stageName={"Pan Number"}
            navigatePath={"/panverification"}
          />
        </div>
        <div className={PanStyles.wrapper}>
          {!prefilloading ? (
            <div className={PanStyles.LeftContainer}>
              <img src={PanImage} alt="Image" className={PanStyles.image} />
            </div>
          ) : (
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={open}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <div className={PanStyles.RightContainer}>
            {!prefilloading ? (
              <>
                <div className={PanStyles.headerSection}>
                  <p className={PanStyles.signup}>Enter Your PAN Details</p>
                </div>

                <Formik
                  validationSchema={schema}
                  initialValues={{
                    // name: namePrefill === "" ? "" : namePrefill,
                    pannumber: panPrefill === "" ? "" : panPrefill,
                    // dateofBirth: dobPrefill === "" ? null : dobPrefill,
                  }}
                  onSubmit={(values: any) => {
                    // const date = new Date(values.dateofBirth.$d);
                    // // console.log(date);
                    // const formattedDate = date.toLocaleDateString("en-GB", {
                    //   year: "numeric",
                    //   day: "2-digit",
                    //   month: "2-digit",
                    // });
                    // const [month, day, year] = formattedDate.split("/");
                    // const formatDate = `${year}/${day}/${month}`;

                    handleClick(values.pannumber, "DEMO");
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <>
                      <form
                        noValidate
                        onSubmit={handleSubmit}
                        onKeyDown={(event) => {
                          event.code === "Enter" ||
                            (event.code === "NumpadEnter" && handleSubmit());
                          // event.code.includes("Enter") && handleSubmit()
                        }}
                        style={{ width: "100%" }}
                      >
                        <>
                          <div className={PanStyles.textfeildSection}>
                            {/* <div>
                            <p className={PanStyles.textfieldLabel}>Mobile Number</p>
                              <TextField
                                label="Name as per PAN"
                                className={PanStyles.textfeild}
                                name="name"
                                variant="outlined"
                                id="outlined-basic"
                                value={values.name?.toUpperCase()}
                                error={touched.name && Boolean(errors.name)}
                                helperText={
                                  touched.name && errors.name?.toString()
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </div> */}
                            <div>
                              <p
                                className={PanStyles.textfieldLabel}
                                style={{ margin: "20px 0px 10px 0px" }}
                              >
                                Enter PAN Number
                              </p>
                              <TextField
                                className={PanStyles.textfeild}
                                id="outlined-basic"
                                placeholder="Enter PAN Number"
                                name="pannumber"
                                variant="outlined"
                                value={values.pannumber.toUpperCase()}
                                error={
                                  touched.pannumber && Boolean(errors.pannumber)
                                }
                                helperText={
                                  touched.pannumber &&
                                  errors.pannumber?.toString()
                                }
                                sx={{
                                  fieldset: { borderColor: "transparent" },
                                }}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                inputProps={{
                                  sx: {
                                    background: "#F2F2F6",
                                    borderRadius: "8px",
                                    "&::placeholder": {
                                      paddingLeft: "10px",
                                    },

                                    paddingLeft: "10px",
                                  },
                                  maxLength: 10,
                                }}

                                // InputProps={{
                                //   endAdornment: (
                                //     <InputAdornment position="end">
                                //       <Typography color="textSecondary">
                                //         {values.pannumber.length}/10
                                //       </Typography>
                                //     </InputAdornment>
                                //   ),
                                // }}
                              />
                            </div>
                            {/* <div>
                              <p
                                className={PanStyles.textfieldLabel}
                                style={{ margin: "20px 0px 0px 0px" }}
                              >
                                Enter date of birth as per your PAN
                              </p>
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                style={{ paddingTop: "0px" }}
                              >
                                <DemoContainer components={["DatePicker"]}>
                                  <DatePicker
                                    className={PanStyles.datePicker}
                                    components={{ OpenPickerIcon: DateIcon }}
                                    minDate={maxDate}
                                    maxDate={eighteenYearsAgo}
                                    format="DD/MM/YYYY"
                                    renderYear={renderYear}
                                    views={["year", "month", "day"]}
                                    name="dateofBirth"
                                    value={values.dateofBirth}
                                    onChange={(date) => {
                                      handleChange({
                                        target: {
                                          name: "dateofBirth",
                                          value: date,
                                        },
                                      });
                                    }}
                                    sx={{
                                      borderRadius: "10px",
                                      // width: "-webkit-fill-available",
                                      background: "#F2F2F6",
                                      outline: "none",
                                      fontSize: "12px",
                                      fontWeight: 500,
                                      padding: "0 0px",
                                      color: "#000000",
                                      boxShadow: "none",
                                      "& .MuiOutlinedInput-notchedOutline": {
                                        border: "none",
                                      },
                                    }}
                                    onBlur={handleBlur}
                                    error={
                                      touched.dateofBirth &&
                                      Boolean(errors.dateofBirth)
                                    }
                                    helperText={
                                      touched.dateofBirth && errors.dateofBirth
                                    }
                                    renderInput={(params: any) => (
                                      <TextField
                                        {...params}
                                        // placeholder="Enter Date of Birth As per Your PAN"
                                      />
                                    )}
                                    {...(DatePicker as any)}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                              <FormHelperText style={{ color: "#d32f2f" }}>
                                {dobError
                                  ? dobError
                                  : touched.dateofBirth &&
                                    errors.dateofBirth?.toString()}
                              </FormHelperText>
                            </div> */}
                          </div>

                          <div className={PanStyles.buttonSection}>
                            <LoadingButton
                              type="submit"
                              variant="contained"
                              loading={loading}
                              id="panVerificationContinueButton"
                              className={PanStyles.button}
                              onClick={() => {
                                handleSubmit();
                              }}
                              loadingIndicator={
                                <CircularProgress
                                  sx={{ color: "#4754A0" }}
                                  size={20}
                                />
                              }
                            >
                              Continue
                            </LoadingButton>
                          </div>
                        </>
                      </form>
                    </>
                  )}
                </Formik>
              </>
            ) : (
              <>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={open}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
