import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import LogoutIcon from "@mui/icons-material/Logout";

import {
  Chip,
  Fab,
  FormControl,
  InputLabel,
  Select,
  Stack,
} from "@mui/material";
import { useSelector, Provider } from "react-redux";
import { useEffect, useState } from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";

import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import EmailIcon from "@mui/icons-material/Email";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SucessSnackBar from "../Components/SnackBars/SucesssnackBar";
import SnackBar from "../Components/SnackBars/SnackBar";
import MenuIcon from "@mui/icons-material/Menu";

import CompositeLogo from "../Components/assets/Images/CompositeLogo.svg";

import ChatIcon from "@mui/icons-material/Chat";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [selectedoption, setSelectedOption] = React.useState<any>();

  const [styles, setStyles] = useState({
    borderRadius: "5px",
    background: "#4754A0",
    color: "white",
    height: "35px",
  });
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const pathName = useSelector((state: any) => state.PATHNAME.pathname);
  const [step, setStep] = useState<any>(0);
  const [stageDetailsApi, setStageDetailsApi] = useState<any>(0);
  const [showSteps, setshowSteps] = useState(false);
  const pathToStepMap = {
    "/": -4,
    "/confirm_otp": -3,
    "/yourdetails": -2,
    "/confirmemailotp": -1,
    "/panverification": 1,
    "/aadhaar": 2,
    "/tradingPreference": 3,
    "/yourprofile": 4,
    "/linkusingifsc": 5,
    "/ipv": 6,
    "/uploaddocuments": 7,
    "/nominee": 8,
    "/termsandconditions": 9,
    "/esign": 10,
    "/congratulations": 11,
  };
  useEffect(() => {
    Object.keys(pathToStepMap).forEach((path) => {
      if (pathName.includes(path)) {
        setStageDetailsApi(pathToStepMap[path]);
      }
    });
  }, [pathName]);

  useEffect(() => {
    if (stageDetailsApi > -1) {
      setshowSteps(true);
    }
  }, [step]);

  const handleChange = (event: any) => {
    setSelectedOption(event.target.value);
    // console.log("event.target.value", event.target.value);

    return <SnackBar message={"hello"} />;
  };

  const handleLogout = () => {
    if (window.confirm("You will be logged out")) {
      sessionStorage.clear();
      window.location.href = "/";
    }
  };

  const handleMouseEnter = () => {
    setIsMenuOpen(true);
    setStyles({
      borderRadius: "5px",
      background: "#4754A0",
      color: "white",
      height: "35px",
    });
  };

  const handleMouseLeave = () => {
    setIsMenuOpen(false);
    setStyles({
      borderRadius: "5px",
      background: "#4754A0",
      color: "white",
      height: "35px",
    });
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
    setStyles({
      borderRadius: "5px",
      background: "#4754A0",
      color: "white",
      height: "35px",
    });
  };

  const handleCopy = () => {
    setIsMenuOpen(false);
    setStyles({
      borderRadius: "5px",
      background: "#4754A0",
      color: "white",
      height: "35px",
    });
    setShowSnackBar(true);
    setSnackBarMessage("Details Copied");
    setTimeout(() => {
      setSnackBarMessage("");
    }, 5000);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleActionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const phoneNumber = pathName.includes("/completed")
    ? "123 123 1234"
    : "123 123 1234";
  const handleWhatsappClick = () => {
    setAnchorEl(null);
    const phoneNumberNew = pathName.includes("/completed")
      ? " +91-1234567894"
      : "+91-1234567891";
    const message = "Hello, I wanted to connect with you!";
    const url = `https://api.whatsapp.com/send?phone=${encodeURIComponent(
      phoneNumberNew
    )}&text=${encodeURIComponent(message)}`;
    window.open(url, "_blank");
  };

  const handleDialpadClick = () => {
    setAnchorEl(null);
    const dialpadUrl = `tel:${phoneNumber}`;
    window.location.href = dialpadUrl;
  };
  const handleEmailClick = () => {
    const emailAddress = pathName.includes("/completed")
      ? " support@company.com"
      : "sales@company.com";
    const subject = "hello";

    const emailUrl = `mailto:${emailAddress}?subject=${encodeURIComponent(
      subject
    )}`;
    window.location.href = emailUrl;
    setAnchorEl(null);
  };
  return (
    <AppBar
      position={pathName === "/home" ? "absolute" : "relative"}
      sx={{
        backgroundColor: pathName === "/home" ? "white" : "white",
        boxShadow: "none",
        opacity: pathName === "/home" ? "70%" : "100%",
        borderRadius: { xs: "0px 0px 0px 0px", md: "0px" },
      }}
    >
      {showSnackBar && <SucessSnackBar message={snackBarMessage} />}
      <Container
        maxWidth="xl"
        sx={{ paddingLeft: "0 !important", paddingRight: "0 !important" }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Stack sx={{ display: { xs: "none", md: "flex" } }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {" "}
              <img
                src={CompositeLogo}
                style={{ zIndex: 100, cursor: "pointer", width: "150px" }}
                onClick={() => {}}
              />{" "}
            </div>
          </Stack>

          <Typography
            variant="h5"
            noWrap
            component="a"
            // href=""
            sx={{
              mr: 0,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "black",
              textDecoration: "none",
            }}
          >
            <img
              src={CompositeLogo}
              style={{ zIndex: 100, cursor: "pointer", width: "150px" }}
              onClick={() => {}}
            />{" "}
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                width: { xs: "max-content", md: 130 },
                display: { xs: "flex", sm: "flex" },
              }}
            >
              <FormControl
                fullWidth
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={setSelectedOption}
                  size="small"
                  open={isMenuOpen}
                  sx={{
                    ...styles,
                    background: { xs: "white", sm: styles.background },
                    color: { xs: "#000", sm: styles.color },

                    height: "unset",
                    "& .MuiSelect-select": { paddingRight: "12px !important" },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        paddingTop: 0,
                        paddingBottom: 0,
                        marginTop: "10px",
                      },
                    },
                    MenuListProps: {
                      sx: {
                        paddingTop: 0,
                        paddingBottom: 0,
                      },
                    },
                  }}
                  onClose={handleMenuClose}
                  onChange={handleChange}
                  IconComponent={() => null}
                  renderValue={(value) => (
                    <>
                      <Stack
                        sx={{ display: { xs: "none", md: "flex" } }}
                        style={{
                          textAlign: "center",
                          marginLeft: "2px",
                          fontSize: "14px",
                        }}
                      >
                        Need any Help?
                      </Stack>
                      <Stack
                        sx={{
                          display: { xs: "flex", md: "none" },
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <MenuIcon />
                      </Stack>
                    </>
                  )}
                >
                  <CopyToClipboard text={"+91-984470001"} onCopy={handleCopy}>
                    <MenuItem
                      value={"+91-984470001"}
                      sx={{
                        background: "#4754A0",
                        fontSize: "14px",
                        color: "white",
                        "&:hover": {
                          background: "#4754A0",
                          fontSize: "14px",
                          color: "white",
                        },
                      }}
                    >
                      {/* <WhatsAppIcon sx={{ marginRight: "3px" }} /> */}
                      {"+91-984470001"}
                    </MenuItem>
                  </CopyToClipboard>
                  <CopyToClipboard text={"9844183084"} onCopy={handleCopy}>
                    <MenuItem
                      value={"9844183084"}
                      sx={{
                        background: "#4754A0",
                        fontSize: "14px",
                        color: "white",
                        "&:hover": {
                          background: "#4754A0",
                          fontSize: "14px",
                          color: "white",
                        },
                      }}
                    >
                      {/* <PhoneInTalkIcon sx={{ marginRight: "3px" }} /> */}
                      {"9844183084"}
                    </MenuItem>
                  </CopyToClipboard>
                  <CopyToClipboard
                    text={"help@compositeinvestments.com"}
                    onCopy={handleCopy}
                  >
                    <MenuItem
                      value={"help@compositeinvestments.com"}
                      sx={{
                        background: "#4754A0",
                        fontSize: "14px",
                        color: "white",
                        "&:hover": {
                          background: "#4754A0",
                          fontSize: "14px",
                          color: "white",
                        },
                      }}
                    >
                      <MailOutlineIcon sx={{ marginRight: "3px" }} />
                      {"help@compositeinvestments.com"}
                    </MenuItem>
                  </CopyToClipboard>
                  {showSteps && (
                    <>
                      {stageDetailsApi > 0 && stageDetailsApi <= 10 && (
                        <MenuItem
                          value={
                            pathName.includes("/completed")
                              ? " support@thefirstock.com"
                              : "sales@thefirstock.com"
                          }
                          sx={{
                            background: "#4754A0",
                            fontSize: "14px",
                            color: "white",
                            display: { xs: "flex", sm: "none" },
                            "&:hover": {
                              background: "#4754A0",
                              fontSize: "14px",
                              color: "white",
                            },
                          }}
                          onClick={handleLogout}
                        >
                          <LogoutIcon sx={{ marginRight: "3px" }} />
                          Logout
                        </MenuItem>
                      )}
                    </>
                  )}
                </Select>
              </FormControl>
            </Box>

            {showSteps && (
              <>
                {stageDetailsApi > 0 && stageDetailsApi <= 10 && (
                  <Button
                    onClick={handleLogout}
                    variant="text"
                    endIcon={<LogoutIcon sx={{ fill: "#4754A0" }} />}
                    sx={{
                      height: "33px",
                      marginLeft: "15px",
                      color: "#4754A0",
                      borderRadius: "100px",
                      display: { xs: "none", sm: "flex" },
                    }}
                  >
                    Logout
                  </Button>
                )}
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
