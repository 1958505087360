import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  FormHelperText,
  Snackbar,
  Alert,
  TextField,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import {
  validateMobileOTPAPICall,
  reSendMobileOTPAPICall,
  SaveIpAddressAPICall,
} from "../shared/APICalls";
import {
  IMobileOtpValidation,
  IReSendMobileOTP,
  ISaveIPaddress,
} from "../shared/dtos";
import routesData from "../shared/routes.json";
import ConfitmOtpStyles from "../pages/Styles/ConfitmOtpStyles.module.scss";
import SignUpImage from "../Components/assets/Images/CompLeftImage.svg";
import SnackBar from "../Components/SnackBars/SnackBar";
import Slide, { SlideProps } from "@mui/material/Slide";
import { MuiOtpInput } from "mui-one-time-password-input";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import axios from "axios";

type TransitionProps = Omit<SlideProps, "direction">;

function TransitionUp(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}

export default function ConfirmOtpPage() {
  const navigate = useNavigate();

  const mobile: any = sessionStorage.getItem("mobile");
  const latitude: any = sessionStorage.getItem("latitude");
  const longitude: any = sessionStorage.getItem("longitude");
  const [otp, setOTP] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState<any>(30);
  const [timerId, setTimerId] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [wrongOTP, setWrongOTP] = useState(false);
  const [loading, setloading] = useState(false);
  const [message, setMessage] = useState("");

  const [IPAddress, setIPAddress] = useState("");
  const otpInputs = useRef<any>([]);
  const [transition, setTransition] = React.useState<
    React.ComponentType<TransitionProps> | undefined
  >(undefined);

  // console.log("mobile", mobile);

  const [phoneNumber, setPhoneNumber] = React.useState("");

  const navigationData = routesData;
  useEffect(() => {
    window.scrollTo(0, 0);
    if (mobile === null) {
      navigate("/");
    }
    // const fetchIPAddress = async () => {
    //   try {
    //     const response = await axios.get("https://checkip.amazonaws.com/");
    //     setIPAddress(response.data);
    //   } catch (err: any) {}
    // };
    // fetchIPAddress();
  }, []);

  useEffect(() => {
    setIsButtonDisabled(true);
    setCountdown(30);

    if (timerId === null) {
      let interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      setTimerId(interval);
    }
  }, []);
  useEffect(() => {
    if (countdown <= 0 && timerId !== null) {
      setIsButtonDisabled(false);

      clearInterval(timerId);
      setTimerId(null);
    }
  }, [countdown]);

  const handlePhoneNumberChange = (event: any) => {
    setPhoneNumber(event.target.value);
  };

  function validateMobileOTP(data: any) {
    setloading(true);

    const source = sessionStorage.getItem("source");
    const id = sessionStorage.getItem("id");

    let obj: IMobileOtpValidation = {
      mobile: mobile,
      mobile_otp: data,
      ref_source: source ? source : "web",
      ref_code: id ? id : "",
    };

    validateMobileOTPAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        // console.log("response", response);
        if (responseData.data.access_token) {
          // console.log("token", responseData.data.access_token);
          sessionStorage.setItem(
            "access-token",
            responseData.data.access_token
          );
        }

        if (responseData.data.client_code) {
          sessionStorage.setItem("client-id", responseData.data.client_code);
          sessionStorage.setItem("client_name", responseData.data.client_name);
        }
        sessionStorage.removeItem("source");
        sessionStorage.removeItem("id");
        setloading(false);
        setMessage(responseData.data.message);
        setWrongOTP(false);
        let objIPAddress: ISaveIPaddress = {
          latitude: latitude,
          longitude: longitude,
        };
        SaveIpAddressAPICall(objIPAddress)
          .then((response: any) => {
            // console.log("responseIPSave", response.data);
            if (
              responseData.data.next_stage &&
              navigationData.hasOwnProperty(responseData.data.next_stage)
            ) {
              navigate(navigationData[responseData.data.next_stage]);
            }
          })
          .catch((error: any) => {
            setloading(false);
            setMessage(
              error.message.includes("Network")
                ? "Error occured, Please try again"
                : error.response.data.message
            );
            setTimeout(() => {
              setMessage("");
            }, 5000);
          });
      })
      .catch((error: any) => {
        // console.log(error.response.status);
        setWrongOTP(true);
        setloading(false);
        if (error.response.status === 404) {
          setMessage("Failed to Continue, Please try Again");
          setTimeout(() => {
            setMessage("");
          }, 5000);
        }
        setMessage(
          error.message.includes("Network")
            ? "Error occured, Please try again"
            : error.response.data.message
        );
        setTimeout(() => {
          setMessage("");
        }, 5000);
      });
  }

  function reSendMobileOTP() {
    let obj: IReSendMobileOTP = {
      mobile: mobile,
    };
    reSendMobileOTPAPICall(obj)
      .then((response: any) => {
        const responseData = response.data.data;
        setloading(false);
      })
      .catch((error: any) => {
        setloading(false);
      });
    setTransition(() => TransitionUp);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
    setIsButtonDisabled(true);
    setCountdown(30);

    if (timerId === null) {
      let interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      setTimerId(interval);
    }
  }
  const handleResendOTP = () => {
    setloading(true);
    reSendMobileOTP();
  };
  const handleBackClick = () => {
    navigate("/");
  };

  const handleClose = () => {
    setOpen(false);
  };

  function validateOTP(value: any) {
    let error: any;
    if (!value) {
      error = "OTP Required";
    } else if (!/^[0-9]{6}$/.test(value)) {
      error = "Must be a valid 6 digit OTP";
    }
    return error;
  }

  const handleChangeField = (
    name: any,
    value: any,
    setFieldValue: any,
    values: any
  ) => {
    setFieldValue(name, value);
    // console.log(value);
    if (value.length === 6) {
      validateMobileOTP(value);
    }
  };

  const handleKeyPress = (event: any) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValidKey = /^\d+$/.test(keyValue);

    if (!isValidKey) {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className={ConfitmOtpStyles.wrapper}>
        <div className={ConfitmOtpStyles.LeftContainer}>
          <img
            src={SignUpImage}
            alt="Image"
            className={ConfitmOtpStyles.image}
          />
        </div>
        <div className={ConfitmOtpStyles.RightContainer}>
          <div className={ConfitmOtpStyles.headerSection}>
            <p className={ConfitmOtpStyles.signup}>OTP Verification</p>
            <p className={ConfitmOtpStyles.subText}>
              Enter 6 Digit code sent to your number <span>{mobile}</span>{" "}
              <a onClick={handleBackClick} className={ConfitmOtpStyles.change}>
                (Change)
              </a>
            </p>
          </div>

          <div>
            <Formik
              initialValues={{
                mobileotp: "",
              }}
              onSubmit={(values) => {
                setloading(true);
                setOTP(values.mobileotp);
                validateMobileOTP(values.mobileotp);
              }}
            >
              {({
                errors,
                touched,
                handleChange,
                values,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form
                  onKeyDown={(event) => {
                    event.code === "Enter" && handleSubmit();
                  }}
                >
                  <div className={ConfitmOtpStyles.inputBlock}>
                    <div className={ConfitmOtpStyles.mobileDisplayOTP}>
                      <Field
                        name="mobileotp"
                        validate={validateOTP}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="Enter OTP"
                            type="numeric"
                            className={ConfitmOtpStyles.textfield}
                            variant="outlined"
                            value={values.mobileotp}
                            onChange={(event) => {
                              const updatedValue = event.target.value;
                              handleChangeField(
                                "mobileotp",
                                updatedValue,
                                setFieldValue,
                                values
                              );
                            }}
                            sx={{
                              fieldset: {
                                borderColor: "transparent",
                              },
                            }}
                            inputProps={{
                              sx: {
                                background: "#F2F2F6",
                                borderRadius: "8px",
                                "&::placeholder": {
                                  paddingLeft: "10px",
                                },

                                paddingLeft: "10px",
                              },
                              maxLength: 6,
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                            }}
                            onKeyPress={(event) => {
                              const keyCode = event.keyCode || event.which;
                              const keyValue = String.fromCharCode(keyCode);
                              const isValidKey = /^\d+$/.test(keyValue);

                              if (!isValidKey) {
                                event.preventDefault();
                              }
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className={ConfitmOtpStyles.desktopDisplayOTP}>
                      <MuiOtpInput
                        length={6}
                        value={values.mobileotp.toUpperCase()}
                        autoFocus
                        onChange={(event: any) => {
                          const updatedValue = event;
                          handleChangeField(
                            "mobileotp",
                            updatedValue,
                            setFieldValue,
                            values
                          );
                        }}
                        inputMode="numeric"
                        onKeyPress={handleKeyPress}
                        sx={{
                          ".MuiOutlinedInput-notchedOutline": {
                            borderRadius: "10px",
                            border: "0px solid #9FA4A5",
                          },
                          ".MuiOutlinedInput-input": {
                            padding: "0px !important",
                            height: "60px",
                            background: "#F2F2F6",
                            borderRadius: "10px",
                          },
                        }}
                      />
                    </div>
                    <FormHelperText style={{ color: "#d32f2f" }}>
                      {touched.mobileotp && errors.mobileotp?.toString()}
                    </FormHelperText>

                    <SnackBar message={message} />

                    {isButtonDisabled ? (
                      <div className={ConfitmOtpStyles.resendSection}>
                        <p className={ConfitmOtpStyles.clickhere}>
                          Time Remaining {countdown}s
                        </p>
                      </div>
                    ) : (
                      <div
                        className={ConfitmOtpStyles.resendSection}
                        style={{ justifyContent: "flex-end" }}
                      >
                        {/* <p className={ConfitmOtpStyles.clickhere}>
                          Click here to{" "}
                        </p> */}
                        <LoadingButton
                          onClick={handleResendOTP}
                          variant="text"
                          id="confirmMobileOtpResendOtpButton"
                          sx={{
                            marginTop: "5px",
                            color: "#006779",
                            textDecoration: "underline",
                          }}
                        >
                          Resend OTP
                        </LoadingButton>
                      </div>
                    )}

                    <div className={ConfitmOtpStyles.buttonSection}>
                      <LoadingButton
                        variant="contained"
                        type="submit"
                        loading={loading}
                        id="confirmMobileOtpContinueButton"
                        className={ConfitmOtpStyles.button}
                        onClick={() => {
                          handleSubmit();
                        }}
                        loadingIndicator={
                          <CircularProgress sx={{ color: "#4754A0" }} size={20} />
                        }
                      >
                        Continue
                      </LoadingButton>
                    </div>
                    <p className={ConfitmOtpStyles.LoginText}>
                      Already have an account?
                      <a
                        href="https://xts.compositedge.com/#!/app"
                        style={{
                          color: "#4754A0",
                          marginLeft: "5px",
                          cursor: "pointer",
                        }}
                      >
                        Login
                      </a>{" "}
                      <ArrowOutwardIcon
                        style={{ fontSize: "18px", color: "#4754A0" }}
                      />
                    </p>

                    <Snackbar
                      open={open}
                      autoHideDuration={3000}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      TransitionComponent={transition}
                      sx={{ marginTop: "50px" }}
                    >
                      <div
                        style={{ background: "#54BF00", borderRadius: "10px " }}
                      >
                        <div style={{ marginLeft: "30px" }}>
                          <Alert
                            icon={false}
                            severity="success"
                            sx={{
                              width: "max-content",
                              height: "50px",
                              fontSize: "16px",
                              borderRadius: "0",
                            }}
                          >
                            Successfully sent the OTP to your Mobile.
                          </Alert>
                        </div>
                      </div>
                    </Snackbar>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}
