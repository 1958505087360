import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  FormHelperText,
  Snackbar,
  Alert,
  TextField,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import {
  validateEmailOTPAPICall,
  reSendEmailOTPAPICall,
} from "../shared/APICalls";
import { IEmailOTPValidation, IReSendEmailOTP } from "../shared/dtos";
import routesData from "../shared/routes.json";
import ConfitmEmailOtpStyles from "../pages/Styles/ConfirmEmailOtp.module.scss";
import emailOtpImage from "../Components/assets/Images/CompLeftImage.svg";
import SnackBar from "../Components/SnackBars/SnackBar";
import Slide, { SlideProps } from "@mui/material/Slide";
import { MuiOtpInput } from "mui-one-time-password-input";
type TransitionProps = Omit<SlideProps, "direction">;

function TransitionUp(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}

export default function ConfirmEmailOtpPage() {
  const navigate = useNavigate();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState<any>(30);
  const [timerId, setTimerId] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [wrongOTP, setWrongOTP] = useState(false);
  const [loading, setloading] = useState(false);
  const otpInputs = useRef<any>([]);
  const [message, setMessage] = useState("");
  const navigationData = routesData;
  const [transition, setTransition] = React.useState<
    React.ComponentType<TransitionProps> | undefined
  >(undefined);

  const clientEmail: any = sessionStorage.getItem("client-email");

  useEffect(() => {
    if (countdown <= 0 && timerId !== null) {
      setIsButtonDisabled(false);

      clearInterval(timerId);
      setTimerId(null);
    }
  }, [countdown]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsButtonDisabled(true);
    setCountdown(30);

    if (timerId === null) {
      let interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      setTimerId(interval);
    }
  }, []);

  function emailOTPValidation(emailOTP: any) {
    // console.log("emailOTP", emailOTP);
    let obj: IEmailOTPValidation = {
      email: clientEmail,
      email_otp: emailOTP,
    };
    validateEmailOTPAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        setWrongOTP(false);
        setloading(false);
        if (responseData.data.access_token) {
          // console.log("token", responseData.data.access_token);
          sessionStorage.setItem(
            "access-token",
            responseData.data.access_token
          );
        }
        if (
          responseData.data.next_stage &&
          navigationData.hasOwnProperty(responseData.data.next_stage)
        ) {
          navigate(navigationData[responseData.data.next_stage]);
        }
        //navigate("/panverification");
      })
      .catch((error: any) => {
        setloading(false);
        setWrongOTP(true);

        setMessage(
          error.message.includes("Network")
            ? "Error occured, Please try again"
            : error.response.data.message
        );
        setTimeout(() => {
          setMessage("");
        }, 5000);
      });
  }

  function reSendemailOTP() {
    let obj: IReSendEmailOTP = {
      email: clientEmail,
    };
    reSendEmailOTPAPICall(obj)
      .then((response: any) => {
        const responseData = response.data.data;
        setloading(false);
        setTransition(() => TransitionUp);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 3000);
        setIsButtonDisabled(true);
        setCountdown(30);
      })
      .catch((error: any) => {
        setloading(false);
      });

    if (timerId === null) {
      let interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      setTimerId(interval);
    }
  }

  const handleBackClick = () => {
    navigate("/yourdetails");
  };

  const handleResendOTP = () => {
    setloading(true);
    reSendemailOTP();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event: any) => {
    //setemail(event.target.value);
  };
  const handleClick = () => {
    navigate("/panverification");
  };
  function validateEmailOTP(value: any) {
    let error: any;
    if (!value) {
      error = "Email OTP Required";
    } else if (!/^[0-9]{6}$/.test(value)) {
      error = "Must be a valid 6 digit OTP";
    }
    return error;
  }

  const handleChangeField = (
    name: any,
    value: any,
    setFieldValue: any,
    values: any
  ) => {
    setFieldValue(name, value);
    //console.log( value);
    if (value.length === 6) {
      emailOTPValidation(value);
    }
  };
  const handleKeyPress = (event: any) => {
    if (!/^[0-9]$/.test(event.key)) {
      event.preventDefault();
    }
  };
  return (
    <>
      <div className={ConfitmEmailOtpStyles.wrapper}>
        <div className={ConfitmEmailOtpStyles.LeftContainer}>
          <img
            src={emailOtpImage}
            alt="Image"
            className={ConfitmEmailOtpStyles.image}
          />
        </div>
        <div className={ConfitmEmailOtpStyles.RightContainer}>
          <div className={ConfitmEmailOtpStyles.headerSection}>
            <p className={ConfitmEmailOtpStyles.signup}>OTP Verification</p>
            <p className={ConfitmEmailOtpStyles.subText}>
              Enter 6 Digit code sent to your mail <span>{clientEmail}</span>{" "}
              <a
                onClick={handleBackClick}
                className={ConfitmEmailOtpStyles.change}
              >
                (Change)
              </a>
            </p>
          </div>

          <div>
            <Formik
              initialValues={{
                emailotp: "",
              }}
              onSubmit={(values) => {
                setloading(true);
                emailOTPValidation(values.emailotp);
              }}
            >
              {({
                errors,
                touched,
                handleChange,
                values,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form
                  onKeyDown={(event) => {
                    event.code.includes("Enter") && handleSubmit();
                  }}
                >
                  <div className={ConfitmEmailOtpStyles.inputBlock}>
                    <div className={ConfitmEmailOtpStyles.mobileDisplayOTP}>
                      <Field
                        name="emailotp"
                        validate={validateEmailOTP}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="Email OTP"
                            className={ConfitmEmailOtpStyles.textfield}
                            variant="outlined"
                            value={values.emailotp}
                            type="numeric"
                            onChange={(event) => {
                              const updatedValue = event.target.value;
                              handleChangeField(
                                "emailotp",
                                updatedValue,
                                setFieldValue,
                                values
                              );
                            }}
                            sx={{
                              fieldset: {
                                borderColor: "transparent",
                              },
                            }}
                            inputProps={{
                              sx: {
                                background: "#F2F2F6",
                                borderRadius: "8px",
                                "&::placeholder": {
                                  paddingLeft: "10px",
                                },

                                paddingLeft: "10px",
                              },
                              maxLength: 6,
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                            }}
                            onKeyPress={(event) => {
                              const keyCode = event.keyCode || event.which;
                              const keyValue = String.fromCharCode(keyCode);
                              const isValidKey = /^\d+$/.test(keyValue);

                              if (!isValidKey) {
                                event.preventDefault();
                              }
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className={ConfitmEmailOtpStyles.desktopDisplayOTP}>
                      <MuiOtpInput
                        length={6}
                        value={values.emailotp}
                        //   name="partnercode"
                        autoFocus
                        inputMode="numeric"
                        onChange={(event: any) => {
                          const updatedValue = event;
                          handleChangeField(
                            "emailotp",
                            updatedValue,
                            setFieldValue,
                            values
                          );
                        }}
                        onKeyPress={handleKeyPress}
                        sx={{
                          ".MuiOutlinedInput-notchedOutline": {
                            borderRadius: "10px",
                            border: "0px solid #9FA4A5",
                          },
                          ".MuiOutlinedInput-input": {
                            padding: "0px !important",
                            height: "60px",
                            background: "#F2F2F6",
                            borderRadius: "10px",
                          },
                        }}
                      />
                    </div>
                    <FormHelperText style={{ color: "#d32f2f" }}>
                      {touched.emailotp && errors.emailotp?.toString()}
                    </FormHelperText>

                    <SnackBar message={message} />

                    {isButtonDisabled ? (
                      <div className={ConfitmEmailOtpStyles.resendSection}>
                        <p className={ConfitmEmailOtpStyles.clickhere}>
                          Time Remaining {countdown}s
                        </p>
                      </div>
                    ) : (
                      <div
                        className={ConfitmEmailOtpStyles.resendSection}
                        style={{ justifyContent: "flex-end" }}
                      >
                        {/* <p className={ConfitmEmailOtpStyles.clickhere}>
                          Click here to{" "}
                        </p> */}
                        <LoadingButton
                          onClick={handleResendOTP}
                          variant="text"
                          id="confirmMobileOtpResendOtpButton"
                          sx={{
                            marginTop: "5px",
                            color: "#006779",
                            textDecoration: "underline",
                          }}
                          loadingIndicator={
                            <CircularProgress sx={{ color: "#4754A0" }} size={20} />
                          }
                        >
                          Resend OTP
                        </LoadingButton>
                      </div>
                    )}

                    <div className={ConfitmEmailOtpStyles.buttonSection}>
                      {/* {values.emailotp.length === 6 ? ( */}
                      <LoadingButton
                        variant="contained"
                        type="submit"
                        loading={loading}
                        id="confirmEmailOtpContinueButton"
                        className={ConfitmEmailOtpStyles.button}
                        onClick={() => {
                          handleSubmit();
                        }}
                        loadingIndicator={
                          <CircularProgress sx={{ color: "#4754A0" }} size={20} />
                        }
                      >
                        Continue
                      </LoadingButton>
                      {/* ) : (
                        <Button
                          variant="contained"
                          disabled
                          id="confirmEmailOtpContinueDisabledButton"
                          className={ConfitmEmailOtpStyles.button}
                        >
                          Continue
                        </Button>
                      )} */}
                    </div>
                    <Snackbar
                      open={open}
                      autoHideDuration={3000}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      TransitionComponent={transition}
                      sx={{ marginTop: "50px" }}
                    >
                      <div
                        style={{ background: "#54BF00", borderRadius: "10px " }}
                      >
                        <div style={{ marginLeft: "30px" }}>
                          <Alert
                            icon={false}
                            severity="success"
                            sx={{
                              width: "max-content",
                              height: "50px",
                              fontSize: "16px",
                              borderRadius: "0",
                            }}
                          >
                            Successfully sent the OTP to your Email.
                          </Alert>
                        </div>
                      </div>
                    </Snackbar>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}
