import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import SuccessPage from "./SuccessPage";
import ErrorPage from "./errorPage";
import PageNotFound from "./pageNotFound";

const MapRoutes: any = {
  success: <SuccessPage />,
  error: <ErrorPage />,
  not_found: <PageNotFound />,
};

export default function HomePage() {
  let page = "not_found";

  const currentLink = window.location.href;

  // console.log("currentLink",currentLink)

  const getCurrentPage = () => {
    if (currentLink.includes(`&success=True`)) {
      return "success";
    } else if (currentLink.includes(`&status=false`)) {
      return "error";
    } else {
      return "not_found";
    }
  };

  page = getCurrentPage();

  return <div>{MapRoutes[page]}</div>;
}
