import React, { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  Typography,
  Button,
  Avatar,
} from "@mui/material";
import DoneImage from "../Images/doneicon.png";
import { useNavigate } from "react-router-dom";
import completedImage from "../Components/assets/Images/completedimagenew.svg";
import Completedstyles from "../pages/Styles/Completed.module.scss";
import icon from "../Components/assets/Images/iconnew.svg";
import { LoadingButton } from "@mui/lab";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";
import facebookImage from "../Components/assets/Images/Facebook.svg";
import twitterImage from "../Components/assets/Images/twitter.svg";
import linkedinImage from "../Components/assets/Images/linkedin.svg";
import whatsappImage from "../Components/assets/Images/Whatsappnew.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";

import CopiedSnackBar from "../Components/SnackBars/CopiedSnackBar";
import GooglePlayStore from "../Components/assets/Images/GooglePlayStore.svg";
import AppStore from "../Components/assets/Images/AppStore.svg";
import MicroSoft from "../Components/assets/Images/MicrosoftStore.svg";
import StageLayout from "../Layouts/StageLayout";

export default function CompletedPage() {
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  const [message, setmessage] = useState("");
  const [completedStages, setCompletedStages] = useState<any>();
  const [backButton, setBackButton] = useState("");

  const ClientCode = sessionStorage.getItem("client-id");
  const ClientName = sessionStorage.getItem("client_name");

  const baseUrl = window.location.href.split("/completed")[0];

  const urlToShare = `${baseUrl}?c=${ClientCode}`;

  return (
    <>
      <div className={Completedstyles.desktopProgress}>
        <StageLayout
          completedStages={completedStages}
          stageName={"Esign Section"}
          navigatePath={"/cameramodule"}
          esignBackButtonEnable={backButton}
        />
      </div>
      <div className={Completedstyles.mainWrapperTop}>
        <div className={Completedstyles.mobileProgress}>
          <StageLayout
            completedStages={completedStages}
            stageName={"Esign Section"}
            navigatePath={"/cameramodule"}
            esignBackButtonEnable={backButton}
          />
        </div>

        <div className={Completedstyles.wrapper}>
          <div className={Completedstyles.container}>
            <p className={Completedstyles.header}>
              Congratulations! Your account has been activated successfully
            </p>
            <div className={Completedstyles.contentWrapper}>
              <div className={Completedstyles.contentWrapperLeft}>
                <img src={completedImage} className={Completedstyles.image} />
              </div>
              <div className={Completedstyles.bottomTextSection}>
                <p className={Completedstyles.bottomText}>
                  Free Equity Delivery :{" "}
                  <span className={Completedstyles.bottomSubText}>
                    All equity delivery investments (NSE, BSE), are absolutely
                    free — Zero Commission.
                  </span>
                </p>
                <p className={Completedstyles.bottomText}>
                  Intraday and F&O trades :{" "}
                  <span className={Completedstyles.bottomSubText}>
                    Flat ₹ 20 or 0.03% (whichever is lower)per executed order
                    across equity.
                  </span>
                </p>
                <p className={Completedstyles.bottomText}>
                  No Hidden Charges :{" "}
                  <span className={Completedstyles.bottomSubText}>
                    100% transparent.
                  </span>
                </p>
              </div>
            </div>

            <div>
              <LoadingButton
                id="tradingPreferencesContinueButton"
                variant="contained"
                type="submit"
                className={Completedstyles.approveButton}
                onClick={() => {
                  window.open("https://xts.compositedge.com/#/app", "_blank");
                }}
              >
                Login
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
