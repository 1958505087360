import React, { useEffect, useState } from "react";
import {
  Stack,
  Button,
  TextField,
  Modal,
  Fade,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import PdfModal from "../PdfModal/PdfModal";
import axios from "axios";
import PreviewModalStyles from "./PreviewModal.module.scss";
import PDFViewerIframe from "../PDFIframe/PDFWithIframe";

const stylePreview = {
  position: "absolute" as "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vW",
  height: "80vh",
  bgcolor: "background.paper",
  marginTop: "50px",
  p: 2,
  boxShadow: 24,
  zIndex: 100,
  borderRadius: "20px",
};

interface Props {
  selectedFile: any;
  image: any;
  handleClosePreview: any;
  documentName: any;
}

export default function PreviewModalPopup({
  selectedFile,
  image,
  handleClosePreview,
  documentName,
}: Props) {
  const [blobPdf, setBlobPdf] = useState<any>();
  //console.log("image", image);
  // console.log("selectedFile", selectedFile);
  const convertToBlob = async () => {
    const url = selectedFile; // Replace with your URL

    try {
      const response = await axios.get(url, { responseType: "blob" });
      const blob = response.data;

      // Use the converted blob as needed
      // console.log("file", blob);
    } catch (error) {
      // console.error("Error converting URL to Blob:", error);
    }
  };

  useEffect(() => {
    convertToBlob();
  }, [selectedFile]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={true}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={stylePreview}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          margin={"0 30px"}
        >
          <Typography variant="h6">
            {documentName.charAt(0).toUpperCase() + documentName.slice(1).replace(/_/g, ' ')}{" "}
            Preview
          </Typography>
          <IconButton onClick={handleClosePreview}>
            <ClearIcon />
          </IconButton>
        </Stack>
        <Stack
          alignItems={"center"}
          style={{
            maxWidth: "100%",
            maxHeight: "90%",
            objectFit: "contain",
            // overflowY: "scroll",
            paddingTop: "10px",
          }}
        >
          {(selectedFile && selectedFile.type === "application/pdf") ||
          !image ||
          (selectedFile && selectedFile.includes("pdf")) ? (
            <div>
              <div className={PreviewModalStyles.pdfMobilePreview}>
                {/* <PdfModal url={URL.createObjectURL(selectedFile)} /> */}
                {/* <iframe
                src={selectedFile}
                width="100%"
                height="450px"
                title="PDF Viewer"
              /> */}
                {selectedFile.type === "application/pdf" ? (
                  <PdfModal url={URL.createObjectURL(selectedFile)} />
                ) : (
                  // <PdfModal url={selectedFile} />
                  <iframe
                    src={selectedFile}
                    width="100%"
                    height="450px"
                    title="PDF Viewer"
                  />
                )}
              </div>
              <div className={PreviewModalStyles.pdfDesktopPreview}>
                {selectedFile.type === "application/pdf" ? (
                  <embed
                    src={URL.createObjectURL(selectedFile)}
                    width="980"
                    height="100%"
                    type="application/pdf"
                  />
                ) : (
                  <iframe
                    src={selectedFile}
                    width={"980px"}
                    height="100%"
                    title="PDF Viewer"
                  />
                )}

                {/* <PDFViewerIframe urldata={selectedFile} /> */}
              </div>
            </div>
          ) : (
            <img
              src={image}
              style={{
                height: "70vh",
                width: "80vw",
                objectFit: "contain",
                border: "1px solid grey",
                borderRadius: "20px",
              }}
            />
          )}
        </Stack>
      </Box>
    </Modal>
  );
}
