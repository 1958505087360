import React, { useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Navigate, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import routesData from "../../shared/routes.json";
import {
  PaymentAuthAPICall,
  PaymentCallbackAPICall,
  PaymentTransactionAPICall,
  stageDetailsAPICall,
} from "../../shared/APICalls";

import StageLayout from "../../Layouts/StageLayout";
import Styles from "./Payment.module.scss";
import atomImage from "../../Components/assets/Images/atomImage.svg";
import paymentRadio from "../../Components/assets/Images/paymentRadio.svg";
import SnackBar from "../../Components/SnackBars/SnackBar";

declare global {
  interface Window {
    AtomPaynetz: any;
  }
}

export default function Payments() {
  const redirectToken = sessionStorage.getItem("access-token");
  const [loading, setloading] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [authDetails, setAuthDetails] = useState({
    atomTokenId: "",
    responseDetails: {
      txnStatusCode: "",
      txnMessage: "",
      txnDescription: "",
    },
  });

  const [completedStages, setCompletedStages] = useState<any>();
  const urlParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlParams.entries());
  const token: any = params.token ? params.token : redirectToken;

  sessionStorage.setItem("access-token", token);

  const navigate = useNavigate();
  const navigationData = routesData;

  useEffect(() => {
    window.scrollTo(0, 0);
    // checkUserLoggedIn(navigate);

    let obj: any = {
      stage: "t_and_c",
    };
    if (token) {
      stageDetailsAPICall(obj)
        .then((response: any) => {
          const responseData = response.data;
          setCompletedStages(responseData.data.completed_stages);
        })
        .catch((error: any) => {});
    }

    // PaymentTransaction();
  }, [token]);

  const PaymentAuth = () => {
    let obj: any = {
      amount: "400",
    };
    setloading(true);
    PaymentAuthAPICall(obj)
      .then((response: any) => {
        setloading(false);
        console.log("trans", response.data.data);
        setAuthDetails(response.data.data);
        loadAtom();
      })
      .catch((error: any) => {
        setloading(false);

        if (error.response.data.message) {
          seterrorMessage(error.response.data.message);
          setTimeout(() => {
            seterrorMessage("");
          }, 5000);
        }
      });
  };

  const PaymentTransaction = () => {
    PaymentTransactionAPICall()
      .then((response: any) => {
        console.log("trans", response.data);
      })
      .catch((error: any) => {});
  };
  const PaymentCallback = () => {
    PaymentCallbackAPICall()
      .then((response: any) => {})
      .catch((error: any) => {});
  };

  const loadAtom = () => {
    const script = document.createElement("script");
    script.src = "https://pgtest.atomtech.in/staticdata/ots/js/atomcheckout.js";
    script.onload = () => {
      const options = {
        atomTokenId: authDetails.atomTokenId,
        merchId: "456669",
        custEmail: "testuser@ndps.in",
        custMobile: "8888888888",
        returnUrl: `https://uat.d14x0laind3li0.amplifyapp.com/payment?token=${redirectToken}`,
      };

      let atom = new window.AtomPaynetz(options, "uat");
    };

    document.body.appendChild(script);
  };
  return (
    <>
      <SnackBar message={errorMessage} />
      <div className={Styles.desktopProgress}>
        <StageLayout
          completedStages={completedStages}
          stageName={"Terms & Conditions"}
          navigatePath={"/linkusingifsc"}
        />
      </div>
      <div className={Styles.mainWrapperTop}>
        <div className={Styles.mobileProgress}>
          <StageLayout
            completedStages={completedStages}
            stageName={"Terms & Conditions"}
            navigatePath={"/linkusingifsc"}
          />
        </div>
        <div className={Styles.wrapper}>
          <div className={Styles.contentWrapper}>
            <p className={Styles.contentTitle}>Select Your Payment Method</p>

            <p className={Styles.subtext}>Payment Gateway</p>
            <div className={Styles.imageSection}>
              <img src={atomImage} className={Styles.image} />
              <img src={paymentRadio} />
            </div>

            <p className={Styles.subtextSummary}>Summary</p>
            <div className={Styles.imageSection}>
              <div>
                <p className={Styles.totalText}>Total</p>
                <p className={Styles.desc}>Account Opening</p>
              </div>
              <p className={Styles.amount}>400</p>
            </div>

            <div
              className={Styles.buttonStack}
              onClick={() => {
                if (!loading) {
                  PaymentAuth();
                }
              }}
            >
              {loading ? (
                <CircularProgress
                  style={{ color: "white", height: "18px", width: "18px" }}
                />
              ) : (
                "Continue"
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
