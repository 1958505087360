import Webcam from "react-webcam";
import React, { useRef, useEffect, useState } from "react";
import { useFaceDetection } from "react-use-face-detection";
import FaceDetection from "@mediapipe/face_detection";
import { Camera } from "@mediapipe/camera_utils";
import { useNavigate } from "react-router-dom";
import {
  Stack,
  Button,
  Card,
  CardMedia,
  Typography,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  browserName,
  isMobile,
  deviceType,
  osName,
  osVersion,
  engineName,
  deviceDetect,
} from "react-device-detect";
import "react-loading-skeleton/dist/skeleton.css";
import { IWebImage } from "../../shared/dtos";
import {
  stageDetailsAPICall,
  startOverAPICall,
  webImageBase64APICall,
} from "../../shared/APICalls";
import routesData from "../../shared/routes.json";
import CamStyles from "./WebCam.module.scss";
import StageLayout from "../../Layouts/StageLayout";
import ErrorSnackBar from "../SnackBars/ErrorSnackBar";
import { confirmAlert } from "react-confirm-alert";
import SnackBar from "../SnackBars/SnackBar";
import { FaceMesh } from "@mediapipe/face_mesh";
import { UAParser } from "ua-parser-js";

const width = 220;
const height = 220;

const EyeBlinkDetection = (): JSX.Element => {
  const { webcamRef, boundingBox, isLoading, detected, facesDetected }: any =
    useFaceDetection({
      faceDetectionOptions: {
        model: "short",
      },
      faceDetection: new FaceDetection.FaceDetection({
        locateFile: (file: any) =>
          `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
      }),
      camera: ({ mediaSrc, onFrame }: any) =>
        new Camera(mediaSrc, {
          onFrame,
          width,
          height,
        }),
    });

  const [previewUrl, setPreviewUrl] = useState("");

  const [setVisible, setsetVisible] = useState<any>("hidden");
  const [base64String, setBase64String] = useState("");

  const [allowCapture, setAllowCapture] = useState(true);
  const [showCamera, setShowCamera] = useState(true);
  const [isSafari, setisSafari] = useState(false);
  const [Border, setBorder] = useState("");
  const [loading, setloading] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [completedStages, setCompletedStages] = useState<any>();
  const [backdrop, setBackDrop] = useState(false);
  const [reload, setReload] = useState(true);
  const [rejectMessage, setRejectMessage] = useState("");
  const [message, setMessage] = useState("");
  const [take, settake] = useState(true);
  const [blinkCount, setBlinkCount] = useState(0);
  const navigationData = routesData;
  const faceMeshRef: any = useRef<any>(null);
  const [capturedImage, setCapturedImage] = useState("");
  const [takePhotoloading, setTakePhotoLoading] = useState(false);
  const [videoSourceError, setVideoSourceError] = useState(false);
  const [deviceName, setDeviceName] = useState<any>({
    type: "",
    model: "",
    vendor: "",
  });
  const [ua, setua] = useState("");

  let lastBlink = false;

  const canvasRef: any = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isMobile) {
      setVideoSourceError(true);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    settake(true);
    setTimeout(() => {
      settake(false);
    }, 1500);

    let obj: any = {
      stage: "ipv",
    };

    stageDetailsAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        setCompletedStages(responseData.data.completed_stages);
        // console.log(responseData);
        // console.log("response", response.data.data.StageDetails.WebImage);
        if (responseData.data.ErrorMessage) {
          setRejectMessage(responseData.data.ErrorMessage);
        }
        const retakeImage = sessionStorage.getItem("re-take");

        if (retakeImage === "retake") {
          setPreviewUrl("");
          setBase64String("");
        } else {
          setPreviewUrl(
            responseData.data.data?.img_base64
              ? `data:image/gif;base64,${responseData.data.data.img_base64}`
              : ""
          );
          setBase64String(
            responseData.data.data?.img_base64
              ? responseData.data.data.img_base64
              : ""
          );
        }

        setReload(false);
      })
      .catch((error: any) => {
        //  console.log("error", error);
      });

    let intervalId: any;
    let alertShown = false;

    const startCameraInterval = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            aspectRatio: 16 / 9,
            height: { ideal: 1920 },
            frameRate: { ideal: 60 },
            facingMode: "user",
          },
        });

        stream?.getTracks().forEach((track) => track.stop());

        setsetVisible("visible");
        setShowCamera(true);
        clearInterval(intervalId);
      } catch (error: any) {
        if (browserName.includes("Safari")) {
          setisSafari(true);
        }
        // setShowCamera(false);

        if (
          !alertShown &&
          (error.name === "NotAllowedError" ||
            error.name === "PermissionDeniedError")
        ) {
          alertShown = true;
          const shouldAskForPermission = window.confirm(
            "Camera access is blocked. Please unblock it in browser settings."
          );
          if (shouldAskForPermission) {
            intervalId = setInterval(async () => {
              try {
                const stream = await navigator.mediaDevices.getUserMedia({
                  video: true,
                });
                stream.getTracks().forEach((track) => track.stop());
                clearInterval(intervalId);
                // console.log("video is true");
                // setShowCamera(true);
                window.location.reload();
              } catch (error: any) {
                // console.error(error);
              }
            }, 1000);
          } else {
            // console.error(error);
          }
        }
      }
    };
    if (browserName !== "Firefox") {
      intervalId = setInterval(startCameraInterval, 1000);
    }
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (capturedImage !== "") {
      faceMeshRef.current = new FaceMesh({
        locateFile: (file) =>
          `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`,
      });

      faceMeshRef.current.setOptions({
        selfieMode: true,
        maxNumFaces: 1,
        refineLandmarks: true,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5,
      });

      faceMeshRef.current.onResults(onResults);

      const interval = setInterval(() => {
        if (
          webcamRef.current &&
          webcamRef.current.video &&
          webcamRef.current.video.readyState === 4 // Check if video is ready
        ) {
          // Start Camera

          const camera = new Camera(webcamRef.current.video, {
            onFrame: async () => {
              await faceMeshRef.current.send({
                image: webcamRef.current.video,
              });
            },
            width: 220,
            height: 220,
          });
          camera.start();
          clearInterval(interval);
        } else {
          const imageSrcNew = webcamRef?.current?.getScreenshot();
          setPreviewUrl(imageSrcNew);
          setTakePhotoLoading(false);
        }
      }, 500); // Check every 500ms

      return () => {
        clearInterval(interval);
        if (faceMeshRef.current) faceMeshRef.current.close();
      };
    }
  }, [capturedImage]);

  function onResults(results) {
    // console.log("results", results);
    if (results.multiFaceLandmarks && results.multiFaceLandmarks[0]) {
      const landmarks = results.multiFaceLandmarks[0];

      // Calculate Eye Aspect Ratio (EAR) for both eyes
      const leftEAR = calculateEAR(
        landmarks[159], // top of the left eye
        landmarks[145], // bottom of the left eye
        landmarks[33], // left corner of the left eye
        landmarks[133] // right corner of the left eye
      );

      const rightEAR = calculateEAR(
        landmarks[386], // top of the right eye
        landmarks[374], // bottom of the right eye
        landmarks[362], // left corner of the right eye
        landmarks[263] // right corner of the right eye
      );

      const isBlinking = leftEAR < 0.25 && rightEAR < 0.25;

      if (isBlinking && !lastBlink) {
        setBlinkCount((prev) => prev + 1);
      }

      lastBlink = isBlinking;

      // Optionally, draw landmarks on a canvas
      const canvasCtx = canvasRef.current.getContext("2d");
      canvasCtx.clearRect(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
      // drawLandmarks(canvasCtx, landmarks);
    }
  }

  const calculateEAR = (top, bottom, left, right) => {
    const verticalDist = Math.sqrt(
      (top.x - bottom.x) ** 2 + (top.y - bottom.y) ** 2
    );
    const horizontalDist = Math.sqrt(
      (left.x - right.x) ** 2 + (left.y - right.y) ** 2
    );
    return verticalDist / horizontalDist;
  };

  useEffect(() => {
    if (facesDetected > 1) {
      setAllowCapture(false);

      setBorder("4px solid white");
    } else if (facesDetected === 1) {
      setAllowCapture(true);

      setBorder("4px solid green");
    } else {
      if (isLoading === false) {
        setAllowCapture(false);

        setBorder("4px solid white");
      }
    }
  }, [facesDetected]);

  useEffect(() => {
    if (blinkCount === 3) {
      setTimeout(() => {
        const imageSrcNew = webcamRef?.current?.getScreenshot();
        setPreviewUrl(imageSrcNew);
      }, 500);
    }
  }, [blinkCount]);

  const capture = React.useCallback(() => {
    if (videoSourceError) {
      const imageSrc = webcamRef?.current?.getScreenshot();
      setPreviewUrl(imageSrc);
    } else {
      confirmAlert({
        title: "",
        message: "Blink your eye 3 times to capture a photo",
        buttons: [
          {
            label: "No",
            onClick: () => {
              setTakePhotoLoading(false);
            },
          },
          {
            label: "Yes",
            onClick: () => {
              setTakePhotoLoading(true);
              const imageSrc = webcamRef?.current?.getScreenshot();
              setCapturedImage(imageSrc);
            },
          },
        ],
      });
    }

    // setPreviewUrl(imageSrc);
  }, [webcamRef, videoSourceError]);

  const handleStartOver = () => {
    confirmAlert({
      title: "",
      message:
        "Are you sure you want to restart, on pressing Yes all the Data will be deleted",
      buttons: [
        {
          label: "No",
          onClick: () => "",
        },
        {
          label: "Yes",
          onClick: () => {
            setBackDrop(true);

            startOverAPICall()
              .then((response: any) => {
                const responseData = response.data;
                //console.log("responseData", response);
                setisloading(false);
                setBackDrop(false);
                navigate("/");
              })
              .catch((error: any) => {
                setAllowCapture(false);
              });
          },
        },
      ],
    });
  };

  function webImageBase64() {
    setloading(true);
    let obj: IWebImage = {
      ipv_base64: base64String !== "" ? base64String : previewUrl,
    };

    webImageBase64APICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        //console.log("responseData", response);
        setloading(false);
        if (
          responseData.data.next_stage &&
          navigationData.hasOwnProperty(responseData.data.next_stage)
        ) {
          // navigate(navigationData[responseData.data.stage]);
          window.location.href = navigationData[responseData.data.next_stage];
        }
      })
      .catch((error: any) => {
        setloading(false);
        if (error.response.data.message) {
          setMessage(error.response.data.message);
          setTimeout(() => {
            setMessage("");
          }, 5000);
        }
      });
  }

  const handleClick = () => {
    sessionStorage.setItem("re-take", "");
    webImageBase64();
  };

  const refreshCamera = () => {
    if (webcamRef.current && webcamRef.current.stream) {
      const stream = webcamRef.current.stream;

      // Stop the current stream
      stream.getTracks().forEach((track: any) => track.stop());

      // Start a new stream
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((newStream) => {
          webcamRef.current.stream = newStream;
        })
        .catch((error) => {
          //  console.error("Error refreshing camera:", error);
        });
    }
  };

  return (
    <>
      <SnackBar message={message} />
      <div className={CamStyles.desktopProgress}>
        {" "}
        <StageLayout
          completedStages={completedStages}
          stageName={"Identification Verification"}
          navigatePath={"/uploaddocuments"}
        />
      </div>
      <div className={CamStyles.main}>
        <div className={CamStyles.mobileProgress}>
          <StageLayout
            completedStages={completedStages}
            stageName={"Identification Verification"}
            navigatePath={"/uploaddocuments"}
          />
        </div>
        <canvas
          ref={canvasRef}
          style={{ position: "absolute", top: 0, left: 0, display: "none" }}
          width={640}
          height={480}
        ></canvas>

        <div className={CamStyles.mainWrapper}>
          <ErrorSnackBar message={rejectMessage} />
          <div className={CamStyles.wrapper}>
            <div className={CamStyles.Container}>
              <p className={CamStyles.HeaderMobile}>Capture Your Selfie</p>
              <div
                style={{
                  width,
                  height,
                  position: "relative",
                  marginBottom: "30px",
                }}
              >
                <div>
                  {previewUrl === "" ? (
                    <>
                      <Webcam
                        audio={false}
                        ref={webcamRef}
                        forceScreenshotSourceSize
                        screenshotFormat="image/png"
                        style={{
                          height,
                          width,
                          borderRadius: "5px",
                          position: "absolute",
                          border: Border,
                          marginTop: "10px",
                        }}
                      />
                    </>
                  ) : (
                    <div>
                      <Card
                        sx={{
                          width: 222,
                          marginTop: "10px",
                          borderRadius: "5px",
                          zIndex: 100,
                          background: "white",
                          marginLeft: "1px",
                        }}
                      >
                        <CardMedia
                          component="img"
                          height="222"
                          image={previewUrl}
                          sx={{
                            zIndex: 100,
                            position: "absolute",

                            borderRadius: "5px",
                            objectFit: "contain",
                          }}
                        />
                      </Card>
                    </div>
                  )}
                </div>
              </div>
              <div className={CamStyles.noFaceTextDiv}>
                {!allowCapture && previewUrl === "" ? (
                  <p className={CamStyles.noFaceText}>
                    No Face/ Detected too many faces
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div>
                {previewUrl !== "" ? (
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div>
                      <Button
                        className={CamStyles.takePhoto}
                        variant="contained"
                        onClick={() => {
                          setPreviewUrl("");
                          setBase64String("");
                          refreshCamera();
                          sessionStorage.setItem("re-take", "retake");
                          // if (reload === true) {
                          window.location.reload();
                          // }
                        }}
                      >
                        Retake Photo
                      </Button>
                    </div>
                    <div>
                      <LoadingButton
                        variant="contained"
                        onClick={handleClick}
                        loading={loading}
                        disabled={previewUrl === ""}
                        sx={{ display: { xs: "block", sm: "none" } }}
                        className={CamStyles.continueButton}
                        loadingIndicator={
                          <CircularProgress
                            sx={{ color: "#4754A0" }}
                            size={20}
                          />
                        }
                      >
                        {" "}
                        Continue
                      </LoadingButton>
                    </div>
                  </div>
                ) : (
                  <div>
                    {takePhotoloading && (
                      <div style={{ textAlign: "center", fontSize: "16px" }}>
                        Blink count -{" "}
                        <span style={{ color: "green", fontWeight: 700 }}>
                          3/
                          {blinkCount}
                        </span>
                      </div>
                    )}
                    <div>
                      {!take ? (
                        <Button
                          className={CamStyles.takePhoto}
                          variant="contained"
                          onClick={capture}
                          disabled={!allowCapture}
                        >
                          {takePhotoloading ? (
                            <CircularProgress
                              style={{
                                width: "20px",
                                height: "20px",
                                color: "white",
                              }}
                            />
                          ) : (
                            "Take a Photo"
                          )}
                        </Button>
                      ) : (
                        <Button
                          className={CamStyles.takePhoto}
                          variant="contained"
                          onClick={capture}
                          disabled
                        >
                          Take a Photo
                        </Button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={CamStyles.rightContainer}>
              <Stack style={{ marginTop: "20px" }}>
                <>
                  {/* {showCamera ? ( */}
                  <div>
                    <div className={CamStyles.textdiv}>
                      <p className={CamStyles.Header}>Capture Your Selfie</p>

                      <p className={CamStyles.instruction}>Instructions</p>
                      <p className={CamStyles.instructiontext}>
                        1.Ensure your face is well-lit and centered in the
                        frame.
                      </p>
                      <p className={CamStyles.instructiontext}>
                        2.Remove any accessories that may obstruct your face
                        (e.g., sunglasses, hats).
                      </p>
                      <p className={CamStyles.instructiontext}>
                        3.Click "Capture Selfie" when you're ready.
                      </p>
                      <p className={CamStyles.instructiontext}>
                        <span style={{ fontWeight: 600 }}>
                          Please Note* : We may get in touch with you if any
                          additional verification is required.
                        </span>
                      </p>
                    </div>
                    <div className={CamStyles.buttonSection}>
                      <div>
                        <Button
                          variant="text"
                          className={CamStyles.backButton}
                          sx={{
                            background: "#EEEFEF",
                            color: "#262626",
                            "&:hover": {
                              background: "#EEEFEF",
                            },
                          }}
                          onClick={() => {
                            // navigate("/uploaddocuments");

                            window.location.href = "/uploaddocuments";
                          }}
                        >
                          Back
                        </Button>
                      </div>
                      <div>
                        <LoadingButton
                          variant="contained"
                          onClick={handleClick}
                          loading={loading}
                          disabled={previewUrl === ""}
                          className={CamStyles.continueButton}
                          loadingIndicator={
                            <CircularProgress
                              sx={{ color: "#4754A0" }}
                              size={20}
                            />
                          }
                          sx={{ display: { xs: "none", sm: "block" } }}
                        >
                          {" "}
                          Continue
                        </LoadingButton>
                      </div>
                    </div>
                  </div>
                  {/* ) : (
                    <Stack alignItems={"center"} justifyContent={"center"}>
                      <Typography>
                        Grant the Camera access to proceed further{" "}
                      </Typography>
                    </Stack>
                  )} */}
                </>
                {/* )} */}
              </Stack>

              {isSafari ? (
                <Stack alignItems={"center"} justifyContent={"center"}>
                  <Typography sx={{ textAlign: "center" }}>
                    Please Press Reload Button after granting camera access
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      window.location.reload();
                    }}
                    style={{ color: "#006779" }}
                  >
                    Reload
                  </Button>
                </Stack>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <p className={CamStyles.startover}>
        {" "}
        If you want to start over, please
        <span className={CamStyles.startoverbutton} onClick={handleStartOver}>
          Click Here
        </span>
      </p>
    </>
  );
};

export default EyeBlinkDetection;
