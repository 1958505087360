import React, { useEffect } from "react";
import { UIURL } from "../shared/constants";

function Rekyc() {
  useEffect(() => {
    window.location.href = `${UIURL}rekyc/`;
  }, []);
  return <div></div>;
}

export default Rekyc;
