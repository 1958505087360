import { createTheme } from "@mui/material/styles";
import { hover } from "@testing-library/user-event/dist/hover";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#4754A0",
      dark: "#4754A0",
    },
    secondary: {
      main: "#027A48",
    },
    error: {
      main: "#ef5350",
    },
  },
  shape: {
    borderRadius: 10,
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: "white",
          fontWeight: 400,
          height: "45px",
        },
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },

        text: {
          color: "#000",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {},
      },
    },
  },

  // breakpoints: {
  //   values: {
  //     xs: 0,       // Extra small screens (0px and up)
  //     sm: 640,     // Small screens (640px and up)
  //     md: 1024,    // Medium screens (1024px and up)
  //     lg: 1280,    // Large screens (1280px and up)
  //     xl: 1920,
  //   },
  // },
});
