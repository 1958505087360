import React, { useEffect, useState, useRef } from "react";
import { Stack, Button, Box, Divider, CircularProgress } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";

import { LoadingButton } from "@mui/lab";

import {
  CompleteAadhaarAPICall,
  KRAUpdateAPICall,
  digiLockerInitAPICall,
  digiLockerStatusAPICall,
  stageDetailsAPICall,
  startOverAPICall,
} from "../shared/APICalls";
import JsonDataToAadhaarFormat from "../Components/JsonDataToAadhaarFormat/JsonDataToAadhaarFormat";
import routesData from "../shared/routes.json";
import AadhaarStyles from "../pages/Styles/AadharKYC.module.scss";
import AadhaarImage from "../Components/assets/Images/aadhaarpagenewimage.svg";
import StageLayout from "../Layouts/StageLayout";
import icon from "../Components/assets/Images/iconnew.svg";
import SnackBar from "../Components/SnackBars/SnackBar";
import KRAScreen from "./KRAscreen";
import BackDrop from "../Components/BackDrop";
import StartOverBackDrop from "../Components/StartOverBackDrop";
import ErrorSnackBar from "../Components/SnackBars/ErrorSnackBar";
import { confirmAlert } from "react-confirm-alert";

export default function AadhaarKYCPage() {
  const navigate = useNavigate();
  const [kraFetched, SetKraFetched] = useState<any>(false);

  const [aadhaarData, setaadhaarData] = useState<any>();
  const [completedStages, setCompletedStages] = useState<any>();
  const [childWindowRef, setchildWindowRef] = useState<any>(null);
  const [closetTimer, setclosetTimer] = useState(false);
  const [loading, setloading] = useState(false);
  const [startOverloading, setstartOverloading] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [displayAadhaarData, setDisplayAadhaarData] = useState(false);
  const [backdropLoading, setbackdropLoading] = useState(false);

  const [KraData, setKraData] = useState<any>();
  const navigationData = routesData;
  const [navigatePath, setNavigate] = useState<any>();
  const [message, setMessage] = useState("");

  const [backdrop, setBackDrop] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");

  const timerRef = useRef<any>(null);

  const handleOpenClick = () => {
    confirmAlert({
      title: "",
      message:
        "By Submitting the above I hereby allow Composite Investments Pvt Ltd to Capture my Aadhaar details. I hereby authorized Composite Investments Pvt Ltd to fetch my details and open my account through KRA/CKYC/Digi locker.",
      buttons: [
        {
          label: "No",
          onClick: () => "",
        },
        {
          label: "Yes",
          onClick: () => {
            setloading(true);
            let digilink: string = "";
            let obj = {
              email: "k.chethan222@gmail.com",
              name: "chethan",
            };
            digiLockerInitAPICall()
              .then((response: any) => {
                const responseData = response.data;
                // setloading(false);
                // console.log("responseData", responseData);
                digilink = responseData.data.link;
                //  console.log("digilink", digilink);
                setTimeout(() => {
                  const childWindow: any = window.open(
                    digilink,
                    "_blank",
                    "width=500,height=700"
                  );
                  setchildWindowRef(childWindow);
                });

                // setloading(false);
                // if (
                //   responseData.data.stage &&
                //   navigationData.hasOwnProperty(responseData.data.stage)
                // ) {
                //   navigate(navigationData[responseData.data.stage]);
                // }
              })
              .catch((error: any) => {
                setloading(false);
              });
          },
        },
      ],
    });
  };
  const handleClose = () => {
    setloading(false);
    setTimeout(() => {
      // navigate("/tradingPreference");
      navigate(navigatePath);
    }, 500);
  };

  const handleContinuetoNextPage = () => {
    let obj = {
      is_continued: true,
    };
    KRAUpdateAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        //  console.log("responseData", responseData.data.stage);
        if (
          responseData.data.stage &&
          navigationData.hasOwnProperty(responseData.data.stage)
        ) {
          navigate(navigationData[responseData.data.stage]);
        }
      })
      .catch((error: any) => {
        //  console.log("error", error.response.data.message);
      });
    setloading(false);
  };

  const handleContinue = () => {
    digiLockerStatusAPICall()
      .then((response: any) => {
        const responseData = response.data;

        if (
          responseData.data.stage &&
          navigationData.hasOwnProperty(responseData.data.stage)
        ) {
          navigate(navigationData[responseData.data.stage]);
        }
        // console.log("responseData", responseData.data.stage);
      })
      .catch((error: any) => {
        // console.log("error", error.response.data.message);
      });
    setloading(false);
  };

  useEffect(() => {
    if (!closetTimer && childWindowRef) {
      //  console.log("timer trigger");
      timerRef.current = setInterval(checkChild, 500);
    }

    return () => {
      clearInterval(timerRef.current);
    };
  }, [childWindowRef, closetTimer]);

  function checkChild() {
    // console.log("timer");
    if (childWindowRef && childWindowRef.closed) {
      clearInterval(timerRef.current);
      // console.log("Child window closed");

      digiLockerStatusAPICall()
        .then((response: any) => {
          const responseData = response.data;

          // console.log("responseDatachild", responseData);

          StageDetails();

          // setDisplayAadhaarData(true);
          // setloading(false);

          setclosetTimer(true);
        })
        .catch((error: any) => {
          // console.log("error", error.response.data.message);
          setloading(false);
          setMessage(error.response.data.message);
          setshowModal(false);

          setTimeout(() => {
            setMessage("");
          }, 8000);
        });
    }
  }

  const handleStartOver = () => {
    confirmAlert({
      title: "",
      message:
        "Are you sure you want to restart, on pressing Yes all the Data will be deleted",
      buttons: [
        {
          label: "No",
          onClick: () => "",
        },
        {
          label: "Yes",
          onClick: () => {
            setBackDrop(true);
            startOverAPICall()
              .then((response: any) => {
                const responseData = response.data;
                //console.log("responseData", response);
                setstartOverloading(false);
                setBackDrop(false);
                navigate("/");
              })
              .catch((error: any) => {
                setBackDrop(false);
                setstartOverloading(false);
              });
          },
        },
      ],
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    StageDetails();
  }, []);

  const StageDetails = () => {
    let obj: any = {
      stage: "kra_or_aadhaar",
    };
    setbackdropLoading(true);
    stageDetailsAPICall(obj)
      .then((response: any) => {
        setbackdropLoading(false);
        const responseData = response.data.data;
        // console.log("responseData", responseData.data.kra);
        setCompletedStages(responseData.completed_stages);
        
        if (responseData.ErrorMessage) {
          setRejectMessage(responseData.ErrorMessage);
        }

        if (responseData.data.is_kra_fetched) {
          setKraData(responseData.data.kra);
          SetKraFetched(true);
        }

        if (responseData.data.is_aadhaar_fetched) {
          setDisplayAadhaarData(true);
          SetKraFetched(false);
          if (responseData.data.aadhaar) {
            setaadhaarData(responseData.data.aadhaar);
          }
        }
      })
      .catch((error: any) => {
        setbackdropLoading(false);
        // console.log("error", error);
      });
  };

  const handleUpdateAadhaarClick = () => {
    SetKraFetched(false);
    // setKraData({});
  };

  const CompleteAadhaarStage = () => {
    CompleteAadhaarAPICall()
      .then((response: any) => {
        // console.log("response", response.data.data.next_stage);
        if (
          response.data.data.next_stage &&
          navigationData.hasOwnProperty(response.data.data.next_stage)
        ) {
          navigate(navigationData[response.data.data.next_stage]);
        }
      })
      .catch((error: any) => {});
  };

  // console.log("displayAadhaarData", displayAadhaarData);

  return (
    <>
      {backdropLoading && (
        <div style={{ height: "100vh", position: "absolute" }}>
          <BackDrop />
        </div>
      )}
      <SnackBar message={message} />
      <ErrorSnackBar message={rejectMessage} />
      <div className={AadhaarStyles.desktopProgress}>
        <StageLayout
          completedStages={completedStages}
          stageName={"Fetched Details"}
          navigatePath={"/aadhaarkyc"}
        />{" "}
      </div>
      <div className={AadhaarStyles.mainWrapper}>
        <div className={AadhaarStyles.mobileProgress}>
          <StageLayout
            completedStages={completedStages}
            stageName={"Fetched Details"}
            navigatePath={"/aadhaarkyc"}
          />
        </div>
        {!kraFetched ? (
          <div className={AadhaarStyles.wrapper}>
            {!displayAadhaarData ? (
              <div className={AadhaarStyles.container}>
                <div className={AadhaarStyles.rightcontainer}>
                  <p className={AadhaarStyles.titleMobile}>
                    {" "}
                    Link Your Aadhaar{" "}
                  </p>
                  <img src={AadhaarImage} className={AadhaarStyles.image} />
                </div>
                <div className={AadhaarStyles.leftcontainer}>
                  <p className={AadhaarStyles.title}> Link Your Aadhaar </p>
                  <p className={AadhaarStyles.leftcontainerText}>
                    1.Click on ‘Fetch From Digilocker’ button below.
                  </p>
                  <p className={AadhaarStyles.leftcontainerText}>
                    2.Enter your Aadhaar Number.
                  </p>
                  <p className={AadhaarStyles.leftcontainerText}>
                    3.Enter the OTP received on your linked mobile number.
                  </p>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    className={AadhaarStyles.buttonKYC}
                    sx={{
                      width: "max-content",
                      margin: { xs: "20px auto", sm: "20px 0px" },
                    }}
                    onClick={handleOpenClick}
                    loading={loading}
                    id="aadharOpenLinkButton"
                    loadingIndicator={
                      <CircularProgress sx={{ color: "#4754A0" }} size={20} />
                    }
                  >
                    Fetch from Digilocker
                  </LoadingButton>
                </div>
              </div>
            ) : (
              <Stack>
                <Box>
                  <JsonDataToAadhaarFormat aadhaarData={aadhaarData} />

                  <div className={AadhaarStyles.redoStyles}>
                    {/* <Button
                      id="aadhaarApproveButton"
                      variant="outlined"
                      onClick={() => {
                        setDisplayAadhaarData(false);
                      }}
                      sx={{
                        color: "green",
                        width: "100px",

                        marginRight: { xs: "20px", sm: "10px" },
                      }}
                      className={AadhaarStyles.redoButton}
                    >
                      Redo
                    </Button> */}
                    <Button
                      id="aadhaarApproveButton"
                      variant="contained"
                      onClick={CompleteAadhaarStage}
                      className={AadhaarStyles.approveButton}
                    >
                      Continue
                    </Button>
                  </div>
                </Box>
              </Stack>
            )}
            {/* {displayFetchedData && (
              <div className={AadhaarStyles.container}>
                {displayAadhaarData ? (
                  <>
                    <div className={AadhaarStyles.rightcontainer}>
                      <p className={AadhaarStyles.titleMobile}>
                        {" "}
                        Link Your Aadhaar{" "}
                      </p>
                      <img src={AadhaarImage} className={AadhaarStyles.image} />
                    </div>
                  </>
                ) : (
                  <Stack>
                    {aadhaarData && aadhaarData.AadhaarName ? (
                      <Box>
                        <JsonDataToAadhaarFormat
                          aadhaarData={aadhaarData}
                          aadaarFetched={aadhaarData.isAadhaarFetched}
                        />
                        {displayAadhaarData === true ? (
                          <LoadingButton
                            type="submit"
                            variant="contained"
                            className={AadhaarStyles.buttonKYC}
                            onClick={handleOpenClick}
                            loading={loading}
                            id="aadharOpenLinkButton"
                          >
                            Fetch from Digilocker
                          </LoadingButton>
                        ) : (
                          <div className={AadhaarStyles.redoStyles}>
                            <Button
                              id="aadhaarApproveButton"
                              variant="outlined"
                              onClick={() => {
                                setDisplayAadhaarData(true);
                              }}
                              sx={{
                                color: "green",
                                width: "100px",

                                marginRight: { xs: "20px", sm: "10px" },
                              }}
                              className={AadhaarStyles.redoButton}
                            >
                              Redo
                            </Button>
                            <Button
                              id="aadhaarApproveButton"
                              variant="contained"
                              onClick={handleContinue}
                              className={AadhaarStyles.approveButton}
                            >
                              Continue
                            </Button>
                          </div>
                        )}
                      </Box>
                    ) : (
                      <div style={{ height: "100vh" }}>
                        <BackDrop />
                      </div>
                    )}
                  </Stack>
                )}
              </div>
            )}
            {!displayFetchedData && (
              <div>
                <Box>
                  <JsonDataToAadhaarFormat
                    aadhaarData={aadhaarDataFetchedKYC}
                    aadaarFetched={aadhaarDataFetchedKYC.isAadhaarFetched}
                  />

                  <div className={AadhaarStyles.buttonStack}>
                    <div className={AadhaarStyles.redoStyles}>
                      <LoadingButton
                        id="aadhaarApproveButton"
                        variant="contained"
                        onClick={handleClose}
                        className={AadhaarStyles.approveButton}
                        sx={{ marginTop: "10px" }}
                        // loading={continueLoading}
                      >
                        Continue
                      </LoadingButton>
                    </div>
                  </div>
                </Box>
              </div>
            )} */}

            {/* <Stack alignItems={"center"} paddingBottom={"20px"}>
              <div className={AadhaarStyles.displayBack}>
                {!displayAadhaar && (
                  <Button
                    variant="outlined"
                    sx={{ color: "rgba(18, 183, 106, 1)", marginTop: "15px" }}
                    onClick={handleFetcedClick}
                  >
                    Continue with Fetched
                  </Button>
                )}

                {displayFetchedData ? (
                  <div>
                    <SnackBar message={message} />
                    <div className={AadhaarStyles.buttonStyles}>
                      <div>
                        {displayFetchedData && (
                          <div className={AadhaarStyles.container}>
                            {displayAadhaarData && (
                              <>
                                <div className={AadhaarStyles.leftcontainer}>
                                  <p className={AadhaarStyles.title}>
                                    {" "}
                                    Link Your Aadhaar{" "}
                                  </p>
                                  <p
                                    className={AadhaarStyles.leftcontainerText}
                                  >
                                    1.Click on ‘Fetch From Digilocker’ button
                                    below.
                                  </p>
                                  <p
                                    className={AadhaarStyles.leftcontainerText}
                                  >
                                    2.Enter your Aadhaar Number.
                                  </p>
                                  <p
                                    className={AadhaarStyles.leftcontainerText}
                                  >
                                    3.Enter the OTP received on your linked
                                    mobile number.
                                  </p>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                        {displayAadhaarData === true && (
                          <LoadingButton
                            type="submit"
                            variant="contained"
                            className={AadhaarStyles.buttonKYC}
                            onClick={handleOpenClick}
                            loading={loading}
                            id="aadharOpenLinkButton"
                          >
                            Fetch from Digilocker
                          </LoadingButton>
                        )}
                      </div>

                      <div>{backdrop && <StartOverBackDrop />}</div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Stack> */}
          </div>
        ) : (
          <div className={AadhaarStyles.fetchWrapper}>
            <KRAScreen aadhaarData={KraData} />
            {backdrop && <StartOverBackDrop />}
            <div className={AadhaarStyles.fetchButtons}>
              <Button
                variant="text"
                className={AadhaarStyles.fetchUpdate}
                onClick={handleUpdateAadhaarClick}
                sx={{
                  background: "#EEEFEF",
                  "&:hover": {
                    background: "#EEEFEF",
                  },
                }}
              >
                Update as on Aadhaar
              </Button>
              <Button
                variant="contained"
                className={AadhaarStyles.fetchContinue}
                onClick={CompleteAadhaarStage}
              >
                Continue
              </Button>
            </div>
            {/* <div className={AadhaarStyles.startOverSection}>
              <img src={icon} className={AadhaarStyles.iconImage} />
              <p className={AadhaarStyles.paratext}>
                {" "}
                if the above mentioned info not yours
                <span
                  className={AadhaarStyles.startoverbutton}
                  onClick={handleStartOver}
                >
                  Start Over
                </span>
              </p>
            </div> */}
          </div>
        )}
      </div>
      <div>{backdrop && <StartOverBackDrop />}</div>
      <p className={AadhaarStyles.startover}>
        {" "}
        If you want to start over, please
        <span
          className={AadhaarStyles.startoverbutton}
          onClick={handleStartOver}
        >
          Click Here
        </span>
      </p>
    </>
  );
}
