import React, { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  Typography,
  Button,
  Avatar,
  CircularProgress,
} from "@mui/material";
import DoneImage from "../Images/doneicon.png";
import ErrorImage from "../Images/errorImage.png";
import { useLocation } from "react-router-dom";
import styles from "../pages/Styles/PanCr.module.scss";

import { useDispatch, useSelector } from "react-redux";
import { IGetDocumentDigilocker } from "../shared/dtos";
import { PanDigilockerDocumentGetAPICall } from "../shared/APICalls";
import ErrorSnackBar from "../Components/SnackBars/ErrorSnackBar";

export default function PANCR() {
  const [countDown, setCountDown] = useState(3);
  const [errorMesage, seterrorMessage] = useState("");
  const [loading, setloading] = useState(false);
  const [displayError, setDisplayError] = useState(false);

  const pathName = useLocation().search;
  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlParams.entries());
  const doc_id = params.id;
  const state = params.state;
  //   const state = params.state;
  sessionStorage.setItem("access-token", state);

  const GetpanDoc = () => {
    let obj: IGetDocumentDigilocker = {
      id: doc_id,
      doc_type: "PANCR",
    };
    setloading(true);
    PanDigilockerDocumentGetAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        setloading(false);
        //   console.log("responseDataResponse", responseData);
        setTimeout(() => {
          window.close();
        }, 3000);
      })
      .catch((error: any) => {
        setloading(false);
        setDisplayError(true);
        seterrorMessage(
          error.response?.data?.error?.message
            ? error.response.data.error.message
            : "Error fetching document"
        );
        setTimeout(() => {
          window.close();
        }, 3000);
        setTimeout(() => {
          seterrorMessage("");
        }, 5000);
      });
  };

  useEffect(() => {
    if (window.location.href.includes("Cancelled")) {
      console.log("cancel");
      setDisplayError(true);
    } else {
      setDisplayError(false);
      GetpanDoc();
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountDown((prevCountDown) => prevCountDown - 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (countDown < 0) {
      setCountDown(3);
    }
  }, [countDown]);

  return (
    <div className={styles.mainWrapperTop}>
      <ErrorSnackBar message={errorMesage} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {" "}
        {loading ? (
          <CircularProgress style={{ width: "100px", height: "100px" }} />
        ) : (
          <img
            src={!displayError ? DoneImage : ErrorImage}
            style={{ width: "100px", height: "100px" }}
          />
        )}
        <div style={{ textAlign: "center", padding: "10px 10px" }}>
          <Typography variant="h5">
            {" "}
            {loading
              ? "Inprogress..."
              : !displayError
              ? "Success"
              : "Error"}{" "}
          </Typography>
          {!loading && !displayError && (
            <Typography style={{ marginTop: "10px" }}>
              We have obtained your documents
            </Typography>
          )}
        </div>
        <div style={{ textAlign: "center" }}>
          {!loading && (
            <Typography style={{ fontSize: "12px", padding: "0 5px" }}>
              The page will close automatically in {countDown} seconds, if not
              close manually to continue.
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
}
