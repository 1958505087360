import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import SuccessEsignPage from "./SuccessEsignPage";
import ErrorEsignPage from "./ErrorEsignPage";
import PageNotFoundEsign from "./PageNotFoundEsign";


const MapRoutes: any = {
  success: <SuccessEsignPage />,
  error: <ErrorEsignPage />,
  not_found: <PageNotFoundEsign />,
};

export default function HomePageEsign() {
  let page = "not_found";

  const currentLink = window.location.href;

  const getCurrentPage = () => {
    if (currentLink.includes("&success=true")) {
      return "success";
    } else if (currentLink.includes("?error=")) {
      return "error";
    } else {
      return "not_found";
    }
  };

  page = getCurrentPage();

  return <div>{MapRoutes[page]}</div>;
}
