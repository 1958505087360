import React, { useEffect, useState } from "react";
import {
  Stack,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  Modal,
  Fade,
  Box,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Navigate, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  startOverAPICall,
  segmentDetailsAPICall,
  segmentDetailsPostAPICall,
  stageDetailsAPICall,
  CommonDropDownAPICall,
} from "../shared/APICalls";
import routesData from "../shared/routes.json";
import TradingStyles from "../pages/Styles/TradingPreference.module.scss";

import StageLayout from "../Layouts/StageLayout";
import icon from "../Components/assets/Images/iconnew.svg";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import TickCircle from "../Components/assets/Images/tick-circle.svg";
import EmptyCircle from "../Components/assets/Images/TickEmptyCircle.svg";
import BackDrop from "../Components/BackDrop";
import StartOverBackDrop from "../Components/StartOverBackDrop";
import ErrorSnackBar from "../Components/SnackBars/ErrorSnackBar";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { confirmAlert } from "react-confirm-alert";
import SnackBar from "../Components/SnackBars/SnackBar";

const TradingPreferenceSchema = Yup.object().shape({
  Equity: Yup.boolean().oneOf([true], "Equity is required"),
});

export default function TradingPreference() {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [segmentData, setSegmentData] = useState<any>([]);
  const [completedStages, setCompletedStages] = useState<any>();
  const [prefilloading, setprefilloading] = useState(true);
  const [backdrop, setBackDrop] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");
  const [message, setMessage] = useState("");
  const [showModal, setshowModal] = useState(false);
  const [tradingPreferenceData, setTradingPreferneceData] = useState<any>();

  const [prefillData, setprefillData] = useState<any>({
    equity: false,
    futures_and_options: false,
    currency: false,
    commodity: false,
    mutual_funds: false,
  });

  const navigationData = routesData;

  useEffect(() => {
    window.scrollTo(0, 0);
    let dd_type = "segments";
    CommonDropDownAPICall(dd_type)
      .then((response: any) => {
        const responseData = response.data.data.segments;
        // console.log("responseData", responseData);
        setSegmentData(
          responseData.filter((a: any) => a.segment_link !== "mutual_funds")
        );
      })
      .catch((error: any) => {});

    let obj: any = {
      stage: "segment",
    };

    stageDetailsAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseDatasegment", responseData.data.data);
        setCompletedStages(responseData.data.completed_stages);
        setprefilloading(false);

        // console.log("response", response.data.data.StageDetails);
        //  console.log("response", response.data.data.StageDetails.map((data: any) =>  data.IsOpted));
        if (responseData.data.data && responseData.data.data.length) {
          setprefillData((prev) => {
            return {
              ...prev,
              equity: responseData.data.data.filter(
                (a: any) => a.label === "equity"
              )[0].opted,
              futures_and_options: responseData.data.data.filter(
                (a: any) => a.label === "futures_and_options"
              )[0].opted,
              currency: responseData.data.data.filter(
                (a: any) => a.label === "currency"
              )[0].opted,
              commodity: responseData.data.data.filter(
                (a: any) => a.label === "commodity"
              )[0].opted,
              mutual_funds: responseData.data.data.filter(
                (a: any) => a.label === "mutual_funds"
              )[0].opted,
            };
          });
        }
        if (response.data.data.ErrorMessage) {
          setRejectMessage(response.data.data.ErrorMessage);
        }
      })
      .catch((error: any) => {
        setprefilloading(false);
        // console.log("error", error);
      });
  }, []);

  function segmentDetailsPost(data: any) {
    setloading(true);

    // console.log("data", data);

    let obj = {
      segment_list: {
        equity: {
          label: data[0].segment_link,
          opted: data[0].isOpted,
        },
        futures_and_options: {
          label: data[1].segment_link,
          opted: data[1].isOpted,
        },

        currency: {
          label: data[2].segment_link,
          opted: data[2].isOpted,
        },
        commodity: {
          label: data[3].segment_link,
          opted: data[3].isOpted,
        },
        mutual_funds: {
          label: "mutual_funds",
          opted: false,
        },
      },
    };

    setshowModal(true);
    setTradingPreferneceData(obj);
  }

  const handleRiskDisclosure = () => {
    console.log("tradingPreferenceData", tradingPreferenceData);
    segmentDetailsPostAPICall(tradingPreferenceData)
      .then((response: any) => {
        const responseData = response.data;

        setloading(false);
        if (
          responseData.data.next_stage &&
          navigationData.hasOwnProperty(responseData.data.next_stage)
        ) {
          navigate(navigationData[responseData.data.next_stage]);
        }
        setshowModal(false);
      })
      .catch((error: any) => {
        setloading(false);
        // console.log("error", error.response.data.message)
        if (error.response.data.message) {
          setMessage(error.response.data.message);
          setTimeout(() => {
            setMessage("");
          }, 5000);
        }
      });
  };

  const handleClick = () => {
    navigate("/yourprofile");
  };
  //console.log(prefillData.mutual_funds);

  const handleStartOver = () => {
    confirmAlert({
      title: "",
      message:
        "Are you sure you want to restart, on pressing Yes all the Data will be deleted",
      buttons: [
        {
          label: "No",
          onClick: () => "",
        },
        {
          label: "Yes",
          onClick: () => {
            setBackDrop(true);
            startOverAPICall()
              .then((response: any) => {
                const responseData = response.data;
                // console.log("responseData", response);
                setBackDrop(false);
                navigate("/");
                window.location.reload();
              })
              .catch((error: any) => {
                setBackDrop(false);
              });
          },
        },
      ],
    });
  };

  console.log("prefillData", prefillData);

  const InitialValues = {
    equity: prefillData.equity,
    futures_and_options: prefillData.futures_and_options,
    currency: prefillData.currency,
    commodity: prefillData.commodity,
    // mutual_funds: prefillData.mutual_funds,
  };

  const handleCheckBoxClick = (name: any, callback: any, values: any) => {
    callback(name, !values[name]);
  };

  return (
    <>
      <SnackBar message={message} />
      <div className={TradingStyles.desktopProgress}>
        <StageLayout
          completedStages={completedStages}
          stageName={"Trading Preferences"}
          navigatePath={"/yourprofile"}
        />
      </div>
      <div className={TradingStyles.mainWrapper}>
        <div className={TradingStyles.mobileProgress}>
          <StageLayout
            completedStages={completedStages}
            stageName={"Trading Preferences"}
            navigatePath={"/yourprofile"}
          />
        </div>
        <ErrorSnackBar message={rejectMessage} />
        <div className={TradingStyles.wrapper}>
          {!prefilloading ? (
            <Formik
              initialValues={InitialValues}
              validationSchema={TradingPreferenceSchema}
              onSubmit={(values) => {
                const selectedSegments = segmentData
                  .filter((segment: any) => values[segment.segment_link])
                  .map((segment: any) => ({
                    segmentName: segment.value,

                    segmentLink: segment.segment_link,
                  }));

                const updatedSegmentData = segmentData.map((a: any) => {
                  const isOpted = selectedSegments.some(
                    (b: any) => b.segmentLink === a.segment_link
                  );
                  return { ...a, isOpted };
                });

                // console.log("updatedSegmentData", updatedSegmentData);
                segmentDetailsPost(updatedSegmentData);
              }}
            >
              {({ errors, touched, handleSubmit, setFieldValue, values }) => {
                console.log("values", values);
                return (
                  <Form
                    onKeyDown={(event) => {
                      event.code === "Enter" ||
                        (event.code === "NumpadEnter" && handleSubmit());
                    }}
                    style={{ width: "100%" }}
                  >
                    <Stack
                      alignItems={"center"}
                      height={"max-content"}
                      width={"100%"}
                    >
                      <div className={TradingStyles.headerSection}>
                        <p className={TradingStyles.signup}>
                          Choose Your Trading Preference
                        </p>
                      </div>
                      <Stack marginTop={"30px"} height={"max-content"}>
                        <FormGroup
                          sx={{
                            height: "max-content",

                            "&.MuiFormGroup-root": {
                              display: "grid",
                              gap: { xs: "10px 10px ", sm: "30px 30px" },
                              gridTemplateColumns: {
                                xs: "repeat(1, 1fr)",
                                sm: "repeat(2, 1fr)",
                              },
                            },
                          }}
                        >
                          {/* <div
                            className={TradingStyles.checkBoxFeild}
                            style={{
                              background: "#F9FAFB",
                            }}
                          >
                            <FormControlLabel
                              sx={{ marginRight: "10px", width: "100%" }}
                              control={
                                <Radio
                                  icon={<img src={TickCircle} width={"24px"} />}
                                  checkedIcon={
                                    <img src={TickCircle} width={"24px"} />
                                  }
                                  name={"equity"}
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    position: "absolute",
                                    right: "10px",
                                  }}
                                  checked
                                />
                              }
                              label={
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <p className={TradingStyles.tradingLabel}>
                                    Equity
                                  </p>
                                  <span className={TradingStyles.defaultLabel}>
                                    Default
                                  </span>
                                </div>
                              }
                            />
                          </div> */}

                          {segmentData.map((segment: any, index: any) => (
                            <div
                              className={TradingStyles.checkBoxFeild}
                              style={{
                                background: "#F9FAFB",
                                border:
                                  values[segment.segment_link] === true
                                    ? ""
                                    : "1px solid #E3E7FF",
                              }}
                              key={segment.segment_link}
                            >
                              <FormControlLabel
                                sx={{ marginRight: "10px", width: "100%" }}
                                control={
                                  <Radio
                                    icon={
                                      values[segment.segment_link] === true ? (
                                        <img src={TickCircle} width={"24px"} />
                                      ) : (
                                        <img src={EmptyCircle} width={"24px"} />
                                      )
                                    }
                                    checkedIcon={
                                      values[segment.segment_link] ===
                                      "equity" ? (
                                        <img src={EmptyCircle} width={"24px"} />
                                      ) : (
                                        <img src={TickCircle} width={"24px"} />
                                      )
                                    }
                                    name={segment.segment_link}
                                    onClick={(e) => {
                                      // if (segment.segment_link !== "equity") {
                                      // e.stopPropagation();
                                      handleCheckBoxClick(
                                        segment.segment_link,
                                        setFieldValue,
                                        values
                                      );
                                      // }
                                    }}
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                      position: "absolute",
                                      right: "10px",
                                    }}
                                    value={values[segment.segment_link]}
                                    checked={values[segment.segment_link]}
                                  />
                                }
                                label={segment.value}
                              />
                              {segment.label === "Equity" && (
                                <span className={TradingStyles.defaultLabel}>
                                  Default
                                </span>
                              )}
                            </div>
                          ))}
                        </FormGroup>
                      </Stack>
                      {backdrop && <StartOverBackDrop />}
                      <div className={TradingStyles.buttonStack}>
                        <div>
                          <Button
                            id="tradingPreferencesContinueButton"
                            variant="text"
                            className={TradingStyles.backButton}
                            sx={{
                              background: "#EEEFEF",
                              "&:hover": {
                                background: "#EEEFEF",
                              },
                            }}
                            onClick={() => {
                              navigate("/yourprofile");
                            }}
                          >
                            Back
                          </Button>
                        </div>
                        <div>
                          {Object.values(values).some(
                            (value) => value === true
                          ) ? (
                            <LoadingButton
                              id="tradingPreferencesContinueButton"
                              variant="contained"
                              // type="submit"
                              onClick={() => {
                                handleSubmit();
                              }}
                              className={TradingStyles.approveButton}
                            >
                              Continue
                            </LoadingButton>
                          ) : (
                            <LoadingButton
                              id="tradingPreferencesContinueButton"
                              variant="contained"
                              // type="submit"
                              onClick={() => {
                                handleSubmit();
                              }}
                              disabled
                              className={TradingStyles.approveButton}
                            >
                              Continue
                            </LoadingButton>
                          )}
                        </div>
                        {/* <div className={TradingStyles.startOverSection}>
                        <img src={icon} className={TradingStyles.iconImage} />
                        <p className={TradingStyles.paratext}>
                          {" "}
                          if the above mentioned info not yours
                          <span
                            className={TradingStyles.startoverbutton}
                            onClick={handleStartOver}
                          >
                            Start Over
                          </span>
                        </p>
                      </div> */}
                      </div>
                    </Stack>
                  </Form>
                );
              }}
            </Formik>
          ) : (
            <div className={TradingStyles.wrapper}>
              <BackDrop />
            </div>
          )}

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={showModal}
            closeAfterTransition
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={showModal}>
              <Box className={TradingStyles.boxModalWrapper}>
                <Stack
                  sx={{ position: "absolute", right: "20px", top: "20px" }}
                >
                  <IconButton
                    onClick={() => {
                      setshowModal(false);
                    }}
                  >
                    <HighlightOffRoundedIcon style={{ color: "#000" }} />
                  </IconButton>
                </Stack>
                <div className={TradingStyles.riskdisclosuremodalwrapper}>
                  <div className={TradingStyles.riskDisclosuresWrapper}>
                    <div className={TradingStyles.riskdisclosuretopsection}>
                      {/* <i
                      className="fa fa-newspaper-o"
                      style="font-size: 20px; margin-top: 3px"
                    ></i> */}
                      <p className={TradingStyles.riskdisclosuresheader}>
                        Risk disclosures on derivatives
                      </p>
                    </div>

                    <div className={TradingStyles.riskdisclosurecentersection}>
                      <ul className={TradingStyles.riskdiclosureul}>
                        <li className={TradingStyles.riskdiclosureulli}>
                          9 out of 10 individual traders in equity Futures and
                          Options Segment, incurred net losses.
                        </li>
                        <li className={TradingStyles.riskdiclosureulli}>
                          On an average, loss makers registered net trading loss
                          close to Rs 50,000.
                        </li>
                        <li className={TradingStyles.riskdiclosureulli}>
                          Over and above the net trading losses incurred, loss
                          makers expended an additional 28% of net trading
                          losses as transaction costs.
                        </li>
                        <li className={TradingStyles.riskdiclosureulli}>
                          Those making net trading profits, incurred between 15%
                          to 50% of such profits as transaction cost.
                        </li>
                      </ul>

                      <p className={TradingStyles.riskdiclosureparadesc}>
                        Source:
                        <a
                          href="https://www.sebi.gov.in/reports-and-statistics/research/jan-2023/study-analysis-of-profit-and-loss-of-individual-traders-dealing-in-equity-fando-segment_67525.html"
                          target="_blank"
                          className="riskdisclosureatag"
                        >
                          SEBI study{" "}
                        </a>{" "}
                        dated january 25, 2023 on "Analysis of Profit and Loss
                        of Individual Traders dealing in equity Futures and
                        Options (F&O) Segment",wherein Aggregate Level findings
                        are based on annual Profit/Loss invurred by individual
                        traders in equity F&O during FY 2021-22.
                      </p>
                    </div>

                    <div className={TradingStyles.riskdisclosurebuttonsection}>
                      <Button
                        className={TradingStyles.riskdisclosurebutton}
                        variant="contained"
                        sx={{ borderRadius: "4px" }}
                        onClick={handleRiskDisclosure}
                      >
                        I understand
                      </Button>
                    </div>
                  </div>
                </div>
              </Box>
            </Fade>
          </Modal>
        </div>
      </div>

      <p className={TradingStyles.startover}>
        {" "}
        If you want to start over, please
        <span
          className={TradingStyles.startoverbutton}
          onClick={handleStartOver}
        >
          Click Here
        </span>
      </p>
    </>
  );
}
