import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { Box, Stack } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepButton from "@mui/material/StepButton";
import { useSelector, Provider } from "react-redux";
import store from "./Components/redux/store";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { theme } from "./Components/ThemeProvider/ThemeProvider";
import Router from "./routes";
import Header from "./pages/Header";

import { StepIconProps } from "@mui/material/StepIcon";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import Check from "@mui/icons-material/Check";
import AppStyles from "./App.module.scss";
import BackButtonListener from "./Components/PreventBackNavigation/PreventBackNavigation";
import { stageDetailsAPICall } from "./shared/APICalls";
import routesData from "./shared/routes.json";
import Footer from "./Components/Footer/Footer";
import BackToTopButton from "./Components/BacktoTop/BackToTopComp";

const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 5,
    alignItems: "center",
    ...(ownerState.active && {
      color: "rgba(3, 152, 85, 1)",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "rgba(3, 152, 85, 1)",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  })
);

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Header />

      <BrowserRouter>
        <Router />
      </BrowserRouter>

      <Footer />
      <BackToTopButton />
    </ThemeProvider>
  );
}

export default App;
