import React, { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  Typography,
  Button,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Modal,
  Fade,
  Box,
  Grid,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import FormatterChecker from "./FormatChecker";
import { useNavigate } from "react-router-dom";
import { checkUserLoggedIn } from "../shared/commonfunctions";
import {
  startOverAPICall,
  documentupdateAPICall,
  getDocumentTypeDropDownAPICall,
  stageDetailsAPICall,
} from "../shared/APICalls";
import routesData from "../shared/routes.json";
import StageLayout from "../Layouts/StageLayout";
import PreviewModalPopup from "../Components/Modals/PreviewModalPopup";
import uploadStyles from "../pages/Styles/Uploads.module.scss";
import icon from "../Components/assets/Images/iconnew.svg";
import UploadImage from "../Components/assets/Images/document-upload.svg";
import SnackBar from "../Components/SnackBars/SnackBar";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import StartOverBackDrop from "../Components/StartOverBackDrop";
import SucessSnackBar from "../Components/SnackBars/SucesssnackBar";
import BackDrop from "../Components/BackDrop";
import ErrorSnackBar from "../Components/SnackBars/ErrorSnackBar";
import { UploadSizes } from "../shared/constants";
import { confirmAlert } from "react-confirm-alert";

export default function UploadDocumentsPage() {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [message, setmessage] = React.useState("");
  const [infoMessage, setinfoMessage] = React.useState(true);
  const [incomeProofDropdown, setincomeProofDropdown] = useState<any>([]);
  const [incomeProofDocRef, setincomeProofDocRef] = useState<any>();
  const [signatureDocRef, setSignatureDocRef] = useState<any>();
  const [bankProofDocRef, setBankProofDocRef] = useState<any>();
  const [panCardDocRef, setPanCardDocRef] = useState<any>();
  const [showUpload, setShowUpload] = useState(false);
  const [disableContinueOption1, setdisableContinueOption1] = useState("");
  const [disableContinueOption2, setdisableContinueOption2] = useState("");
  const [disableContinueOption3, setdisableContinueOption3] = useState("");
  const [disableContinueOption4, setdisableContinueOption4] = useState("");
  const [displayDropdownOptions, setDisplayDropdownOptions] = useState(true);
  const [completedStages, setCompletedStages] = useState<any>();
  const [incomeProofUrl, setincomeProofUrl] = useState<any>();
  const [signatureUrl, setsignatureUrl] = useState<any>();
  const [bankProofeUrl, setbankProofUrl] = useState<any>();
  const [pancardUrl, setpancardUrl] = useState<any>();
  const [IncomeProof, setIncomeProof] = useState<any>("");
  const [InformationMessage, setInformationMessage] = useState("");
  const [backdrop, setBackDrop] = useState(false);
  const rupeeSymbol = String.fromCharCode(8377);
  const [uploadmessage, setUploadmessage] = useState("");
  const [backLoading, setBackDropLoading] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");
  const [IncomeProofDocTypeRef, setIncomeProofDocTypeRef] = useState("");
  const [bankDetails, setBankDetails] = useState({
    AccountNumber: "",
    KraStatus: "",
    IFSCCode: "",
  });
  const [foSelected, setFOSecelectd] = useState(true);

  const [initialdropdowndata, setinitialdropdowndata] = useState<any>([]);

  const navigationData = routesData;

  const chequeFormat = ["png", "jpg", "jpeg"];
  const formats = ["pdf", "png", "jpg", "jpeg"];

  const handleChange = (event: any) => {
    // console.log("event.target.value.docTypeRef",event.target.value)
    setIncomeProofDocTypeRef(event.target.value.doc_type_ref);

    setIncomeProof(event.target.value);
    setincomeProofDocRef(event.target.value.purpose);

    setShowUpload(true);

    switch (event.target.value.value) {
      case "Copy of ITR acknowledgement":
        setInformationMessage(
          `ITR acknowledgment with gross annual income exceeding ${rupeeSymbol}1,20,000`
        );
        break;
      case "Salary Slip for last 3 months":
        setInformationMessage(
          `The latest salary slip with gross monthly income exceeding ${rupeeSymbol}15,000`
        );
        break;
      case "Copy of Form 16 in case of salary income":
        setInformationMessage(
          `Form 16 with gross annual income exceeding ${rupeeSymbol}1,20,000`
        );
        break;
      case "Copy of cancelled cheque":
        setInformationMessage(`Copy of cancelled cheque of your bank account`);
        break;
      case "Bank account statement for last 6 month":
        setInformationMessage(
          `Bank statement for the last 6 months with average balance of more than ${rupeeSymbol}10,000`
        );
        break;
    }
  };
  const handleClick = () => {
    handleUpdateDocuments();
  };
  const handleStartOver = () => {
    confirmAlert({
      title: "",
      message:
        "Are you sure you want to restart, on pressing Yes all the Data will be deleted",
      buttons: [
        {
          label: "No",
          onClick: () => "",
        },
        {
          label: "Yes",
          onClick: () => {
            setBackDrop(true);

            startOverAPICall()
              .then((response: any) => {
                const responseData = response.data;

                setBackDrop(false);
                navigate("/");
                window.location.reload();
              })
              .catch((error: any) => {
                setBackDrop(false);
              });
          },
        },
      ],
    });
  };

  const handleUpdateDocuments = () => {
    setloading(true);
    documentupdateAPICall()
      .then((response: any) => {
        const responseData = response.data;
        setloading(false);
        if (
          responseData.data.next_stage &&
          navigationData.hasOwnProperty(responseData.data.next_stage)
        ) {
          navigate(navigationData[responseData.data.next_stage]);
        }
      })
      .catch((error: any) => {
        setloading(false);
      });
  };

  const handlePreview = (data: any) => {};

  const handleDisplayOptions = (data: any) => {
    setDisplayDropdownOptions(data);
    if (data === true) {
      setIncomeProof("");
      setIncomeProof(initialdropdowndata[0]);
      setIncomeProofDocTypeRef(initialdropdowndata[0].doc_type_ref);
      setincomeProofDocRef(initialdropdowndata[0].purpose);
      setincomeProofUrl("");
      setInformationMessage("");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // checkUserLoggedIn(navigate);
    StageDetailsInitial();
  }, []);

  const StageDetailsInitial = () => {
    let dropDowndata: any;
    setBackDropLoading(true);
    // getDocumentTypeDropDownAPICall()
    //   .then((response: any) => {
    //     const responseData = response.data;

    //     const incomeProofdata = responseData.data.income_proof;
    //     const filtredData = incomeProofdata.sort(
    //       (a: any, b: any) => a.sequence - b.sequence
    //     );
    //     // console.log("responseData", incomeProofdata);
    //     setIncomeProof(filtredData[0]);
    //     setinitialdropdowndata(filtredData);
    //     setIncomeProofDocTypeRef(filtredData[0].docTypeRef);
    //     setincomeProofDocRef(filtredData[0].purpose);
    //     setShowUpload(true);
    //     setincomeProofDropdown(filtredData);
    //     dropDowndata = responseData.data.income_proof;
    //     setSignatureDocRef(responseData.data.signature.docTypeRef);
    //     setPanCardDocRef(responseData.data.pancard.docTypeRef);
    //     setincomeProofDocRef(responseData.data.income_proof[0].purpose);
    //   })
    //   .catch((error: any) => {});
    let obj: any = {
      stage: "document_upload",
    };

    stageDetailsAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;

        setCompletedStages(responseData.data.completed_stages);
        // const futuresAndOptionsObject =
        //   responseData.data.StageDetails.TradingPref.find(
        //     (item) => item.TradingPreLink === "futures_and_options"
        //   );
        setBackDropLoading(false);
        // if (response.data.data.StageDetails.ClientDetails) {
        //   setBankDetails({
        //     AccountNumber: response.data.data.StageDetails.ClientDetails[0]
        //       .AccountNumber
        //       ? response.data.data.StageDetails.ClientDetails[0].AccountNumber
        //       : "",
        //     KraStatus: response.data.data.StageDetails.ClientDetails[0]
        //       .KraStatus
        //       ? response.data.data.StageDetails.ClientDetails[0].KraStatus
        //       : "",
        //     IFSCCode: response.data.data.StageDetails.ClientDetails[0].IFSC
        //       ? response.data.data.StageDetails.ClientDetails[0].IFSC
        //       : "",
        //   });
        // }

        if (responseData.data.data["is_f&o_selected"] === true) {
          setFOSecelectd(true);
          setdisableContinueOption3("");
        } else {
          setFOSecelectd(false);
          setdisableContinueOption3("Document uploaded successfully.");
        }
        if (response.data.data.ErrorMessage) {
          setRejectMessage(response.data.data.ErrorMessage);
        }

        if (responseData.data.data.income_proof.url) {
          setdisableContinueOption3("Document uploaded successfully.");
        }
        if (responseData.data.data.signature.url) {
          setdisableContinueOption1("Document uploaded successfully.");
        }
        if (responseData.data.data.pancard.url) {
          setdisableContinueOption2("Document uploaded successfully.");
        }
        if (responseData.data.data.bank_proof.url) {
          setdisableContinueOption4("Document uploaded successfully.");
        }
        console.log(
          "responseData.data.data.pancard",
          responseData.data.data.pancard
        );
        if (
          responseData.data.data.pancard &&
          responseData.data.data.pancard.url
        ) {
          setpancardUrl(responseData.data.data.pancard.url);
        }

        if (
          responseData.data.data.signature &&
          responseData.data.data.signature.url
        ) {
          setsignatureUrl(responseData.data.data.signature.url);
        }
        if (
          responseData.data.data.bank_proof &&
          responseData.data.data.bank_proof.url
        ) {
          setbankProofUrl(responseData.data.data.bank_proof.url);
        }

        if (
          responseData.data.data.income_proof &&
          responseData.data.data.income_proof.url
        ) {
          setShowUpload(true);

          setincomeProofUrl(responseData.data.data.income_proof.url);
        }

        // if (
        //   dropDowndata &&
        //   responseData.data.StageDetails.Documents.income_proof
        // ) {
        //   const SetSelectData = dropDowndata.find(
        //     (item) =>
        //       item.docTypeRef ===
        //       responseData.data.StageDetails.Documents.income_proof.DocTypeRef
        //   );

        //   setIncomeProof(SetSelectData);
        //   setDisplayDropdownOptions(false);
        // }
      })
      .catch((error: any) => {
        // console.log("error", error);
        setBackDropLoading(false);
      });
  };

  const handleSignatureDocumentID = (data: any) => {
    //  console.log("dataSignature", data);

    setdisableContinueOption1(data);

    setUploadmessage(data);
    setTimeout(() => {
      setUploadmessage("");
    }, 5000);
  };
  const handleBankProofDocumentID = (data: any) => {
    //  console.log("dataSignature", data);

    setdisableContinueOption4(data);

    setUploadmessage(data);
    setTimeout(() => {
      setUploadmessage("");
    }, 5000);
  };
  const handlePanDocumentID = (data: any) => {
    // console.log("panCradData", data);

    setdisableContinueOption2(data);

    setUploadmessage(data);
    setTimeout(() => {
      setUploadmessage("");
    }, 5000);
  };

  const dataArray: any[] = [];
  const handleDocumentID = (data: any) => {
    // console.log("documentID", data);
    setdisableContinueOption3(data);

    setUploadmessage(data);

    setTimeout(() => {
      setUploadmessage("");
    }, 5000);
    dataArray.push(data);

    const nameCounts = dataArray.reduce((countMap, name) => {
      countMap[name] = (countMap[name] || 0) + 1;
      return countMap;
    }, {});
    const uniqueNames = dataArray.filter((name) => nameCounts[name] === 1);
    // console.log("uniqueNames", uniqueNames);

    if (dataArray[0] === "Document uploaded successfully.") {
    }
    if (uniqueNames.length === 3) {
    } else {
    }
  };

  return (
    <>
      <div className={uploadStyles.desktopProgress}>
        <StageLayout
          completedStages={completedStages}
          stageName={"Upload Documents"}
          navigatePath={"/nomineepage"}
        />
      </div>
      <div className={uploadStyles.mainWrapperTop}>
        <div className={uploadStyles.mobileProgress}>
          <StageLayout
            completedStages={completedStages}
            stageName={"Upload Documents"}
            navigatePath={"/nomineepage"}
          />
        </div>
        <ErrorSnackBar message={rejectMessage} />
        <div className={uploadStyles.wrapper}>
          <div className={uploadStyles.headerSection}>
            <p className={uploadStyles.signup}>Upload Your Documents</p>
          </div>
          {!backLoading ? (
            <div className={uploadStyles.contentWrapper}>
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 1, sm: 2, md: 5 }}
              >
                <Grid item xs={12} md={6}>
                  <p className={uploadStyles.textfieldLabel}>
                    Upload Your Signature
                  </p>
                  <div className={uploadStyles.uploadSignatureSection}>
                    <div>
                      <p className={uploadStyles.subText}>
                        {" "}
                        Upload Your Signature{" "}
                      </p>
                    </div>
                    <div>
                      <FormatterChecker
                        previewImage={handlePreview}
                        acceptedFormats={chequeFormat}
                        documentRef={"signature"}
                        documentID={handleSignatureDocumentID}
                        displayOptions={""}
                        stageDetailsData={signatureUrl}
                        name="signature"
                        purpose={"signature"}
                        pdfSize={UploadSizes["signature"].pdfSize}
                        imageSize={UploadSizes["signature"].imageSize}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <p className={uploadStyles.textfieldLabel}>Upload Pan Card</p>
                  <div className={uploadStyles.uploadSignatureSection}>
                    <div>
                      <p className={uploadStyles.subText}> Upload Pan Card </p>
                    </div>
                    <div>
                      <FormatterChecker
                        previewImage={handlePreview}
                        acceptedFormats={formats}
                        documentRef={"pancard"}
                        documentID={handlePanDocumentID}
                        displayOptions={""}
                        stageDetailsData={pancardUrl}
                        name="pancard"
                        purpose={"pancard"}
                        pdfSize={UploadSizes["pancard"].pdfSize}
                        imageSize={UploadSizes["pancard"].imageSize}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <p className={uploadStyles.textfieldLabel}>
                    Upload copy of Cancelled Cheque / Bank Statement
                  </p>
                  <div className={uploadStyles.uploadSignatureSection}>
                    <div>
                      <p className={uploadStyles.subText}>
                        {" "}
                        Cancelled Cheque / Bank Statement
                      </p>
                    </div>
                    <div>
                      <FormatterChecker
                        previewImage={handlePreview}
                        acceptedFormats={formats}
                        documentRef={"bank_proof"}
                        documentID={handleBankProofDocumentID}
                        displayOptions={""}
                        stageDetailsData={bankProofeUrl}
                        name="bank_proof"
                        purpose={"bank_proof"}
                        pdfSize={UploadSizes["bank_proof"].pdfSize}
                        imageSize={UploadSizes["bank_proof"].imageSize}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <p className={uploadStyles.textfieldLabel}>
                    Upload Income Proof
                  </p>
                  <div className={uploadStyles.uploadSignatureSection}>
                    <div>
                      <p className={uploadStyles.subText}> Income Proof</p>
                    </div>
                    <div>
                      <FormatterChecker
                        previewImage={handlePreview}
                        acceptedFormats={formats}
                        documentRef={"income_proof"}
                        documentID={handleDocumentID}
                        displayOptions={handleDisplayOptions}
                        stageDetailsData={incomeProofUrl}
                        name="income_proof"
                        purpose={"income_proof"}
                        pdfSize={UploadSizes["income_proof"].pdfSize}
                        imageSize={UploadSizes["income_proof"].imageSize}
                      />
                    </div>
                  </div>
                  {foSelected && (
                    <div className={uploadStyles.infoSection}>
                      *Since you have selected F&O, your income proof is
                      mandatory
                    </div>
                  )}
                </Grid>
              </Grid>
              <div
                className={uploadStyles.messageSection}
                style={{
                  // alignItems: "center",
                  display: "flex",
                }}
              >
                <InfoOutlinedIcon />{" "}
                <span>
                  Copy of Cancelled Cheque / Bank Statement Should display your
                  name, bank account number and IFSC Code
                </span>
              </div>

              <SucessSnackBar message={uploadmessage} />

              <SnackBar message={message} />
              {backdrop && <StartOverBackDrop />}
              <div className={uploadStyles.buttonStack}>
                <div>
                  <Button
                    id="tradingPreferencesContinueButton"
                    variant="text"
                    className={uploadStyles.backButton}
                    sx={{
                      background: "#EEEFEF",
                      "&:hover": {
                        background: "#EEEFEF",
                      },
                    }}
                    onClick={() => {
                      navigate("/nominee");
                    }}
                  >
                    Back
                  </Button>
                </div>
                <div>
                  {disableContinueOption1 ===
                    "Document uploaded successfully." &&
                  disableContinueOption2 ===
                    "Document uploaded successfully." &&
                  disableContinueOption3 ===
                    "Document uploaded successfully." &&
                  disableContinueOption4 ===
                    "Document uploaded successfully." ? (
                    <LoadingButton
                      variant="contained"
                      onClick={handleClick}
                      loading={loading}
                      id="upLoadDocumentsContinueButton"
                      className={uploadStyles.approveButton}
                      loadingIndicator={
                        <CircularProgress sx={{ color: "#4754A0" }} size={20} />
                      }
                    >
                      {" "}
                      Continue
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      variant="contained"
                      onClick={handleClick}
                      loading={loading}
                      disabled
                      id="upLoadDocumentsContinueButton"
                      className={uploadStyles.approveButton}
                      loadingIndicator={
                        <CircularProgress sx={{ color: "#4754A0" }} size={20} />
                      }
                    >
                      {" "}
                      Continue
                    </LoadingButton>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div style={{ height: "65vh" }}>
              <BackDrop />
            </div>
          )}
        </div>
      </div>
      <p className={uploadStyles.startover}>
        {" "}
        If you want to start over, please
        <span
          className={uploadStyles.startoverbutton}
          onClick={handleStartOver}
        >
          Click Here
        </span>
      </p>
    </>
  );
}
