import React, { useEffect, useState } from "react";
import {
  Stack,
  Typography,
  FormGroup,
  FormControlLabel,
  Button,
  Checkbox,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Modal,
  Fade,
  Box,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Navigate, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import routesData from "../shared/routes.json";
import {
  stageDetailsAPICall,
  startOverAPICall,
  termsAndConditionsAPICall,
} from "../shared/APICalls";
import { ITermsandConditions } from "../shared/dtos";
import { checkUserLoggedIn } from "../shared/commonfunctions";
import StageLayout from "../Layouts/StageLayout";
import Styles from "../pages/Styles/TermsandConditions.module.scss";
import image from "../Components/assets/Images/TCImage.svg";
import icon from "../Components/assets/Images/iconnew.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import TickCircle from "../Components/assets/Images/tick-circle.svg";
import StartOverBackDrop from "../Components/StartOverBackDrop";
import BackDrop from "../Components/BackDrop";
import Dropdown from "../Components/SelectDropdown/DropDown";
import PDFViewerIframe from "../Components/PDFIframe/PDFWithIframe";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { confirmAlert } from "react-confirm-alert";
import SnackBar from "../Components/SnackBars/SnackBar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ErrorSnackBar from "../Components/SnackBars/ErrorSnackBar";

const TradingPreferenceSchema = Yup.object().shape({
  tariff: Yup.boolean().oneOf([true], "tariff is required"),
  condition1: Yup.boolean().oneOf([true], "Accept the condition"),
  condition2: Yup.boolean().oneOf([true], "Accept the condition"),
  condition3: Yup.boolean().oneOf([true], "Accept the condition"),
  condition4: Yup.boolean().oneOf([true], "Accept the condition"),
});

const Settlements = [
  {
    id: "quarter",
    sequence: 1,
    value: "Quarterly",
  },
  {
    id: "monthly",
    sequence: 2,
    value: "Monthly",
  },
];
const etch = [
  {
    id: true,
    sequence: 1,
    value: "Yes",
  },
  {
    id: false,
    sequence: 2,
    value: "No",
  },
];
const period = [
  {
    id: "daily",
    sequence: 1,
    value: "Daily",
  },
  {
    id: "weekly",
    sequence: 2,
    value: "Weekly",
  },
  {
    id: "fortnightly",
    sequence: 3,
    value: "Fortnightly",
  },
  {
    id: "monthly",
    sequence: 4,
    value: "Monthly",
  },
];
const dpaccepect = [
  {
    id: true,
    sequence: 1,
    value: "Yes",
  },
  {
    id: false,
    sequence: 2,
    value: "No",
  },
];

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vW",
  height: "90vH",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  padding: "0px 0px 0px 5px",
};

export default function TermsandConditionsPage() {
  const [startOverloading, setstartOverloading] = useState(false);
  const [loading, setloading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [completedStages, setCompletedStages] = useState<any>();
  const [backdrop, setBackDrop] = useState(false);
  const [prefilloading, setprefilloading] = useState(true);
  const [showModal, setshowModal] = useState(false);
  const [dropDownValue, setDropDownValue] = useState<any>(Settlements[0]);
  const [etchvalue, setetchvalue] = useState<any>(etch[0]);
  const [regulationperiod, setregulationperiod] = useState<any>(period[3]);
  const [dropDownValuedpaccepect, setDropDownValuedpaccepect] = useState<any>(
    dpaccepect[0]
  );
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const navigationData = routesData;
  const [prefilldata, setprefillData] = useState({
    condition1: true,
    condition2: true,
    condition3: true,
    condition4: true,
  });
  const [pdfUrl, setPdfUrl] = useState<any>("");
  const [rejectMessage, setrejectMessage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    // checkUserLoggedIn(navigate);

    let obj: any = {
      stage: "t_and_c",
    };

    stageDetailsAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseData", responseData.data.data);
        setCompletedStages(responseData.data.completed_stages);
        if (responseData.data.ErrorMessage) {
          setrejectMessage(responseData.data.ErrorMessage);
        }

        setTimeout(() => {
          setprefilloading(false);
        }, 500);

        if (responseData.data.data.is_t_and_c === true) {
          setprefillData((prev) => {
            return {
              ...prev,
              condition1: true,
              condition2: true,
              condition3: true,
              condition4: true,
            };
          });

          setSelectAll(true);
        }

        if (responseData.data.data.settlement_preference) {
          const preferenceType = Settlements.filter(
            (data) => data.id === responseData.data.data.settlement_preference
          )[0];
          setDropDownValue(preferenceType);
        }

        const etchfilter = etch.filter(
          (a: any) =>
            a.id ===
            responseData.data.data.terms_and_conditions
              .electronic_transaction_holding_statement
        );
        const periodfilter = period.filter(
          (a: any) =>
            a.id ===
            responseData.data.data.terms_and_conditions
              .account_statement_requirement
        );
        const dpaccepectfilter = dpaccepect.filter(
          (a: any) =>
            a.id ===
            responseData.data.data.terms_and_conditions
              .accept_all_pledge_instructions
        );

        setetchvalue(etchfilter[0]);
        setregulationperiod(periodfilter[0] ? periodfilter[0] : period[3]);
        setDropDownValuedpaccepect(dpaccepectfilter[0]);
      })
      .catch((error: any) => {
        setprefilloading(false);
      });
  }, []);

  function termsAndConditions() {
    setloading(true);
    let obj: any = {
      is_t_and_c: true,
      settlement_preference: dropDownValue.id,
      terms_and_conditions: {
        account_statement_requirement: regulationperiod.id,
        accept_all_pledge_instructions: dropDownValuedpaccepect.id,
        electronic_transaction_holding_statement: etchvalue.id,
      },
    };

    termsAndConditionsAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseData", response);
        setloading(false);
        if (
          responseData.data.next_stage &&
          navigationData.hasOwnProperty(responseData.data.next_stage)
        ) {
          navigate(navigationData[responseData.data.next_stage]);
        }
      })
      .catch((error: any) => {
        setloading(false);

        if (error.response.data.message) {
          setMessage(error.response.data.message);
          setTimeout(() => {
            setMessage("");
          }, 5000);
        }
      });
  }

  const handleChangeSelectChange = (event: any) => {
    setDropDownValue(event.target.value);
  };

  const handleSelectAllChange = (event, setFieldValue) => {
    const { checked } = event.target;
    setSelectAll(checked);

    setFieldValue("condition1", checked);
    setFieldValue("condition2", checked);
    setFieldValue("condition3", checked);
    setFieldValue("condition4", checked);
  };

  const handlePreviewClick = (type: any) => {
    if (type === "tariff") {
      setPdfUrl(
        "https://compositepublic.s3.ap-south-1.amazonaws.com/folders/ONLINE++TRADING++TARIFF+SHEET.pdf"
      );
    } else {
      setPdfUrl(
        "https://compositepublic.s3.ap-south-1.amazonaws.com/folders/ONLINE+DP+TARIFF+SHEET.pdf"
      );
    }
    setshowModal(true);
  };

  const handlePreviewClose = () => {
    setshowModal(false);
  };

  const handleStartOver = () => {
    confirmAlert({
      title: "",
      message:
        "Are you sure you want to restart, on pressing Yes all the Data will be deleted",
      buttons: [
        {
          label: "No",
          onClick: () => "",
        },
        {
          label: "Yes",
          onClick: () => {
            setBackDrop(true);
            setstartOverloading(true);
            startOverAPICall()
              .then((response: any) => {
                const responseData = response.data;
                // console.log("responseData", response);
                setstartOverloading(false);
                setBackDrop(false);
                navigate("/");
                window.location.reload();
              })
              .catch((error: any) => {
                setBackDrop(false);
                setstartOverloading(false);
              });
          },
        },
      ],
    });
  };

  const InitialValues = {
    condition1: prefilldata.condition1,
    condition2: prefilldata.condition2,
    condition3: prefilldata.condition3,
    condition4: prefilldata.condition4,
  };
  return (
    <>
      <ErrorSnackBar message={rejectMessage} />
      <SnackBar message={message} />
      <div className={Styles.desktopProgress}>
        <StageLayout
          completedStages={completedStages}
          stageName={"Terms & Conditions"}
          navigatePath={"/linkusingifsc"}
        />
      </div>
      <div className={Styles.mainWrapperTop}>
        <div className={Styles.mobileProgress}>
          <StageLayout
            completedStages={completedStages}
            stageName={"Terms & Conditions"}
            navigatePath={"/linkusingifsc"}
          />
        </div>
        <div className={Styles.wrapper}>
          <div className={Styles.leftContainer}>
            {!prefilloading ? (
              <Formik
                initialValues={InitialValues}
                validationSchema={TradingPreferenceSchema}
                onSubmit={(values) => {
                  termsAndConditions();
                }}
              >
                {({ values, errors, touched, setFieldValue }) => {
                  {
                    values.condition1 &&
                    values.condition2 &&
                    values.condition3 &&
                    values.condition4
                      ? setSelectAll(true)
                      : setSelectAll(false);
                  }
                  return (
                    <Form>
                      <div>
                        <div className={Styles.mainContainer}>
                          <div className={Styles.imageContainerMobile}>
                            <img src={image} className={Styles.image} />
                          </div>
                          <div className={Styles.headerSection}>
                            <p className={Styles.signup}>
                              Declarations and Standing instructions for your
                              Trading and Demat Account
                            </p>
                            <p className={Styles.subText}>
                              By pressing continue, you acknowledge and confirm
                              that the information provided by you is true &
                              Correct.
                            </p>
                            <p className={Styles.subText}>
                              Bold items are selectable .
                            </p>
                          </div>
                          <div className={Styles.contentContaineWrapper}>
                            <div className={Styles.contentContainer}>
                              <div className={Styles.checkBoxDiv}>
                                <FormGroup>
                                  <FormControlLabel
                                    sx={{ alignItems: "flex-start" }}
                                    control={
                                      <Checkbox
                                        name="selectAll"
                                        checked={selectAll}
                                        sx={{
                                          padding: "0px",
                                          display: "none",
                                        }}
                                        onChange={(event) =>
                                          handleSelectAllChange(
                                            event,
                                            setFieldValue
                                          )
                                        }
                                        icon={<PanoramaFishEyeIcon />}
                                        checkedIcon={
                                          <img
                                            src={TickCircle}
                                            width={"24px"}
                                          />
                                        }
                                      />
                                    }
                                    label={
                                      <span className={Styles.label}></span>
                                    }
                                  />
                                </FormGroup>
                              </div>
                              <div className={Styles.checkBoxDiv}>
                                <FormGroup>
                                  <FormControlLabel
                                    sx={{ alignItems: "flex-start" }}
                                    control={
                                      <Checkbox
                                        name="condition1"
                                        checked={selectAll || values.condition1}
                                        icon={<PanoramaFishEyeIcon />}
                                        checkedIcon={
                                          <img
                                            src={TickCircle}
                                            width={"24px"}
                                          />
                                        }
                                        sx={{ padding: "0px", display: "none" }}
                                        onChange={(event) => {
                                          const { checked } = event.target;
                                          setFieldValue("condition1", true);
                                        }}
                                      />
                                    }
                                    label={
                                      <span className={Styles.label}>
                                        <span>1.</span>{" "}
                                        <span>
                                          My sole country of Tax Residency is
                                          India. I have understood the
                                          information requirements of this Form
                                          (read along with the FATCA and CRS
                                          instructions) and hereby confirm that
                                          the information provided by me/us on
                                          this Form is true, correct and
                                          complete. I also confirm that I have
                                          read and understood the FATCA and CRS
                                          Terms and Conditions below and hereby
                                          accept the same. The Name in your
                                          account will be updated as per the
                                          Income Tax Database owing to Exchange
                                          Regulations.
                                        </span>
                                      </span>
                                    }
                                  />
                                </FormGroup>
                                {errors.condition1 && touched.condition1 && (
                                  <Typography variant="body2" color="error">
                                    {errors.condition1}
                                  </Typography>
                                )}
                              </div>
                              <div className={Styles.checkBoxDiv}>
                                <FormGroup>
                                  <FormControlLabel
                                    sx={{ alignItems: "flex-start" }}
                                    control={
                                      <Checkbox
                                        name="condition2"
                                        sx={{ padding: "0px", display: "none" }}
                                        checked={selectAll || values.condition2}
                                        icon={<PanoramaFishEyeIcon />}
                                        checkedIcon={
                                          <img
                                            src={TickCircle}
                                            width={"24px"}
                                          />
                                        }
                                        onChange={(event) => {
                                          const { checked } = event.target;
                                          setFieldValue("condition2", true);
                                        }}
                                      />
                                    }
                                    label={
                                      <span className={Styles.label}>
                                        <span>2.</span>{" "}
                                        <span>
                                          {" "}
                                          I authorise to operate the movement of
                                          securities from my demat account for
                                          any Obligations created at the
                                          Exchange through my order(s)/trade(s)
                                          or for the purpose of availing margins
                                          as per the Terms & Conditions of the
                                          Online Delivery Instruction.on.
                                        </span>
                                      </span>
                                    }
                                  />
                                </FormGroup>
                                {errors.condition2 && touched.condition2 && (
                                  <Typography variant="body2" color="error">
                                    {errors.condition2}
                                  </Typography>
                                )}
                              </div>
                              <div className={Styles.checkBoxDiv}>
                                <FormGroup>
                                  <FormControlLabel
                                    sx={{ alignItems: "flex-start" }}
                                    control={
                                      <Checkbox
                                        name="condition3"
                                        sx={{ padding: "0px", display: "none" }}
                                        checked={selectAll || values.condition3}
                                        icon={<PanoramaFishEyeIcon />}
                                        checkedIcon={
                                          <img
                                            src={TickCircle}
                                            width={"24px"}
                                          />
                                        }
                                        onChange={(event) => {
                                          const { checked } = event.target;
                                          setFieldValue("condition3", true);
                                        }}
                                      />
                                    }
                                    label={
                                      <span className={Styles.label}>
                                        <span>3.</span>{" "}
                                        <span>
                                          I confirm and understand that my name
                                          as per the IT Department will be taken
                                          as my default account name, in case
                                          there is any mismatch the IT
                                          Department and my name as per the KRA
                                          database/Aadhaar/Bank Account. I
                                          Further confirm that the linked bank
                                          accounts, Aadhaar, and PAN belong to
                                          me.
                                        </span>
                                      </span>
                                    }
                                  />
                                </FormGroup>
                                {errors.condition3 && touched.condition3 && (
                                  <Typography variant="body2" color="error">
                                    {errors.condition3}
                                  </Typography>
                                )}
                              </div>

                              <div className={Styles.checkBoxDiv}>
                                <FormGroup>
                                  <FormControlLabel
                                    sx={{ alignItems: "flex-start" }}
                                    control={
                                      <Checkbox
                                        name="condition4"
                                        sx={{ padding: "0px", display: "none" }}
                                        checked={selectAll || values.condition4}
                                        icon={<PanoramaFishEyeIcon />}
                                        checkedIcon={
                                          <img
                                            src={TickCircle}
                                            width={"24px"}
                                          />
                                        }
                                        onChange={(event) => {
                                          const { checked } = event.target;
                                          setFieldValue("condition4", true);
                                        }}
                                      />
                                    }
                                    label={
                                      <span className={Styles.label}>
                                        <span>4.</span>{" "}
                                        <span>
                                          I confirm to have read and understood
                                          the contents of the Equity Annexure
                                          and Commodity Annexure Documents.
                                        </span>
                                      </span>
                                    }
                                  />
                                </FormGroup>
                                {errors.condition4 && touched.condition4 && (
                                  <Typography variant="body2" color="error">
                                    {errors.condition4}
                                  </Typography>
                                )}
                              </div>
                              <div className={Styles.checkBoxDiv}>
                                <FormGroup>
                                  <FormControlLabel
                                    sx={{ alignItems: "flex-start" }}
                                    control={
                                      <Checkbox
                                        name="condition5"
                                        sx={{ padding: "0px", display: "none" }}
                                        checked={selectAll || values.condition4}
                                        icon={<PanoramaFishEyeIcon />}
                                        checkedIcon={
                                          <img
                                            src={TickCircle}
                                            width={"24px"}
                                          />
                                        }
                                        onChange={(event) => {
                                          const { checked } = event.target;
                                          setFieldValue("condition4", true);
                                        }}
                                      />
                                    }
                                    label={
                                      <span className={Styles.label}>
                                        <span>5.</span>{" "}
                                        <span>
                                          As per the “Digital Data Protection
                                          Act 2023”, all the reporting entries
                                          registered with CKYCRR were requested
                                          to obtain the consent from the
                                          customer for downloading of their CKYC
                                          records from the CKYCRR. Therefore,
                                          obtaining customer consent is also
                                          mandatory as per the RBI Master
                                          Directions for the entities regulated
                                          by RBI. This means that when you sign
                                          up for a service or become a client of
                                          Compositedge, subject to KYC
                                          regulations, you automatically agree
                                          to allow them to access your KYC
                                          records stored in CKYCRR.
                                        </span>
                                      </span>
                                    }
                                  />
                                </FormGroup>
                                {errors.condition4 && touched.condition4 && (
                                  <Typography variant="body2" color="error">
                                    {errors.condition4}
                                  </Typography>
                                )}
                              </div>
                              <div className={Styles.checkBoxDiv}>
                                <FormGroup>
                                  <FormControlLabel
                                    sx={{ alignItems: "flex-start" }}
                                    control={
                                      <Checkbox
                                        name="condition5"
                                        sx={{ padding: "0px", display: "none" }}
                                        checked={selectAll || values.condition4}
                                        icon={<PanoramaFishEyeIcon />}
                                        checkedIcon={
                                          <img
                                            src={TickCircle}
                                            width={"24px"}
                                          />
                                        }
                                        onChange={(event) => {
                                          const { checked } = event.target;
                                          setFieldValue("condition4", true);
                                        }}
                                      />
                                    }
                                    label={
                                      <span className={Styles.label}>
                                        <span>6.</span>
                                        <span className={Styles.mobileLine}>
                                          I confirm to take settlement of my
                                          unused funds on a
                                          <div>
                                            <FormControl
                                              size="small"
                                              sx={{ width: "45px" }}
                                            >
                                              <Select
                                                style={{
                                                  // width: "75px",
                                                  marginTop: "-4px",
                                                }}
                                                inputProps={{
                                                  IconComponent: () => (
                                                    <ArrowDropDownIcon
                                                      style={{
                                                        marginLeft: "0px",
                                                      }}
                                                    />
                                                  ),
                                                }}
                                                disableUnderline
                                                sx={{
                                                  fontSize: "14px",

                                                  color: "#4754A0",
                                                  fontWeight: 600,
                                                  boxShadow: "none",
                                                  // ".MuiSvgIcon-root-393": {
                                                  //   visibility: "hidden",
                                                  // },
                                                  ".MuiOutlinedInput-input": {
                                                    padding:
                                                      "3.5px 5px !important",
                                                  },
                                                  ".MuiOutlinedInput-notchedOutline":
                                                    {
                                                      border: 0,
                                                    },
                                                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                                    {
                                                      border: 0,
                                                    },
                                                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                                    {
                                                      border: 0,
                                                    },
                                                }}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={dropDownValue}
                                                label=""
                                                onChange={(event) => {
                                                  handleChangeSelectChange(
                                                    event
                                                  );
                                                }}
                                              >
                                                {Settlements.map(
                                                  (data: any, index: any) => {
                                                    // console.log(data)
                                                    return (
                                                      <MenuItem
                                                        value={data}
                                                        key={index}
                                                      >
                                                        {data.value}
                                                      </MenuItem>
                                                    );
                                                  }
                                                )}
                                              </Select>
                                            </FormControl>
                                          </div>
                                          basis from my Compositedge trading
                                          account.
                                        </span>
                                      </span>
                                    }
                                  />
                                </FormGroup>
                                {errors.condition4 && touched.condition4 && (
                                  <Typography variant="body2" color="error">
                                    {errors.condition4}
                                  </Typography>
                                )}
                              </div>
                              <div className={Styles.checkBoxDiv}>
                                <FormGroup>
                                  <FormControlLabel
                                    sx={{ alignItems: "flex-start" }}
                                    control={
                                      <Checkbox
                                        name="condition5"
                                        sx={{ padding: "0px", display: "none" }}
                                        checked={selectAll || values.condition4}
                                        icon={<PanoramaFishEyeIcon />}
                                        checkedIcon={
                                          <img
                                            src={TickCircle}
                                            width={"24px"}
                                          />
                                        }
                                        onChange={(event) => {
                                          const { checked } = event.target;
                                          setFieldValue("condition4", true);
                                        }}
                                      />
                                    }
                                    label={
                                      <span className={Styles.label}>
                                        <span>7.</span>{" "}
                                        <span>
                                          I instruct the DP to receive every
                                          credit into my account.
                                        </span>
                                      </span>
                                    }
                                  />
                                </FormGroup>
                                {errors.condition4 && touched.condition4 && (
                                  <Typography variant="body2" color="error">
                                    {errors.condition4}
                                  </Typography>
                                )}
                              </div>
                              <div className={Styles.checkBoxDiv}>
                                <FormGroup>
                                  <FormControlLabel
                                    sx={{ alignItems: "flex-start" }}
                                    control={
                                      <Checkbox
                                        name="condition8"
                                        sx={{ padding: "0px", display: "none" }}
                                        checked={selectAll || values.condition4}
                                        icon={<PanoramaFishEyeIcon />}
                                        checkedIcon={
                                          <img
                                            src={TickCircle}
                                            width={"24px"}
                                          />
                                        }
                                        onChange={(event) => {
                                          const { checked } = event.target;
                                          setFieldValue("condition8", true);
                                        }}
                                      />
                                    }
                                    label={
                                      <span className={Styles.label}>
                                        <span>8.</span>{" "}
                                        <span>
                                          I would like to instruct the DP to
                                          accept all the pledge instructions
                                          without any further instructions from
                                          my end.
                                        </span>
                                        <div>
                                          <FormControl size="small">
                                            <Select
                                              style={{
                                                // width: "75px",
                                                marginTop: "-4px",
                                              }}
                                              inputProps={{
                                                IconComponent: () => (
                                                  <ArrowDropDownIcon
                                                    style={{
                                                      marginLeft: "0px",
                                                    }}
                                                  />
                                                ),
                                              }}
                                              disableUnderline
                                              sx={{
                                                fontSize: "14px",

                                                color: "#4754A0",
                                                fontWeight: 600,
                                                boxShadow: "none",
                                                ".MuiSvgIcon-root-393": {
                                                  visibility: "hidden",
                                                },
                                                ".MuiOutlinedInput-input": {
                                                  padding:
                                                    "3.5px 5px !important",
                                                },
                                                ".MuiOutlinedInput-notchedOutline":
                                                  {
                                                    border: 0,
                                                  },
                                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                                  {
                                                    border: 0,
                                                  },
                                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                                  {
                                                    border: 0,
                                                  },
                                              }}
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              value={dropDownValuedpaccepect}
                                              label=""
                                              onChange={(event) => {
                                                setDropDownValuedpaccepect(
                                                  event.target.value
                                                );
                                              }}
                                            >
                                              {dpaccepect.map(
                                                (data: any, index: any) => {
                                                  // console.log(data)
                                                  return (
                                                    <MenuItem
                                                      value={data}
                                                      key={index}
                                                    >
                                                      {data.value}
                                                    </MenuItem>
                                                  );
                                                }
                                              )}
                                            </Select>
                                          </FormControl>
                                        </div>
                                      </span>
                                    }
                                  />
                                </FormGroup>
                                {errors.condition4 && touched.condition4 && (
                                  <Typography variant="body2" color="error">
                                    {errors.condition4}
                                  </Typography>
                                )}
                              </div>
                              <div className={Styles.checkBoxDiv}>
                                <FormGroup>
                                  <FormControlLabel
                                    sx={{ alignItems: "flex-start" }}
                                    control={
                                      <Checkbox
                                        name="condition9"
                                        sx={{ padding: "0px", display: "none" }}
                                        checked={selectAll || values.condition4}
                                        icon={<PanoramaFishEyeIcon />}
                                        checkedIcon={
                                          <img
                                            src={TickCircle}
                                            width={"24px"}
                                          />
                                        }
                                        onChange={(event) => {
                                          const { checked } = event.target;
                                          setFieldValue("condition8", true);
                                        }}
                                      />
                                    }
                                    label={
                                      <span className={Styles.label}>
                                        <span>9.</span>{" "}
                                        <span style={{ width: "max-content" }}>
                                          Account Statement Requirement:
                                        </span>
                                        <div>
                                          <FormControl size="small">
                                            <Select
                                              style={{
                                                // width: "75px",
                                                marginTop: "-4px",
                                              }}
                                              inputProps={{
                                                IconComponent: () => (
                                                  <ArrowDropDownIcon
                                                    style={{
                                                      marginLeft: "0px",
                                                    }}
                                                  />
                                                ),
                                              }}
                                              disableUnderline
                                              sx={{
                                                fontSize: "14px",

                                                color: "#4754A0",
                                                fontWeight: 600,
                                                boxShadow: "none",
                                                ".MuiSvgIcon-root-393": {
                                                  visibility: "hidden",
                                                },
                                                ".MuiOutlinedInput-input": {
                                                  padding:
                                                    "3.5px 5px !important",
                                                },
                                                ".MuiOutlinedInput-notchedOutline":
                                                  {
                                                    border: 0,
                                                  },
                                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                                  {
                                                    border: 0,
                                                  },
                                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                                  {
                                                    border: 0,
                                                  },
                                              }}
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              value={regulationperiod}
                                              label=""
                                              onChange={(event) => {
                                                setregulationperiod(
                                                  event.target.value
                                                );
                                              }}
                                            >
                                              {period.map(
                                                (data: any, index: any) => {
                                                  // console.log(data)
                                                  return (
                                                    <MenuItem
                                                      value={data}
                                                      key={index}
                                                    >
                                                      {data.value}
                                                    </MenuItem>
                                                  );
                                                }
                                              )}
                                            </Select>
                                          </FormControl>
                                        </div>
                                      </span>
                                    }
                                  />
                                </FormGroup>
                                {errors.condition4 && touched.condition4 && (
                                  <Typography variant="body2" color="error">
                                    {errors.condition4}
                                  </Typography>
                                )}
                              </div>
                              <div className={Styles.checkBoxDiv}>
                                <FormGroup>
                                  <FormControlLabel
                                    sx={{ alignItems: "flex-start" }}
                                    control={
                                      <Checkbox
                                        name="condition10"
                                        sx={{ padding: "0px", display: "none" }}
                                        checked={selectAll || values.condition4}
                                        icon={<PanoramaFishEyeIcon />}
                                        checkedIcon={
                                          <img
                                            src={TickCircle}
                                            width={"24px"}
                                          />
                                        }
                                        onChange={(event) => {
                                          const { checked } = event.target;
                                          setFieldValue("condition8", true);
                                        }}
                                      />
                                    }
                                    label={
                                      <span className={Styles.label}>
                                        <span>10.</span>{" "}
                                        <span className={Styles.mobileLine}>
                                          I request you to send the Electronic
                                          Transaction-cum-Holding statement at
                                          my email ID.
                                          <div>
                                            <FormControl size="small">
                                              <Select
                                                style={{
                                                  // width: "45px",
                                                  marginTop: "-4px",
                                                }}
                                                inputProps={{
                                                  IconComponent: () => (
                                                    <ArrowDropDownIcon
                                                      style={{
                                                        marginLeft: "0px",
                                                      }}
                                                    />
                                                  ),
                                                }}
                                                disableUnderline
                                                sx={{
                                                  fontSize: "14px",
                                                  // width: "45px",
                                                  color: "#4754A0",
                                                  fontWeight: 600,
                                                  boxShadow: "none",
                                                  ".MuiSvgIcon-root-393": {
                                                    visibility: "hidden",
                                                  },
                                                  ".MuiOutlinedInput-input": {
                                                    padding:
                                                      "3.5px 5px !important",
                                                  },
                                                  ".MuiOutlinedInput-notchedOutline":
                                                    {
                                                      border: 0,
                                                    },
                                                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                                    {
                                                      border: 0,
                                                    },
                                                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                                    {
                                                      border: 0,
                                                    },
                                                }}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={etchvalue}
                                                label=""
                                                onChange={(event) => {
                                                  setetchvalue(
                                                    event.target.value
                                                  );
                                                }}
                                              >
                                                {etch.map(
                                                  (data: any, index: any) => {
                                                    // console.log(data)
                                                    return (
                                                      <MenuItem
                                                        value={data}
                                                        key={index}
                                                      >
                                                        {data.value}
                                                      </MenuItem>
                                                    );
                                                  }
                                                )}
                                              </Select>
                                            </FormControl>
                                          </div>
                                        </span>
                                      </span>
                                    }
                                  />
                                </FormGroup>
                                {errors.condition4 && touched.condition4 && (
                                  <Typography variant="body2" color="error">
                                    {errors.condition4}
                                  </Typography>
                                )}
                              </div>
                              <div className={Styles.checkBoxDiv}>
                                <FormGroup>
                                  <FormControlLabel
                                    sx={{ alignItems: "flex-start" }}
                                    control={
                                      <Checkbox
                                        name="condition11"
                                        sx={{ padding: "0px", display: "none" }}
                                        checked={selectAll || values.condition4}
                                        icon={<PanoramaFishEyeIcon />}
                                        checkedIcon={
                                          <img
                                            src={TickCircle}
                                            width={"24px"}
                                          />
                                        }
                                        onChange={(event) => {
                                          const { checked } = event.target;
                                          setFieldValue("condition8", true);
                                        }}
                                      />
                                    }
                                    label={
                                      <span className={Styles.label}>
                                        <span>11.</span>{" "}
                                        <span>
                                          There is no action/proceedings
                                          initiated/pending/taken by SEBI/Stock
                                          exchange/any other authority against
                                          the applicant/constituent or its
                                          Partners/promoters/whole time
                                          directors/authorized persons in charge
                                          of dealing in securities during the
                                          last 3 years/and conviction by any
                                          court in India or abroad.
                                        </span>
                                      </span>
                                    }
                                  />
                                </FormGroup>
                              </div>
                              <div className={Styles.checkBoxDiv}>
                                <FormGroup>
                                  <FormControlLabel
                                    sx={{ alignItems: "flex-start" }}
                                    control={
                                      <Checkbox
                                        name="condition11"
                                        sx={{ padding: "0px", display: "none" }}
                                        checked={selectAll || values.condition4}
                                        icon={<PanoramaFishEyeIcon />}
                                        checkedIcon={
                                          <img
                                            src={TickCircle}
                                            width={"24px"}
                                          />
                                        }
                                        onChange={(event) => {
                                          const { checked } = event.target;
                                          setFieldValue("condition8", true);
                                        }}
                                      />
                                    }
                                    label={
                                      <span className={Styles.label}>
                                        <span>12.</span>{" "}
                                        <span>
                                          Online Trading Access:{" "}
                                          <a href="https://compositepublic.s3.ap-south-1.amazonaws.com/folders/Stoptrade+-+Text+for+Online+Ac+Opening+Module+(EKYC).docx">
                                            Guidelines
                                          </a>{" "}
                                          to Temporarily block your account
                                        </span>
                                      </span>
                                    }
                                  />
                                </FormGroup>
                              </div>
                              <div className={Styles.checkBoxDiv}>
                                <FormGroup>
                                  <FormControlLabel
                                    sx={{ alignItems: "flex-start" }}
                                    control={
                                      <Checkbox
                                        name="condition11"
                                        sx={{ padding: "0px", display: "none" }}
                                        checked={selectAll || values.condition4}
                                        icon={<PanoramaFishEyeIcon />}
                                        checkedIcon={
                                          <img
                                            src={TickCircle}
                                            width={"24px"}
                                          />
                                        }
                                        onChange={(event) => {
                                          const { checked } = event.target;
                                          setFieldValue("condition8", true);
                                        }}
                                      />
                                    }
                                    label={
                                      <span className={Styles.label}>
                                        <span>13.</span>{" "}
                                        <span>
                                          <a href="https://compositepublic.s3.ap-south-1.amazonaws.com/folders/Most+Important+Terms+and+Conditions.docx">
                                            Most Important Terms and Conditions
                                          </a>{" "}
                                          is appearing in the application form
                                          but not while processing the account
                                        </span>
                                      </span>
                                    }
                                  />
                                </FormGroup>
                              </div>
                              <div className={Styles.checkBoxDiv}>
                                <FormGroup>
                                  <FormControlLabel
                                    sx={{ alignItems: "flex-start" }}
                                    control={
                                      <Checkbox
                                        name="condition11"
                                        sx={{ padding: "0px", display: "none" }}
                                        checked={selectAll || values.condition4}
                                        icon={<PanoramaFishEyeIcon />}
                                        checkedIcon={
                                          <img
                                            src={TickCircle}
                                            width={"24px"}
                                          />
                                        }
                                        onChange={(event) => {
                                          const { checked } = event.target;
                                          setFieldValue("condition8", true);
                                        }}
                                      />
                                    }
                                    label={
                                      <span className={Styles.label}>
                                        <span>14.</span>
                                        <span
                                          className={Styles.aTag}
                                          onClick={() => {
                                            handlePreviewClick("tariff");
                                          }}
                                        >
                                          {" "}
                                          Click here
                                        </span>{" "}
                                        to view the Tariff Sheet.
                                      </span>
                                    }
                                  />
                                </FormGroup>
                                {errors.condition4 && touched.condition4 && (
                                  <Typography variant="body2" color="error">
                                    {errors.condition4}
                                  </Typography>
                                )}
                              </div>
                              <div className={Styles.checkBoxDiv}>
                                <FormGroup>
                                  <FormControlLabel
                                    sx={{ alignItems: "flex-start" }}
                                    control={
                                      <Checkbox
                                        name="condition11"
                                        sx={{ padding: "0px", display: "none" }}
                                        checked={selectAll || values.condition4}
                                        icon={<PanoramaFishEyeIcon />}
                                        checkedIcon={
                                          <img
                                            src={TickCircle}
                                            width={"24px"}
                                          />
                                        }
                                        onChange={(event) => {
                                          const { checked } = event.target;
                                          setFieldValue("condition8", true);
                                        }}
                                      />
                                    }
                                    label={
                                      <span className={Styles.label}>
                                        <span>15.</span>
                                        <span
                                          className={Styles.aTag}
                                          onClick={() => {
                                            handlePreviewClick("dptariff");
                                          }}
                                        >
                                          {" "}
                                          Click here
                                        </span>{" "}
                                        to view the DP Tariff Sheet.
                                      </span>
                                    }
                                  />
                                </FormGroup>
                                {errors.condition4 && touched.condition4 && (
                                  <Typography variant="body2" color="error">
                                    {errors.condition4}
                                  </Typography>
                                )}
                              </div>
                            </div>
                            <div className={Styles.imageContainerDesktop}>
                              <img src={image} className={Styles.image} />
                            </div>
                          </div>
                        </div>

                        <div className={Styles.buttonStack}>
                          <div>
                            <Button
                              id="tradingPreferencesContinueButton"
                              variant="text"
                              className={Styles.backButton}
                              sx={{
                                background: "#EEEFEF",
                                "&:hover": {
                                  background: "#EEEFEF",
                                },
                              }}
                              onClick={() => {
                                navigate("/linkusingifsc");
                              }}
                            >
                              Back
                            </Button>
                          </div>
                          <div>
                            <LoadingButton
                              variant="contained"
                              type="submit"
                              loading={loading}
                              id="termsandconditionsContinueButton"
                              className={Styles.approveButton}
                              loadingIndicator={
                                <CircularProgress
                                  sx={{ color: "#4754A0" }}
                                  size={20}
                                />
                              }
                            >
                              Continue
                            </LoadingButton>
                          </div>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <div style={{ height: "100vh" }}>
                <BackDrop />
              </div>
            )}
          </div>
        </div>
      </div>
      {showModal && (
        <Stack>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={showModal}
            closeAfterTransition
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={showModal}>
              <Box sx={style}>
                <Stack
                  alignItems={"flex-end"}
                  marginRight={"10px"}
                  marginTop={"5px"}
                >
                  <IconButton onClick={handlePreviewClose}>
                    <HighlightOffIcon />
                  </IconButton>
                </Stack>
                <div className={Styles.pdfMobilePreview}>
                  {/* <PdfModal url={pdfUrl} /> */}
                  <PDFViewerIframe urldata={pdfUrl} />
                </div>
                <div className={Styles.pdfDesktopPreview}>
                  <PDFViewerIframe urldata={pdfUrl} />
                </div>
              </Box>
            </Fade>
          </Modal>
        </Stack>
      )}
      <p className={Styles.startover}>
        {" "}
        If you want to start over, please
        <span className={Styles.startoverbutton} onClick={handleStartOver}>
          Click Here
        </span>
      </p>
    </>
  );
}
