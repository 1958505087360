import React, { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  Typography,
  Button,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useLocation } from "react-router-dom";
import { checkUserLoggedIn } from "../shared/commonfunctions";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { EmailSignupAPICall, sendEmialAPICall } from "../shared/APICalls";
import { IEmailSignup, ISendEmail } from "../shared/dtos";
import routesData from "../shared/routes.json";
import YourDetailsStyles from "../pages/Styles/YourDetails.module.scss";
import YourdetailsImage from "../Components/assets/Images/CompLeftImage.svg";
import googleImage from "../Components/assets/Images/goggle.png";
import ContinueWithGoogle from "../Components/ContinueWithGoogle/ContinueWithGoogle";
import SnackBar from "../Components/SnackBars/SnackBar";

export default function YourDetails() {
  const navigate = useNavigate();

  const [emailAddress, setemailAddress] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<any>(false);
  const [message, setMessage] = useState<any>("");
  const [loading, setloading] = useState(false);

  const navigationData = routesData;

  useEffect(() => {
    window.scrollTo(0, 0);
    checkUserLoggedIn(navigate);
  }, []);

  function sendEmailAddress(emailid: any, name: any) {
    let obj: IEmailSignup = {
      email: emailid?.toLowerCase(),
    };
    EmailSignupAPICall(obj)
      .then((response: any) => {
        sessionStorage.setItem("client-email", emailid);
        const responseData = response.data;
        // console.log(response, "response");

        setloading(false);

        if (
          responseData.data.next_stage &&
          navigationData.hasOwnProperty(responseData.data.next_stage)
        ) {
          navigate(navigationData[responseData.data.next_stage]);
        }
      })
      .catch((error: any) => {
        setloading(false);
        // console.log("error", error);

        setMessage(
          error.message.includes("Network")
            ? "Error occured, Please try again"
            : error.response.data.message
        );
        setTimeout(() => {
          setMessage("");
        }, 5000);
      });
  }

  const handleClick = () => {
    navigate("/confirmemailotp");
  };
  const callBackMessage = (data: any) => {
    setMessage(data);
  };

  function validateName(value: any) {
    let error: any;
    if (!value) {
      error = "Name Required";
    } else if (!/^[a-zA-Z\s]{1,50}$/.test(value)) {
      error = "Extra characters not allowed";
    }
    return error;
  }

  function validateEmail(value: any) {
    let error: any;
    if (!value) {
      error = "Email Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      error = "Invalid email address";
    } else if (value.length > 75) {
      error = "Not a valid email address";
    }
    return error;
  }
  return (
    <>
      <div className={YourDetailsStyles.wrapper}>
        <div className={YourDetailsStyles.LeftContainer}>
          <img
            src={YourdetailsImage}
            alt="Image"
            className={YourDetailsStyles.image}
          />
        </div>
        <div className={YourDetailsStyles.RightContainer}>
          <div className={YourDetailsStyles.headerSection}>
            <p className={YourDetailsStyles.signup}>User Sign Up</p>
            <p className={YourDetailsStyles.subText}>
              Or continue with your existing application
            </p>
          </div>

          <div>
            <Formik
              initialValues={{
                name: "",
                email: "",
              }}
              onSubmit={(values) => {
                console.log(values);
                setloading(true);
                setemailAddress(values.email);
                // sendEmailAddress(values.name, values.email);
                sendEmailAddress(values.email, values.name);
              }}
            >
              {({ errors, touched, handleChange, values, handleSubmit }) => (
                <Form
                  onKeyDown={(event) => {
                    event.code === "Enter" ||
                      (event.code === "NumpadEnter" && handleSubmit());
                  }}
                >
                  <div className={YourDetailsStyles.textfeildSection}>
                    {/* <p className={YourDetailsStyles.textfieldLabel}>
                      Enter your full name
                    </p> */}
                    {/* <Field
                      name="name"
                      // validate={validateName}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className={YourDetailsStyles.textfeild}
                          id="outlined-basic"
                          label=""
                          placeholder="Name"
                          variant="outlined"
                          InputProps={{
                            maxLength: 50,
                          }}
                          sx={{
                            fieldset: { borderColor: "transparent" },
                          }}
                          inputProps={{
                            sx: {
                              background: "#F2F2F6",
                              borderRadius: "8px",
                              "&::placeholder": {
                                paddingLeft: "10px",
                              },

                              paddingLeft: "10px",
                            },
                          }}
                        />
                      )}
                    /> */}

                    <div
                      style={{
                        color: "#d32f2f",
                        fontSize: "12px",
                        height: "10px",
                        padding: "5px 0px 0px 15px",
                      }}
                    >
                      {errors.name && touched.name && errors.name}
                    </div>
                    <p className={YourDetailsStyles.textfieldLabel}>
                      Enter Email Address
                    </p>

                    <Field
                      name="email"
                      validate={validateEmail}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className={YourDetailsStyles.textfeild}
                          id="outlined-basic"
                          placeholder="Email"
                          variant="outlined"
                          InputProps={{
                            maxLength: 75,
                          }}
                          sx={{
                            fieldset: { borderColor: "transparent" },
                          }}
                          inputProps={{
                            sx: {
                              background: "#F2F2F6",
                              borderRadius: "8px",
                              "&::placeholder": {
                                paddingLeft: "10px",
                              },

                              paddingLeft: "10px",
                            },
                          }}
                        />
                      )}
                    />
                    <div
                      style={{
                        color: "#d32f2f",
                        fontSize: "12px",
                        height: "10px",
                        padding: "5px 0px 0px 15px",
                      }}
                    >
                      {errors.email && touched.email && errors.email}
                    </div>

                    <SnackBar message={message} />

                    <div className={YourDetailsStyles.buttonSection}>
                      {/* <ContinueWithGoogle handleCallBack={callBackMessage} />
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginLeft: "2px",
                          color: "#656567",
                        }}
                      >
                        or
                      </Typography> */}
                      {/* {values.email ? ( */}
                      <LoadingButton
                        variant="contained"
                        type="submit"
                        loading={loading}
                        id="yourDetailsContinueButton"
                        className={YourDetailsStyles.button}
                        onClick={() => {
                          handleSubmit();
                        }}
                        loadingIndicator={
                          <CircularProgress
                            sx={{ color: "#4754A0" }}
                            size={20}
                          />
                        }
                      >
                        Continue
                      </LoadingButton>
                      {/* ) : (
                        <Button
                          variant="contained"
                          disabled
                          className={YourDetailsStyles.button}
                        >
                          Continue
                        </Button>
                      )} */}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}
