import React, { useEffect, useState } from "react";
import {
  Stack,
  Button,
  TextField,
  Modal,
  Fade,
  Box,
  IconButton,
} from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import uploadIcon from "../../Components/assets/Images/UploadIcon.svg";
import SnackBar from "../SnackBars/SnackBar";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import styles from "./DrapandDrop.module.scss";

const styleNew = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vW",
  height: "50vH",
  bgcolor: "background.paper",

  p: 4,
  boxShadow: 24,
  borderRadius: "10px",
};
const isMobileView = window.innerWidth <= 768; // Adjust the breakpoint if needed

// Change the width value to "90vw" for mobile view
if (isMobileView) {
  styleNew.width = "90vw";
}

interface Props {
  showDragandDropModal: any;
  handleCloseModal: any;
  handleFileChangeUpload: any;
  acceptedFormats: any;
  errorMessage: string;
  selectedFile: any;
  showInput: any;
  image: any;
}

export default function DragandDropFile({
  showDragandDropModal,
  handleCloseModal,
  handleFileChangeUpload,
  acceptedFormats,
  errorMessage,
  selectedFile,
  showInput,
  image,
}: Props) {
  const [file, setFile] = useState<any>(null);
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const handleChange = (file: any) => {
    setFile(file);
  };

  // console.log("acceptedFormats", acceptedFormats);

  return (
    <Stack alignItems={"center"}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showDragandDropModal}
        // onClose={handleCloseModal}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={showDragandDropModal}>
          <Box sx={styleNew}>
            <IconButton
              style={{ position: "absolute", right: "0px", top: "0px" }}
              onClick={handleCloseModal}
            >
              <HighlightOffIcon style={{ color: "#000" }} />
            </IconButton>
            <Stack
              height={"100%"}
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}

              // marginTop={"50px"}
            >
              <div style={{ width: "100%", height: "100%" }}>
                <FileUploader
                  multiple={true}
                  handleChange={handleFileChangeUpload}
                  name="file"
                  types={acceptedFormats}
                  onTypeError={(err: any) => {
                    setFileErrorMessage(err);

                    setTimeout(() => {
                      setFileErrorMessage("");
                    }, 5000);
                  }}
                >
                  <div
                    style={{
                      border: "2px dotted rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={uploadIcon} width={"60px"} />
                      {/* <CloudUploadOutlinedIcon
                        style={{ fontSize: "60px", color: "#006779" }}
                      /> */}
                      <p className={styles.selectText}>
                        Select a file or drag and drop here
                      </p>
                      <p className={styles.formatText}>
                        <span>
                          {`${acceptedFormats}`},file size no more than 3MB{" "}
                        </span>
                      </p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        marginTop:'10px'

                      }}
                    >
                      <Button variant="outlined" style={{ color: "#4754A0", height:'40px', padding:'0px 20px' }}>
                        Select file
                      </Button>
                    </div>

                    {/* {errorMessage && errorMessage} */}
                  </div>
                </FileUploader>
              </div>
              <SnackBar message={fileErrorMessage} />
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </Stack>
  );
}
