// import React from "react";

// const PDFViewerIframe = ({ url }) => {
//   console.log(url);
//   return (
//     <div style={{ padding: "0px 10px" }}>
//       <iframe src={url} width="100%" height="450px" title="PDF Viewer" />
     
//     </div>
//   );
// };

// export default PDFViewerIframe;


import * as React from 'react';
import { Viewer } from '@react-pdf-viewer/core';

import '@react-pdf-viewer/core/lib/styles/index.css';

const PDFViewerIframeEsign = ({urldata}) => {
    const base64 =urldata;
        
    const pdfContentType = 'application/pdf';

    // const base64toBlob = (data: string) => {
    //     // Cut the prefix `data:application/pdf;base64` from the raw base 64
    //     const base64WithoutPrefix = data.substr(`data:${pdfContentType};base64,`.length);

    //     const bytes = atob(base64WithoutPrefix);
    //     let length = bytes.length;
    //     let out = new Uint8Array(length);

    //     while (length--) {
    //         out[length] = bytes.charCodeAt(length);
    //     }

    //     return new Blob([out], { type: pdfContentType });
    // };

    // const url = URL.createObjectURL(base64toBlob(base64));

    return (
        <div
           style={{height:'100%'}}
        >
            <iframe src={urldata} width="99%" height="100%" title="PDF Viewer" />
        </div>
    );
};

export default PDFViewerIframeEsign;
