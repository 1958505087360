import { combineReducers } from "redux";
import PATHNAME from "./reducers/pathNameReducers";



const rootReducer = combineReducers({
  // Define a top-level state field named `todos`, handled by `todosReducer`
  PATHNAME: PATHNAME,

 

});

export default rootReducer;