import { Alert, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import Slide, { SlideProps } from '@mui/material/Slide';
type TransitionProps = Omit<SlideProps, 'direction'>;



function TransitionUp(props: TransitionProps) {
  return <Slide {...props} direction="up"  />;
}

interface props {
    message:string;
}

export default function CopiedSnackBar({message}:props) {
  const [transition, setTransition] = React.useState<
  React.ComponentType<TransitionProps> | undefined
>(undefined);
    const [open, setOpen] = useState(false);
    //console.log("message", message)

    useEffect(()=>{

        if(message){
            setOpen(true)
            setTransition(() => TransitionUp);
            setTimeout(()=>{
                setOpen(false)
            },3000)
        }

    },[message])
    
  //  console.log(message)
  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        TransitionComponent={transition}
        sx={{marginBottom:"50px"}}
      >
        <div style={{ background: "#54BF00", borderRadius: "10px " }}>
          <div style={{ marginLeft: "30px" }}>
            <Alert
              icon={false}
              severity="success"
              sx={{
                width: "max-content",
                height: "50px",
                fontSize: "16px",
                borderRadius: "0",
                display:"flex",
                alignItems:"center",
                background:"#EAFFE9",
              
              }}
            >
                
              {message}
            </Alert>
          </div>
        </div>
      </Snackbar>
    </div>
  );
}