import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Box, Chip, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import { useSelector, Provider } from "react-redux";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import backIcon from "../Components/assets/Images/backIcon.svg";
import styles from "./StageLayout.module.scss";
import stepperDoneIcon from "../Components/assets/Images/steperdoneicon.svg";
import stepperEmptyIcon from "../Components/assets/Images/steperemptyicon.svg";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 3,
  borderRadius: 5,
  margin: "0px 0px 0px 0px",
  // width: "93vw",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#B1DDC3" : "#308fe8",
  },
}));

interface Props {
  completedStages: any;
  stageName: string;
  navigatePath: string;
  esignBackButtonEnable?: any;
}

export default function StageLayoutEkyc({
  completedStages,
  stageName,
  navigatePath,
  esignBackButtonEnable,
}: Props) {
  const navigate = useNavigate();

  const pathName = useSelector((state: any) => state.PATHNAME.pathname);

  const StepperData = [
    {
      title: "Pan Verification",
      value: "pan",
    },
    {
      title: "Aadhaar",
      value: "kra_or_aadhaar",
    },
    {
      title: "Your Details",
      value: "customer_details",
    },
    {
      title: "Trading Preference",
      value: "segment",
    },
    {
      title: "Bank Account",
      value: "bank",
    },
    {
      title: "T & C",
      value: "t_and_c",
    },
    {
      title: "Nominee",
      value: "nominee",
    },
    {
      title: "Documents Upload",
      value: "document_upload",
    },
    {
      title: "IPV",
      value: "ipv",
    },
    {
      title: "Esign",
      value: "e_sign",
    },
  ];

  const completedStageDetails = {
    ekyc_pan: {
      title: "Pan Verification",
      path: "/panverification",
    },
    ekyc_kra_or_aadhaar: {
      title: "Aadhaar",
      path: "/aadhaarkyc",
    },
    ekyc_customer_details: {
      title: "Profile Details",
      path: "/yourprofile",
    },
    ekyc_segment: {
      title: "Trading Preference",
      path: "/tradingPreference",
    },
    ekyc_bank: {
      title: "Bank Account",
      path: "/linkusingifsc",
    },
    ekyc_t_and_c: {
      title: "Terms and Conditions",
      path: "/termsandconditions",
    },
    ekyc_document_upload: {
      title: "Documents Upload",
      path: "/uploaddocuments",
    },
    ekyc_ipv: {
      title: "IPV",
      path: "/ipv",
    },
    ekyc_nominee: {
      title: "Nominee",
      path: "/nominee",
    },
    ekyc_e_sign: {
      title: "Esign",
      path: "/esign",
    },
  };

  return (
    <>
      <Stack style={{ overflowX: "hidden" }}>
        <Box
          sx={{
            width: "100%",
            margin: { xs: "0 2px", md: "0 10px" },
            zIndex: 100,
            position: "relative",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <div className={styles.stepperWrapper}>
              {StepperData.map((a: any, index: any) => (
                <div className={styles.stepSection}>
                  <div className={styles.step}>
                    <div
                      className={styles.stepLine}
                      style={{
                        visibility: index === 0 ? "hidden" : "visible",
                      }}
                    ></div>

                    {pathName.includes("/congratulations") ||
                    pathName.includes("/completed") ? (
                      <img src={stepperDoneIcon} className={styles.stepIcon} />
                    ) : (
                      <img
                        src={
                          completedStages &&
                          completedStages?.filter(
                            (stage: any) => stage === a.value
                          )[0]
                            ? stepperDoneIcon
                            : stepperEmptyIcon
                        }
                        className={styles.stepIcon}
                      />
                    )}

                    {/* <img
                      src={
                        completedStages &&
                        completedStages?.filter(
                          (stage: any) => stage === a.value
                        )[0]
                          ? stepperDoneIcon
                          : stepperEmptyIcon
                      }
                      className={styles.stepIcon}
                    /> */}
                    {StepperData.length - 1 !== index && (
                      <div className={styles.stepLine}></div>
                    )}
                  </div>
                  <p className={styles.stepTitle}>{a.title}</p>
                </div>
              ))}
            </div>

            <div className={styles.circularProgress}>
              <CircularProgressbar
                value={completedStages?.length * 10}
                strokeWidth={12}
                text={`${
                  completedStages?.length
                    ? completedStages?.length
                    : pathName.includes("/congratulations") ||
                      pathName.includes("/completed")
                    ? 10
                    : 0
                }/${10}`}
                styles={buildStyles({
                  textColor: "#000",
                  textSize: "24px",
                  pathColor: `#4754A0`,
                  trailColor: "rgba(71, 84, 160, 0.30)",
                })}
              />
            </div>
          </Box>
        </Box>
      </Stack>
    </>
  );
}
